// Used to build the Layout/Header/Breadcrumb

// TODO: possible expansions include adding parameters such as :id which would need to be handled by Header/Breadcrumb
export default {
  "/account": "Account",
  "/account/mfa": "TOTP Setup",
  "/orgs": "Orgs",
  "/campaigns": "Campaigns",
  "/campaigns/details": "Details",
  "/campaigns/manager": "Manager",
  "/campaigns/requester": "Requester",
  "/campaigns/orderqueue": "Order Queue",
  "/locations": "Locations",
  "/audiences": "Audiences",
  "/audiences/details": "Details",
  "/segments": "Segments",
  "/segments/new/1/updatedAt_DESC/%7B%7D?pageSize=10": "OLD GEO TARGETS",
  "/segments/geo-targets/new/1/updatedAt_DESC/%7B%7D?pageSize=10":
    "NEW GEO TARGETS",
  // "/segments/events-targets/new/1/updatedAt_DESC/%7B%7D": "Events Targets",
  "/orgconfig": "Organization Configuration",
  "/paid_search": "Paid Search Campaign",
  "/home": "Home Dashboard",
  "/seo": "SEO Domains",
  "/articles": "Articles",
  "/home/analytics/display/summary": "Overview",
  "/home/analytics/display": "Reports",
  "/home/analytics/paid-search": "Paid Search Reports",
  "/home/analytics/seo": "SEO Reports",
  "/editorial": "Publisher",
  "/invoice": "Invoices",
  "/signal": "Signal tags",
  "/advertiser": "Advertiser",
  "/demograph": "Demograph",
  "/tags": "Tags",
  "/audience": "Audience",
  "/audience/details": "Details",
  "/targeting": "Targeting",
  "/retails_stores": "Retail Stores",
  "/settings": "Settings",
  "/knowledge_base": "Knowledge Base",
  "/tasks": "Tasks",
  "/tasks/form": "Create Task",
  "/tasks/details": "Task Detail",
  "/tasks/edit_task": "Edit Task",
};
