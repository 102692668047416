import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";
import GET_ORGS_BASIC_DETAILS from "../../GraphQl/Queries/GET_ORGS_BASIC_DETAILS";

const OrgContext = createContext();

export const OrgProvider = ({ children }) => {
  const [
    fetchBasicOrgsDetails,
    { data: basicOrgsDetails, loading: basicOrgsDetailsLoading, error },
  ] = useLazyQuery(GET_ORGS_BASIC_DETAILS);

  return (
    <OrgContext.Provider
      value={{
        fetchBasicOrgsDetails,
        basicOrgsDetails: (basicOrgsDetails && basicOrgsDetails.orgs) || [],
        basicOrgsDetailsLoading,
        error,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

export default OrgContext;
