import React, { Component } from "react";
import { Card } from "antd";
import WhiteLabel from "./admin/platform/shared/WhiteLabel";

const result = WhiteLabel();
const logo = result;

//toggle this to switch between maintenance and technical difficulties - false = technical difficulties
const maintenance = true;

let text = `This data platform is currently experiencing unexpected technical
          issues, we will be back online shortly.`;

if (maintenance) {
  text = `This data platform is currently undergoing scheduled maintenance, we will be back online shortly.`;
}

class MaintenanceWhiteLabel extends Component {
  render() {
    return (
      <div style={styles.landingPage}>
        <Card
          hoverable
          style={{ padding: 20 }}
          cover={<img alt="example" src={logo} />}
        />

        <br />
        <div
          style={{
            textAlign: "center",
            fontSize: 20,
          }}
        >
          {text}
        </div>
      </div>
    );
  }
}

const styles = {
  landingPage: {
    top: 20,
    position: "relative",
    margin: " auto",
    width: "300px",
    padding: "5px",
  },
};

export default MaintenanceWhiteLabel;
