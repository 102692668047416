import { Row, Col, Tabs } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import DetailBar from "../components/DetailBar";
import DualAxisChart from "../../../../shared/charts/DualAxisChart";
import TopLandingPages from "../components/TopLandingPages";
import KeywordRankingsView from "./KeywordRankingsView";
import { summaryScaleLegacy } from "../../utils/summaryScaleLegacy";
import moment from "moment";

const { TabPane } = Tabs;

const SummaryView = ({
  summary,
  rankingsReport,
  byDate,
  dateRange,
  generateDataSummary = summary => [],
  generateROISummary = summary => [],
  reportBuilder = report => [],
  filterReportsByDate = dateRange => [],
  setReportData,
  generateKeywordRankingsSummary,
  domain,
}) => {
  const [filteredByDate, setFilteredByDate] = useState(byDate);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedRangeKey, setSelectedRangeKey] = useState("Custom");
  //Holds all of the KPI for the Data Detailbar
  const [summaryDetails, setSummaryDetails] = useState(
    generateDataSummary(summary)
  );
  //Holds all of the KPI for the ROI Detailbar
  const [summaryROIDetails, setSummaryROIDetails] = useState(
    generateROISummary(summary)
  );
  const [currentTab, setCurrentTab] = useState("overall");

  const allTimeRange = useMemo(() => {
    if (!byDate || !byDate.length) return null;
    const sortedDates = [...byDate].sort(
      (a, b) => moment(a.date).valueOf() - moment(b.date).valueOf()
    );
    return [
      moment(sortedDates[0].date),
      moment(sortedDates[sortedDates.length - 1].date),
    ];
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setReportData(filteredByDate);
  }, [filteredByDate, setReportData]);

  useEffect(() => {
    if (currentTab === "overall") {
      setReportData(filteredByDate);
    } else if (currentTab === "rankings") {
      setReportData(rankingsReport.keywordReportRankings);
    }
  }, [currentTab, filteredByDate, rankingsReport, setReportData]);

  let isMultiTab = false;
  if (rankingsReport) isMultiTab = true;
  const dropDownOptions = [
    { key: "sessions", display: "Sessions" },
    { key: "pagesPerSession", display: "Page View" },
    { key: "bounceRate", display: "Bounce Rate" },
    { key: "revenue", display: "Revenue" },
    { key: "avgSessionDuration", display: "Average Session Duration" },
    { key: "goalConversionRate", display: "Goal Conversion Rate" },
    { key: "transactions", display: "Transactions" },
    { key: "avgOrderValue", display: "Average Order Value" },
  ];

  //Holds all of the overall data for SEO. This is also the default view if there are no other tabs
  const OverallDataView = () => {
    return (
      <>
        <Row gutter={35}>
          <Col xs={24}>
            <DetailBar name="Data" dataToDisplay={summaryDetails} />
          </Col>

          <Col xs={24}>
            <DetailBar name="ROI" dataToDisplay={summaryROIDetails} />
          </Col>
        </Row>
        <Row gutter={35}>
          <Col xs={24} style={{ marginTop: "2%" }}>
            <DualAxisChart
              id={"SeoSummary"}
              byDate={filteredByDate}
              xAxis="date"
              options={dropDownOptions}
              defaultLeftYValue={{ key: "sessions", display: "Sessions" }}
              defaultRightYValue={{ key: "revenue", display: "Revenue" }}
              defaultSummaryDates={selectedDateRange}
              has6MonDisables={false}
              defaultRangeKey={selectedRangeKey}
              allTimeDateRange={allTimeRange}
              onDatePickerChange={({ data, dates, rangeKey }) => {
                let reportData;
                if (rangeKey === "All Time" && allTimeRange) {
                  reportData = filterReportsByDate(allTimeRange);
                } else {
                  reportData = filterReportsByDate(dates);
                }

                const report = reportBuilder(reportData);
                setFilteredByDate(report.byDate);
                setSummaryDetails(generateDataSummary(report.summary));
                setSummaryROIDetails(generateROISummary(report.summary));
                setSelectedDateRange(
                  rangeKey === "All Time" ? allTimeRange : dates
                );
                setSelectedRangeKey(rangeKey);
              }}
              hasTooltip
              tooltip={
                <span>
                  Data is processed daily and is not in real time. Google
                  analytics uses data sampling which is designed to speed up
                  reporting. In addition, revenue and transactions are subject
                  to a default 30 day conversion window. Minor discrepancies
                  between this dashboard and the Google Interface are to be
                  expected.
                  <br />
                  <br />
                  Data Sampling - Links to
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article"
                  >
                    https://support.google.com/analytics/answer/2637192?hl=en#zippy=%2Cin-this-article
                  </a>
                </span>
              }
              customScale={summaryScaleLegacy}
            />
          </Col>
        </Row>
        <Row gutter={35}>
          <Col xs={24}>
            <TopLandingPages data={filteredByDate} domain={domain} />
          </Col>
        </Row>
      </>
    );
  };

  const SEODisplay = () => {
    if (!isMultiTab) {
      return <OverallDataView />;
    } else {
      return (
        <Tabs activeKey={currentTab} onChange={key => setCurrentTab(key)}>
          <TabPane tab="Overall Data" key="overall">
            <OverallDataView />
          </TabPane>
          <TabPane tab="Keyword Rankings" key="rankings">
            <KeywordRankingsView
              rankings={rankingsReport}
              generateKeywordRankingsSummary={generateKeywordRankingsSummary}
            />
          </TabPane>
        </Tabs>
      );
    }
  };

  return (
    <React.Fragment>
      <SEODisplay />
    </React.Fragment>
  );
};

export default SummaryView;
