import gql from "graphql-tag";

const GET_CART_PROVIDERS = gql`
  query getCartProviders {
    getCarts {
      label
      value
    }
  }
`;

export default GET_CART_PROVIDERS;
