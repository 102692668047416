import gql from "graphql-tag";

const CREATE_COMMENT = gql`
  mutation createComment($data: CommentCreateInput!) {
    createComment(data: $data) {
      id
      comment
      attachedFiles {
        id
        name
        url
      }
      attachedImages {
        id
        name
        url
      }
      codeSnippet
      createdBy {
        id
        name
      }
      createdAt
    }
  }
`;

export default CREATE_COMMENT;
