import React from "react";
import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Tag,
  Modal,
  Button,
  Typography,
} from "antd";
import {
  PaperClipOutlined,
  PictureOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { uploadFileToS3 } from "../../../shared/upload/uploadFileToS3";
import { CopyBlock } from "react-code-blocks";
import { useState, useRef } from "react";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { message } from "antd";
import { S3Image } from "../../CampaignLauncher/components";
import S3File from "../../../shared/S3File";

const { Text } = Typography;

export const TaskComments = currentTask => {
  const [form] = Form.useForm();
  const [showCodeModal, setShowCodeModal] = useState(false);
  // Mock comments data, pull data from actual API
  const {
    currentUser,
    createComment,
    createCommentLoading,
    fetchTask,
  } = useTaskContext();

  const currentTaskId =
    currentTask && currentTask.currentTask && currentTask.currentTask.id;
  const createdBy =
    currentTask &&
    currentTask.currentTask &&
    currentTask.currentTask.createdBy &&
    currentTask.currentTask.createdBy.name
      ? currentTask.currentTask.createdBy.name
      : "Unknown";

  const initialComments = {
    id: 1,
    createdBy: createdBy,
    createdAt:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.createdAt
        ? new Date(currentTask.currentTask.createdAt).toLocaleDateString()
        : "N/A",
    comment:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.taskDetails
        ? currentTask.currentTask.taskDetails
        : "No task details available",
    attachedFiles:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedFiles
        ? currentTask.currentTask.attachedFiles
        : [],
    attachedImages:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedImages
        ? currentTask.currentTask.attachedImages
        : [],
    codeSnippet:
      currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.codeSnippet
        ? currentTask.currentTask.codeSnippet
        : [],
  };

  const [comments, setComments] = useState([initialComments]);
  const fileInputRef = useRef(null);
  const imageInputRef = useRef(null);

  const handleUploadFileClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadImageClick = () => {
    imageInputRef.current.click();
  };

  const handleFileChange = e => {
    const files = Array.from(e.target.files);
    form.setFieldsValue({
      attachedFiles: [...(form.getFieldValue("attachedFiles") || []), ...files],
    });
    e.target.value = null;
  };

  const handleImageChange = e => {
    const files = Array.from(e.target.files);
    form.setFieldsValue({
      attachedImages: [
        ...(form.getFieldValue("attachedImages") || []),
        ...files,
      ],
    });
    e.target.value = null;
  };

  const handleRemoveFile = index => {
    const attachedFiles = form.getFieldValue("attachedFiles") || [];
    attachedFiles.splice(index, 1);
    form.setFieldsValue({ attachedFiles });
  };

  const handleRemoveImage = index => {
    const attachedImages = form.getFieldValue("attachedImages") || [];
    attachedImages.splice(index, 1);
    form.setFieldsValue({ attachedImages });
  };

  const handleShowCodeModal = () => {
    setShowCodeModal(true);
  };

  const handleEmbedCodeSnippet = () => {
    const codeSnippet = form.getFieldValue("attachedCodeSnippet");
    form.setFieldsValue({
      commentsCodeValue: codeSnippet,
      isCodeSnippet: true,
    });
    setShowCodeModal(false);
  };

  const handleUploadFilesToS3 = async files => {
    try {
      const medias = [];
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const media = await uploadFileToS3({ file });
          medias.push(media);
        }
      }
      return medias;
    } catch (error) {
      console.error(error);
      throw new Error("No greater than 2MB is allowed");
    }
  };

  const handleSubmitComment = async values => {
    const {
      comment,
      attachedFiles,
      attachedImages,
      attachedCodeSnippet,
    } = values;
    let mediaImages = [];
    let mediaFiles = [];

    try {
      message.loading("Posting comment...", 0);

      if (attachedImages && attachedImages.length) {
        mediaImages = await handleUploadFilesToS3(attachedImages);
      }

      if (attachedFiles && attachedFiles.length) {
        mediaFiles = await handleUploadFilesToS3(attachedFiles);
      }

      const commentData = {
        comment: comment,
        attachedImages: mediaImages.length ? { create: mediaImages } : {},
        attachedFiles: mediaFiles.length ? { create: mediaFiles } : {},
        codeSnippet: attachedCodeSnippet || "",
        createdBy: { connect: { id: currentUser.id } },
        task: { connect: { id: currentTaskId } },
      };

      if (!comment) {
        message.destroy(); // Remove loading message
        message.error("Comment cannot be empty.");
        return;
      }

      const { data } = await createComment({
        variables: {
          data: commentData,
        },
      });

      // Extract the newly created comment from the response
      const newComment = data.createComment;

      // Append the new comment to the state
      const formattedComment = {
        id: newComment.id,
        createdBy: newComment.createdBy.name || "Unknown",
        createdAt: new Date(newComment.createdAt).toLocaleDateString() || "N/A",
        comment: newComment.comment,
        attachedFiles: newComment.attachedFiles || [],
        attachedImages: newComment.attachedImages || [],
        codeSnippet: newComment.codeSnippet || "",
      };
      setComments(prevComments => [...prevComments, formattedComment]);

      await fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });

      form.resetFields(); // Clear the form
      message.destroy(); // Remove loading message
      message.success("Comment posted successfully!");
    } catch (error) {
      message.destroy(); // Remove loading message
      message.error(`Failed to post comment: ${error.message}`);
    }
  };

  const onButtonClick = () => {
    form.submit();
  };

  const displayedComments = [
    ...(comments || []),
    ...((currentTask &&
      currentTask.currentTask &&
      currentTask.currentTask.attachedComments) ||
      []),
  ];

  return (
    <div>
      <Form form={form} onFinish={handleSubmitComment} layout="vertical">
        <Card
          style={{
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
          }}
          title={<Text style={{ fontWeight: "bolder" }}>ACTIVITY</Text>}
        >
          {displayedComments.map(comment => (
            <Card key={comment.id} style={{ padding: "0", margin: "0" }}>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontWeight: "bold" }}>
                  {typeof comment.createdBy === "object"
                    ? comment.createdBy.name
                    : comment.createdBy}
                </Text>
                <span style={{ color: "gray" }}>
                  {new Date(comment.createdAt).toLocaleDateString("en-US")}
                </span>
              </Row>
              <Row>
                <Text
                  style={{
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  {typeof comment.comment === "object" &&
                  comment.comment !== null
                    ? comment.comment.text || "No text available"
                    : comment.comment || "No comment available"}
                </Text>
              </Row>
              {comment.attachedFiles &&
                comment.attachedFiles.map((file, index) => (
                  <Row
                    key={index}
                    style={{ paddingTop: "5px", display: "block" }}
                  >
                    {/* <Tag
                      onClick={async () => {
                        try {
                          let url = file.url;

                          // Attempt to download the file
                          const downloadFile = async fileUrl => {
                            const response = await fetch(fileUrl, {
                              method: "GET",
                            });
                            if (!response.ok) {
                              throw new Error("Download failed");
                            }
                            const blob = await response.blob();
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = file.name;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(link.href);
                          };

                          try {
                            await downloadFile(url);
                          } catch (error) {
                            // If initial download fails, attempt to refresh the URL
                            if (error.message === "Download failed") {
                              message.warning("File URL is expired");
                            }
                          }
                        } catch (error) {
                          message.error(
                            "Error downloading the file. Please try again."
                          );
                        }
                      }}
                      onClose={() => handleRemoveFile(index)}
                      icon={<PaperClipOutlined />}
                      style={{
                        marginBottom: "4px",
                        display: "block",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        width: "fit-content",
                        cursor: "pointer",
                      }}
                    >
                      {file.name}
                    </Tag> */}
                    <S3File key={index} file={file} />
                  </Row>
                ))}
              {comment.attachedImages &&
                comment.attachedImages.map((file, index) => (
                  <Row
                    key={index}
                    style={{
                      paddingTop: "5px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* <Tag
                      onClick={async () => {
                        try {
                          const response = await fetch(file.url, { method: "GET" });
                          const blob = await response.blob();
                          const link = document.createElement("a");
                          link.href = URL.createObjectURL(blob);
                          link.download = file.name; 
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          URL.revokeObjectURL(link.href);
                        } catch (error) {
                          message.error("Error downloading the file:", error);
                        }
                      }}
                      icon={<PictureOutlined />}
                      style={{
                        marginBottom: "4px",
                        display: "block",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        width: "fit-content",
                        cursor: "pointer",
                      }}
                    >
                      {file.name || "Unnamed Image"}
                    </Tag> */}
                    <S3Image
                      key={index}
                      imgKey={file.key}
                      height="25%"
                      width="25%"
                    />
                  </Row>
                ))}
              {typeof comment.codeSnippet === "string" &&
                comment.codeSnippet.trim() && (
                  <Col
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    <CopyBlock
                      text={comment.codeSnippet}
                      language="javascript"
                      wrapLongLines
                      showLineNumbers={false}
                      theme="dracula"
                      codeBlock
                    />
                  </Col>
                )}
            </Card>
          ))}
        </Card>
        <Card>
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              position: "relative",
              padding: "5px",
            }}
          >
            <Row style={{ flexGrow: 1 }}>
              <Form.Item name="comment" noStyle>
                <Input.TextArea
                  maxLength={500}
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  placeholder="Add a comment"
                  style={{
                    border: "none",
                    outline: "none",
                    resize: "none",
                    width: "100%",
                    padding: "5px",
                  }}
                />
              </Form.Item>
            </Row>

            {/* Hidden files input */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .ppt, .pptx"
              onChange={handleFileChange}
            />
            <input
              type="file"
              ref={imageInputRef}
              style={{ display: "none" }}
              multiple
              accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
              onChange={handleImageChange}
            />
            {/* Displaying selected files as tags */}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.attachedFiles !== currentValues.attachedFiles
              }
            >
              {() => {
                const attachedFiles = form.getFieldValue("attachedFiles") || [];
                return (
                  attachedFiles.length > 0 && (
                    <Row
                      style={{
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      {attachedFiles.map((file, index) => (
                        <Tag
                          closable
                          key={index}
                          onClose={() => handleRemoveFile(index)}
                          icon={<PaperClipOutlined />}
                          style={{
                            marginBottom: "4px",
                            display: "block",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            width: "fit-content",
                          }}
                        >
                          {file.name}
                        </Tag>
                      ))}
                    </Row>
                  )
                );
              }}
            </Form.Item>
            {/* Displaying selected images as tags */}
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.attachedImages !== currentValues.attachedImages
              }
            >
              {() => {
                const attachedImages =
                  form.getFieldValue("attachedImages") || [];
                return (
                  attachedImages.length > 0 && (
                    <Row
                      style={{
                        display: "block",
                        marginTop: "10px",
                      }}
                    >
                      {attachedImages.map((file, index) => (
                        <Tag
                          closable
                          key={index}
                          onClose={() => handleRemoveImage(index)}
                          icon={<PictureOutlined />}
                          style={{
                            marginBottom: "4px",
                            display: "block",
                            whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            width: "fit-content",
                          }}
                        >
                          {file.name}
                        </Tag>
                      ))}
                    </Row>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isCodeSnippet !== currentValues.isCodeSnippet ||
                prevValues.commentsCodeValue !== currentValues.commentsCodeValue
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("isCodeSnippet") &&
                getFieldValue("commentsCodeValue") && (
                  <Col
                    style={{
                      maxWidth: "100%",
                      maxHeight: "300px",
                      overflowY: "auto",
                      overflowX: "auto",
                    }}
                  >
                    <CopyBlock
                      text={getFieldValue("commentsCodeValue")}
                      language="javascript"
                      wrapLongLines
                      showLineNumbers={false}
                      theme="dracula"
                      codeBlock
                    />
                  </Col>
                )
              }
            </Form.Item>
          </Row>
          <Row
            style={{
              display: "flex",
              paddingTop: "10px",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Col style={{ display: "flex" }}>
              <Form.Item name="attachedFiles" noStyle>
                <PaperClipOutlined
                  style={{
                    paddingInline: "5px",
                    color: "gray",
                    fontSize: "20px",
                  }}
                  onClick={handleUploadFileClick}
                />
              </Form.Item>
              <Form.Item name="attachedImages" noStyle>
                <PictureOutlined
                  style={{
                    paddingInline: "5px",
                    color: "gray",
                    fontSize: "20px",
                  }}
                  onClick={handleUploadImageClick}
                />
              </Form.Item>
              <CodeOutlined
                style={{
                  paddingInline: "5px",
                  color: "gray",
                  fontSize: "20px",
                }}
                onClick={handleShowCodeModal}
              />
              <Modal
                closable={false}
                visible={showCodeModal}
                bodyStyle={{ height: "300px", padding: "0" }}
                footer={
                  <>
                    <Button
                      onClick={() => setShowCodeModal(false)}
                      style={{ marginLeft: "8px" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleEmbedCodeSnippet}
                      style={{ marginLeft: "8px" }}
                    >
                      Embed
                    </Button>
                  </>
                }
              >
                <Card
                  title={
                    <Text style={{ fontWeight: "bolder" }}>
                      SEND CODE SNIPPET
                    </Text>
                  }
                >
                  <Row style={{ paddingBottom: "20px" }}>
                    <Text>
                      Embed a code snippet by pasting the code into the field
                      below:
                    </Text>
                  </Row>
                  <Row
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "1px solid #d9d9d9",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Row style={{ flexGrow: 1 }}>
                      <Form.Item name="attachedCodeSnippet" noStyle>
                        <Input.TextArea
                          maxLength={5000}
                          autoSize={{ minRows: 6, maxRows: 6 }}
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "none",
                            outline: "none",
                            resize: "none",
                            width: "100%",
                            margin: "5px",
                          }}
                        />
                      </Form.Item>
                    </Row>
                  </Row>
                </Card>
              </Modal>
            </Col>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={onButtonClick} disabled={createCommentLoading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
