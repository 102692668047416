import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Popconfirm,
} from "antd";
import { PageHeaderLayout, PageContentLayout } from "../../../../shared/layout";
import { AimOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSEOLinkMaintenanceContext } from "../../../../../core/components/seoRefactored/seoDetails/context/SEOLinkMaintenanceContext";
import UploadLinksModal from "../components/UploadLinksModal";
import { contentTypeOptions } from "../../../../../core/utils/constants/constants";

// Function to convert content type to all caps
const formatContentType = str => str.toUpperCase();

// URL validation
const validateUrl = (_, value) => {
  if (!value) return Promise.reject("URL is required");
  const cleanUrl = value.replace(/^https?:\/\//, "");
  const urlPattern = /^([a-zA-Z0-9][a-zA-Z0-9-]*\.)+[a-zA-Z]{2,}$/;

  return urlPattern.test(cleanUrl)
    ? Promise.resolve()
    : Promise.reject("Please enter a valid URL");
};

const LinkMaintenanceView = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const {
    linkAcquisitions,
    id,
    orgName,
    createAcquiredLink,
    updateAcquiredLink,
    deleteAcquiredLink,
  } = useSEOLinkMaintenanceContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [editingLink, setEditingLink] = useState(null);
  const [filteredLinks, setFilteredLinks] = useState(linkAcquisitions);

  useEffect(() => {
    setFilteredLinks(linkAcquisitions);
  }, [linkAcquisitions]);

  const handleSearch = value => {
    const lowercasedValue = value.toLowerCase();
    const filtered = linkAcquisitions.filter(
      link =>
        link.url.toLowerCase().includes(lowercasedValue) ||
        link.type.toLowerCase().includes(lowercasedValue) ||
        link.destinationURL.toLowerCase().includes(lowercasedValue)
    );
    setFilteredLinks(filtered);
  };

  const handleUpload = () => {
    setIsUploadModalVisible(true);
  };

  const handleUploadModalCancel = () => {
    setIsUploadModalVisible(false);
  };

  const handleAdd = () => {
    setModalMode("create");
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleEdit = record => {
    setModalMode("edit");
    setEditingLink(record);

    const sourceUrl = record.url.replace(/^https?:\/\//, "");
    const destinationUrl = record.destinationURL.replace(/^https?:\/\//, "");

    form.setFieldsValue({
      sourceUrl: sourceUrl,
      destinationUrl: destinationUrl,
      contentType: formatContentType(record.type),
    });
    setIsModalVisible(true);
  };

  const handleRemove = async linkId => {
    try {
      await deleteAcquiredLink(linkId);
    } catch (error) {
      console.error("Error removing link:", error);
    }
  };

  const onCancel = () => {
    setIsModalVisible(false);
    setEditingLink(null);
    form.resetFields();
  };

  const onFinish = async values => {
    const { sourceUrl, destinationUrl, contentType } = values;

    const updatedSourceUrl = sourceUrl.startsWith("http")
      ? sourceUrl
      : `https://${sourceUrl}`;
    const updatedDestinationUrl = destinationUrl.startsWith("http")
      ? destinationUrl
      : `https://${destinationUrl}`;

    try {
      if (modalMode === "create") {
        await createAcquiredLink(
          updatedSourceUrl,
          updatedDestinationUrl,
          contentType
        );
      } else {
        await updateAcquiredLink(
          editingLink.key,
          updatedSourceUrl,
          updatedDestinationUrl,
          contentType
        );
      }

      setIsModalVisible(false);
      setEditingLink(null);
      form.resetFields();
    } catch (error) {
      console.error("Error in onFinish:", error);
    }
  };

  const columns = [
    {
      title: "Source URL",
      dataIndex: "url",
      render: text => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Content Type",
      dataIndex: "type",
      render: text => formatContentType(text),
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit Link
          </Button>
          <Popconfirm
            title="Are you sure you want to remove this link?"
            onConfirm={() => handleRemove(record.key)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Remove Link</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <PageHeaderLayout
        titleIcon={<AimOutlined />}
        title={orgName}
        rightContent={
          <Button onClick={() => history.push(`/seo/details/${id}`)}>
            Back
          </Button>
        }
      />
      <PageContentLayout>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card
              title={"LINKS MAINTENANCE"}
              extra={
                <>
                  <Input.Search
                    placeholder="Search acquired links"
                    onSearch={handleSearch}
                    style={{ width: 250, marginRight: 10 }}
                    allowClear={true}
                  />
                  <Button onClick={handleUpload} style={{ marginRight: 10 }}>
                    Upload Acquired Links
                  </Button>
                  <Button type="primary" onClick={handleAdd}>
                    Add Acquired Link
                  </Button>
                </>
              }
            >
              <Table
                style={{ width: "100%" }}
                columns={columns}
                dataSource={filteredLinks}
                size={"default"}
                scroll={{ x: 300 }}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
        <Modal
          visible={isModalVisible}
          title={modalMode === "create" ? "ADD NEW LINK" : "EDIT LINK"}
          onCancel={onCancel}
          width={600}
          footer={[
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={form.submit}>
              {modalMode === "create" ? "Create" : "Update"}
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="sourceUrl"
              label="Source URL"
              extra="Enter the acquired link source URL"
              rules={[
                { required: true, message: "Please enter the source URL" },
                { validator: validateUrl },
              ]}
            >
              <Input addonBefore="https://" placeholder="Enter URL" />
            </Form.Item>

            <Form.Item
              name="destinationUrl"
              label="Destination URL"
              extra="Enter destination URL - Where the acquired link takes users"
              rules={[
                { required: true, message: "Please enter the destination URL" },
                { validator: validateUrl },
              ]}
            >
              <Input addonBefore="https://" placeholder="Enter URL" />
            </Form.Item>

            <Form.Item
              name="contentType"
              label="Content Type"
              extra="Select the content type for this acquired link"
              rules={[
                { required: true, message: "Please select a content type" },
              ]}
            >
              <Select placeholder="Select content type">
                {contentTypeOptions.map(option => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        {/* Upload Modal */}
        <UploadLinksModal
          visible={isUploadModalVisible}
          onCancel={handleUploadModalCancel}
        />
      </PageContentLayout>
    </React.Fragment>
  );
};

export default LinkMaintenanceView;
