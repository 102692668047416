import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  UploadOutlined,
  DeleteOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import PaginationTable from "../../../shared/PaginationTable";
// import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Input,
  Col,
  Row,
  Progress,
  Form,
  message,
  Popconfirm,
  Spin,
  Space,
  Upload,
  Empty,
  Modal,
  Divider,
  Typography,
  Tooltip,
  Skeleton,
  Switch,
  Select,
  Popover,
} from "antd";
import Papa from "papaparse";
import {
  encryptStringSHA512,
  encryptStringSHA256,
  encryptStringSHA3,
  encryptStringSHA1,
  encryptStringMD5,
} from "../../../../core/utils/encryptString";
import TemplateCSVDownloader from "../../../shared/TemplateCSVDownloader";
import { HashedEmailConfig } from "../configs/emailConfig";
const { Dragger } = Upload;
const { Text } = Typography;

const EmailTab = ({
  activateHashedEmailList,
  createHashedEmailList,
  bulkCreateHashedEmailAddress,
  hashedEmailLists,
  deleteHashedEmailList,
  refetchHashedEmailLists,
  currentPage,
  loadingHashedEmailLists,
  processDeviceIds,
}) => {
  const [form] = Form.useForm();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadComplete, setUploadComplete] = useState(false);
  const [isEmailsProcessed, setEmailsProcessed] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showUploadModal, setSetShowModal] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const [emails, setEmails] = useState([]);
  const [savedHashedEmailCount, setHashedEmailCount] = useState(0);
  const [emailsAlreadyHashed, setEmailsAlreadyHashed] = useState(false);
  const [selectedHashingMethod, setSelectedHashingMethod] = useState("");
  const [visible, setModalVisibility] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortOrder, setSortOrder] = useState("ascending");
  const [sortValue, setSortValue] = useState("descend");
  const emailRegex = /\S+@\S+\.\S+/;
  const history = useHistory();

  const sortIcon =
    sortOrder === "ascending" ? (
      <SortAscendingOutlined />
    ) : (
      <SortDescendingOutlined />
    );

  const hashedEmailListExtracted =
    !Array.isArray(hashedEmailLists) &&
    hashedEmailLists &&
    hashedEmailLists.getHashedEmailListWithCount.hashedEmailList
      ? hashedEmailLists.getHashedEmailListWithCount.hashedEmailList
      : [];

  const emailModalColumn = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: text => {
        if (isEmailsProcessed && !emailsAlreadyHashed) {
          const isValidEmail = emailRegex.test(text);
          return (
            <span
              style={{
                color: isValidEmail ? "inherit" : "red",
              }}
            >
              {text} {isValidEmail ? "" : "(Invalid Value)"}
            </span>
          );
        } else if (isEmailsProcessed && emailsAlreadyHashed) {
          const isEmail = emailRegex.test(text);
          return (
            <span
              style={{
                color: !isEmail ? "inherit" : "red",
              }}
            >
              {text} {!isEmail ? "" : "(Invalid Value)"}
            </span>
          );
        } else {
          return <span>{text}</span>;
        }
      },
      sorter: (a, b) => {
        const isValidA = emailsAlreadyHashed
          ? !emailRegex.test(a.email)
          : emailRegex.test(a.email);
        const isValidB = emailsAlreadyHashed
          ? !emailRegex.test(b.email)
          : emailRegex.test(b.email);

        if (isValidA && !isValidB) {
          return -1; // valid emails come before invalid emails
        } else if (!isValidA && isValidB) {
          return 1; // invalid emails come after valid emails
        } else {
          return 0; // maintain the original order
        }
      },
      defaultSortOrder: "descend",
      sortOrder: sortValue,
    },
    {
      title: "Option",
      dataIndex: "option",
      key: "option",
      render: (_, email) => (
        <Popconfirm
          title="Are you sure you want to delete this email?"
          onConfirm={async e => {
            const index = emails.findIndex(element => element === email.email);
            if (index !== -1) {
              const newData = [...emails]; // create a new array with the same elements as the state array
              newData.splice(index, 1); // remove the element at the found index
              setEmails(newData); // set the state to the new array
              message.success("Email deleted");
            }
          }}
          onCancel={e => {
            message.warning("Cancelled");
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  const ProcessDeviceIdsButton = ({
    emailList,
    processDeviceIds,
    refetchHashedEmailLists,
  }) => {
    const [open, setOpen] = useState(false);
    const isDisabled = !emailList.activated || emailList.isProcessedByStatera;

    const confirm = async () => {
      setOpen(false);
      // reused setIsUploading to show loading spinner, instead of making a new state
      setIsUploading(true);
      message.info("Processing email list...");
      const response = await processDeviceIds({
        variables: { emailListId: emailList.id },
      });
      if (response && response.data.processDeviceIds.deviceIdsProcessed > 0) {
        await refetchHashedEmailLists();
        message.destroy();
        setIsUploading(false);
        message.success("Email list processed.");
      } else {
        message.destroy();
        setIsUploading(false);
        message.warning("No device IDs were processed.");
      }
    };

    const handleOpenChange = newOpen => {
      if (!newOpen) {
        setOpen(newOpen);
        return;
      }
      if (!isDisabled) {
        setOpen(newOpen);
      }
    };

    return (
      <Popconfirm
        title="Do you want to process this email list?"
        visible={open}
        onVisibleChange={handleOpenChange}
        onConfirm={e => {
          e.stopPropagation();
          confirm();
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button
          style={{ display: "flex", justifyContent: "start" }}
          disabled={isDisabled}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        >
          Process Device IDs
        </Button>
      </Popconfirm>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: text => (
        <Tooltip title={text} placement="topLeft">
          <span>{text}</span>
        </Tooltip>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email Count",
      dataIndex: "emailCount",
      key: "emailCount",
      render: (_, record) => {
        return record.hashedEmailsCount;
      },
      sorter: (a, b) => a.hashedEmailsCount - b.hashedEmailsCount,
    },
    {
      title: "Device",
      dataIndex: "deviceCount",
      key: "deviceCount",
      align: "right",
      render: (_, record) => {
        return Math.ceil(record.deviceCount)
          ? Math.ceil(record.deviceCount).toLocaleString()
          : "N/A";
      },
      sorter: (a, b) => a.deviceCount - b.deviceCount,
    },
    {
      title: "Match %",
      dataIndex: "matchRate",
      key: "matchRate",
      align: "right",
      render: (_, record) => {
        const matchRate =
          record.matchRate !== null && record.matchRate !== undefined
            ? record.matchRate.toFixed(2).toLocaleString() + "%"
            : "N/A";
        return matchRate;
      },
      sorter: (a, b) => a.matchRate - b.matchRate,
    },
    {
      title: "Status",
      dataIndex: "activated",
      key: "activated",
      render: (text, record) => (record.activated ? "Activated" : "Inactive"),
      sorter: (a, b) => a.activated - b.activated,
    },

    {
      title: "Device IDs",
      dataIndex: "processDeviceId",
      key: "processDeviceId",
      render: (_, emailList) => (
        <ProcessDeviceIdsButton
          emailList={emailList}
          processDeviceIds={processDeviceIds}
          refetchHashedEmailLists={refetchHashedEmailLists}
        />
      ),
    },
    {
      title: "Segment Information",
      dataIndex: "segmentInformation",
      key: "segmentInformation",
      render: (_, record) => {
        const segmentInfo =
          record.dspSegmentIds && record.dspSegmentIds.length
            ? record.dspSegmentIds[0]
            : { segmentId: "", segmentName: "" };
        return (
          <>
            <span>ID: {segmentInfo.segmentId}</span>
            <br />
            <span>Name: {segmentInfo.segmentName}</span>
          </>
        );
      },
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Uploaded",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: text => {
        if (!text) return "-";
        return moment(text).format("MM/DD/YYYY - hh:mma");
      },
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];

  const removeDuplicates = arr => {
    return Array.from(new Set(arr));
  };

  const convertToLowercase = arr => {
    return arr.map(e => e.toLowerCase());
  };

  const containsEmail = arr => {
    return arr.some(element => emailRegex.test(element));
  };

  const findInvalidEmail = arr => {
    return arr.find(element => !emailRegex.test(element)) || null;
  };

  function hasNonHashedValue(arr) {
    const hexRegex = /^[0-9a-fA-F]+$/;
    return !arr.every(str => {
      return (
        (str.length === 32 ||
          str.length === 40 ||
          str.length === 64 ||
          str.length === 128) &&
        hexRegex.test(str) &&
        str !==
          str
            .split("")
            .reverse()
            .join("")
      );
    });
  }

  const startUpload = async () => {
    let encryptedEmails = [];

    if (isNameEmpty()) {
      return message.error("Please input list name.");
    }

    if (
      emailsAlreadyHashed &&
      (containsEmail(emails) || hasNonHashedValue(emails))
    ) {
      return message.warning(
        "One or more of the values in your data contains an email address. Please ensure that all values are hashed or use an email-free dataset."
      );
    }

    if (!emailsAlreadyHashed && findInvalidEmail(emails)) {
      return message.warning(
        "One or more of the values in your data is not a valid email address. Please correct your data or use an email-free dataset."
      );
    }

    if (!emailsAlreadyHashed) {
      encryptedEmails = emails.map(email =>
        encryptStringBasedOnHashSelection(email)
      );
      setEmails(encryptedEmails);
    } else {
      encryptedEmails = emails;
    }

    setEmailsProcessed(false);
    setIsUploading(true);

    const chunkSize =
      selectedHashingMethod === "SHA3" || selectedHashingMethod === "SHA512"
        ? 500
        : 1000;

    let progressCount = 0;
    let isLastIteration = false;

    const response = await createHashedEmailList({
      name: form.getFieldValue("name"),
    });

    const emailListId = response.data.createHashedEmailList.id;

    for (let i = 0; i < encryptedEmails.length; i += chunkSize) {
      const chunk = encryptedEmails.slice(i, i + chunkSize);
      progressCount += chunkSize;

      isLastIteration = i + chunkSize >= encryptedEmails.length;
      const response = await bulkCreateHashedEmailAddress({
        emailListId: emailListId,
        emails: chunk,
        refetch: isLastIteration,
      });

      setHashedEmailCount(
        prevCount =>
          prevCount + response.data.bulkCreateHashedEmailAddress.uniqueEmails
      );

      setProgress(Math.floor((progressCount / encryptedEmails.length) * 100));
    }

    setIsUploading(false);
    setUploadComplete(true);
    refetchHashedEmailLists();
  };

  const activateAllEmails = async data => {
    setIsUploading(true);
    const response = await activateHashedEmailList({});
    if (
      response &&
      response.data &&
      response.data.activateHashedEmailList &&
      response.data.activateHashedEmailList.success
    ) {
      message.success("Emails Activated");
      await refetchHashedEmailLists();
    } else if (
      response &&
      response.data &&
      response.data.activateHashedEmailList &&
      response.data.activateHashedEmailList.error
    ) {
      message.error(response.data.activateHashedEmailList.error);
    } else {
      message.error(
        "No emails were activated. Please make sure there are emails to activate."
      );
    }

    setIsUploading(false);
  };

  const changeHashingMethod = hashingMethod => {
    setSelectedHashingMethod(hashingMethod);
  };

  const handleEmailHashToggle = checked => {
    setEmailsAlreadyHashed(checked);
    setSortValue("descend");
  };

  const handleSortByName = () => {
    let sorted;
    if (sortOrder === "ascending") {
      sorted = [...emails].sort((a, b) => a.localeCompare(b));
      setSortOrder("descending");
    } else {
      sorted = [...emails].sort((a, b) => b.localeCompare(a));
      setSortOrder("ascending");
    }
    setEmails(sorted);
  };

  const encryptStringBasedOnHashSelection = value => {
    switch (selectedHashingMethod) {
      case "SHA512":
        return encryptStringSHA512(value);
      case "SHA256":
        return encryptStringSHA256(value);
      case "SHA3":
        return encryptStringSHA3(value);
      case "SHA1":
        return encryptStringSHA1(value);
      case "MD5":
        return encryptStringMD5(value);
      default:
        return encryptStringSHA256(value);
    }
  };

  const customRequest = ({ file, onError }) => {
    if (file.name.toLowerCase().includes("csv")) {
      Papa.parse(file, {
        skipEmptyLines: "greedy",
        error: function(err, file, inputElem, reason) {
          onError({ err, file, inputElem, reason });
        },
        complete: async results => {
          const currentEmails = removeDuplicates(results.data.flat(4));
          const lowerCaseEmails = convertToLowercase(currentEmails);
          const combinedEmails = [...emails, ...lowerCaseEmails];
          const uniqueEmails = removeDuplicates(combinedEmails);
          setEmails(uniqueEmails);
        },
      });
    } else {
      message.error("Invalid file type. Only accepts .csv");
      return;
    }
  };

  const handleCloseUploadModal = () => {
    form.resetFields();
    setEmails([]);
    setErrorText("");
    setProgress(0);
    setHashedEmailCount(0);
    setUploadComplete(false);
    setSetShowModal(false);
    setEmailsAlreadyHashed(false);
  };

  const isNameEmpty = () => {
    const nameValue = form.getFieldValue("name");
    return !nameValue || nameValue.trim() === "";
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleBatchDelete = async () => {
    try {
      setIsUploading(true);
      const hasActivatedItem = selectedRows.some(row => row.activated === true);

      if (hasActivatedItem) {
        message.warning(
          "One or more of the selected email lists are activated. Please deselect them and try again."
        );
        return;
      }
      // Map each row to a delete promise
      const deletePromises = selectedRows.map(async row => {
        return deleteHashedEmailList(row); // Return the promise for deletion
      });

      // Wait for all promises to resolve
      await Promise.all(deletePromises);

      await refetchHashedEmailLists();

      message.success(
        "All selected email lists have been successfully deleted."
      );
    } catch (error) {
      message.error("An error occurred while deleting the email lists.");
    } finally {
      setSelectedRows([]);
      setSelectedRowKeys([]);
      setIsUploading(false);
    }
  };

  return (
    <>
      <Modal
        title="Email Upload"
        visible={showUploadModal}
        onCancel={handleCloseUploadModal}
        closable={!isUploading}
        maskClosable={false}
        keyboard={false}
        style={{ top: 20 }}
        bodyStyle={{ height: "79vh", overflowY: "scroll" }}
        width="80%"
        footer={[
          <Button
            key="back"
            onClick={handleCloseUploadModal}
            disabled={isUploading}
          >
            Close
          </Button>,
          <Button
            key="ok"
            type="primary"
            htmlType="submit"
            onClick={startUpload}
            disabled={
              emails.length === 0 || isUploadComplete === true || isUploading
            }
          >
            Upload
          </Button>,
        ]}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form
            form={form}
            name="basic"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={startUpload}
            style={{ marginBottom: "0px", width: "60%" }}
          >
            <Form.Item
              label="Name"
              name="name"
              style={{ marginBottom: "0px" }}
              rules={[
                { required: true, message: "Please input your list name." },
              ]}
            >
              <Input
                placeholder="Email List Name"
                style={{
                  width: "100%",
                  alignSelf: "flex-start",
                }}
                disabled={isUploading}
              />
            </Form.Item>
          </Form>
          <TemplateCSVDownloader
            tooltipMessage="CSV Template for Email list"
            rows={HashedEmailConfig.csvTemplate.rows}
            templateName={HashedEmailConfig.csvTemplate.templateName}
            styles={{ margin: "0px", height: "34px" }}
            title="Sample Email list template"
          ></TemplateCSVDownloader>
        </div>

        <div style={{ marginTop: "0.2em" }}>
          <Popover
            content={
              <ol style={{ fontSize: "14px" }}>
                <li>Enter the Email List Name</li>
                <li>
                  Upload a CSV file. Please make sure to remove the header in
                  the column. Download the Sample Template for reference
                </li>
                <li>
                  Select your desired options
                  <ol>
                    <li>
                      If your CSV contains hashed email, turn on the option for
                      emails already being hashed
                    </li>
                    <li>Alternatively, select your desired hashing method</li>
                  </ol>
                </li>
                <li>Click on the Upload button</li>
              </ol>
            }
            title="Instructions on how to use the Email Uploader feature"
          >
            <Button type="default" size="small">
              Hover to see Instructions
            </Button>
          </Popover>
        </div>

        <Divider />

        {(isUploading === true || isUploadComplete === true) && (
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Progress
                percent={progress}
                status={errorText ? "exception" : "active"}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {isUploadComplete === true && (
                <Text
                  type="success"
                  style={{ color: "#52c41a", textAlign: "center" }}
                >
                  Upload Complete.{" "}
                  {`${savedHashedEmailCount}/${emails.length} emails were successfully saved.`}
                </Text>
              )}
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {errorText ? (
                <div style={{ color: "red" }}> Error: {errorText}</div>
              ) : (
                <></>
              )}
            </div>
          </>
        )}

        <div style={{ marginTop: "0.5em" }}>
          <Row justify="space-between" align="top">
            <Col span={12}>
              Emails are already hashed{" "}
              <Tooltip
                title={"Enable this option if the emails are already hashed."}
              >
                <Switch
                  defaultChecked={false}
                  checked={emailsAlreadyHashed}
                  onChange={handleEmailHashToggle}
                />
              </Tooltip>
            </Col>
            <Col span={12} align="right">
              Hashed Method Selection{" "}
              <Select
                defaultValue="SHA256"
                disabled={emailsAlreadyHashed}
                onChange={(_, val) => {
                  changeHashingMethod(val.value);
                }}
                options={[
                  {
                    value: "SHA512",
                    label: "SHA512",
                  },
                  {
                    value: "SHA256",
                    label: "SHA256",
                  },
                  {
                    value: "SHA3",
                    label: "SHA3",
                  },
                  {
                    value: "SHA1",
                    label: "SHA1",
                  },
                  {
                    value: "MD5",
                    label: "MD5",
                  },
                ]}
              />
            </Col>
          </Row>
        </div>

        <Spin spinning={isUploading} size="large">
          {emails.length === 0 && (
            <>
              <div style={{ marginTop: "2em" }}>
                <Dragger
                  accept={".csv"}
                  showUploadList={false}
                  multiple={false}
                  customRequest={params =>
                    customRequest({
                      ...params,
                    })
                  }
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag CSV file to this area to upload
                  </p>
                  <p className="ant-upload-hint">Import your Emails here.</p>
                </Dragger>
              </div>
            </>
          )}

          {emails.length > 0 && (
            <div
              style={{
                height: "60vh",
                overflowY: "scroll",
                textAlign: "right",
              }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "0.4em" }}
              >
                <Col>
                  <Upload
                    accept={".csv"}
                    showUploadList={false}
                    multiple={false}
                    customRequest={params =>
                      customRequest({
                        ...params,
                      })
                    }
                  >
                    <Button
                      icon={<UploadOutlined />}
                      disabled={isUploadComplete}
                    >
                      Add more
                    </Button>
                  </Upload>
                  <Popconfirm
                    title="Are you sure you want to delete all emails?"
                    onConfirm={() => {
                      setEmails([]);
                      message.success("Emails deleted sucessfully");
                      setModalVisibility(false);
                    }}
                    visible={visible}
                    onCancel={() => {
                      message.warning("Cancelled");
                      setModalVisibility(false);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={emails.length === 0}
                      style={{ marginLeft: "0.5em" }}
                      onClick={e => {
                        e.stopPropagation();
                        setModalVisibility(true);
                      }}
                    >
                      Delete all
                    </Button>
                  </Popconfirm>
                  <Button
                    icon={sortIcon}
                    disabled={emails.length === 0}
                    style={{ marginLeft: "0.5em" }}
                    onClick={() => {
                      handleSortByName();
                    }}
                  >
                    Sort by Name
                  </Button>
                </Col>
                <Col>
                  {emails.length && (
                    <Tooltip
                      title={
                        "Some of the emails in your CSV file are duplicates, and they have been automatically removed."
                      }
                    >
                      <Text type="secondary" style={{ textAlign: "right" }}>
                        Email Count: {emails.length}
                      </Text>
                    </Tooltip>
                  )}
                </Col>
              </Row>

              <PaginationTable
                columns={emailModalColumn}
                data={emails.map(email => {
                  return { email: email };
                })}
                total={emails.length}
                bordered={false}
                paginationSize={"default"}
                size={"small"}
                current={currentPage}
                pageSize={pageSize}
                scroll={{ x: 700 }}
                rowKey="email"
                showSorterTooltip={false}
                sortDirections={[]}
                onChange={options => {
                  setPageSize(options.pageSize);
                  setSortValue(sortValue === "ascend" ? "descend" : "ascend");
                }}
              />
            </div>
          )}
        </Spin>
      </Modal>
      <Spin spinning={isUploading} size="large">
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row gutter={[15, 15]} justify="space-between">
            <Col xs={12} lg={12}>
              <Row>
                <Col xs={12} lg={12}>
                  <Button
                    style={{ marginRight: "0.4em" }}
                    disabled={!selectedRows.length}
                    onClick={handleBatchDelete}
                  >
                    Delete
                  </Button>
                  <Button
                    onClick={activateAllEmails}
                    disabled={
                      !hashedEmailListExtracted ||
                      hashedEmailListExtracted.length === 0
                    }
                  >
                    Activate Emails
                  </Button>
                </Col>
                <Col xs={12} lg={12}></Col>
              </Row>
            </Col>
            <Col
              xs={12}
              lg={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setSetShowModal(true);
                  setEmailsProcessed(false);
                }}
              >
                Upload Emails
              </Button>
            </Col>
          </Row>
          <Row>
            {}
            <Col xs={24} lg={24}>
              {loadingHashedEmailLists && <Skeleton active rows={10} />}
              {hashedEmailListExtracted &&
              hashedEmailListExtracted.length > 0 ? (
                <PaginationTable
                  rowSelection={rowSelection}
                  loading={loadingHashedEmailLists}
                  columns={columns}
                  data={hashedEmailListExtracted}
                  total={hashedEmailListExtracted.length}
                  bordered={false}
                  paginationSize={"default"}
                  size={"default"}
                  current={currentPage}
                  pageSize={pageSize}
                  scroll={{ x: 700 }}
                  rowKey="id"
                  onChange={options => {
                    setPageSize(options.pageSize);
                  }}
                  onRow={record => {
                    return {
                      onClick: () => {
                        history.push(`/audience/details/${record.id}`);
                      },
                    };
                  }}
                />
              ) : (
                <>{!loadingHashedEmailLists && <Empty />}</>
              )}
            </Col>
          </Row>
        </Space>
      </Spin>
    </>
  );
};

export default EmailTab;
