import { Storage } from "aws-amplify";

export const uploadFileToS3 = async ({ file, mediaType = "STILL" }) => {
  try {
    const { name, size, type } = file;

    if (size > 2000000) {
      throw new Error("File can't exceed 2MB");
    }

    const fname = `${new Date().getTime()}-${name}`;
    const response = await Storage.put(fname, file, {
      contentType: type, // Changed to use the file's type directly
    });

    const { key } = response;
    const url = await Storage.get(key, {
      // TODO: Create a global config for expiry time
      expires: 600000, // 10 minutes expiry time
    });

    return {
      key,
      url,
      name,
      size,
      fileType: key.split(".")[1].toUpperCase(),
      type: mediaType,
    };
  } catch (error) {
    throw new Error("Error uploading file");
  }
};
