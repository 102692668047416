/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

// Ant Design
import { Card, Row, Col, Grid, Button, Spin } from "antd";

// Custom Components
import { PageContentLayout, PageHeaderLayout } from "../../shared/layout";
import { DotChartOutlined, FormOutlined } from "@ant-design/icons";
import { ButtonText } from "../../shared/globalStyling/styledText";

// Demographic Components
import DemographicsAudienceTable from "./components/DemographicsAudienceTable";
import FilterBar from "./components/FilterBar";

// Utils
import { isMobileScreen } from "./utils/mobileUtils";

const DemographicsMainView = ({
  history,
  audienceDemographs,
  handleUpdateAudience,
  handleFetchAudienceDemographs,
  handleSaveAudiencesToApi,
  loadingAudienceDemographs,
  loadingPublishing,
}) => {
  const audienceDemographsData =
    audienceDemographs && audienceDemographs.audienceDemographs
      ? audienceDemographs.audienceDemographs
      : [];

  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();

  useEffect(() => {
    // Fetch Audience Demographs on Component Mount
    handleFetchAudienceDemographs();
  }, []);

  return (
    <>
      <PageHeaderLayout
        title={"Demograph"}
        titleIcon={
          !isMobileScreen(breakPoint) && (
            <DotChartOutlined
              style={{
                marginRight: "10px",
                color: "lightgrey",
                fontSize: 20,
              }}
            />
          )
        }
        rightContent={
          <Row style={{ padding: "15px" }}>
            <Col className="ant-row-flex-column ant-row-align-items-center">
              <Button
                onClick={() => {
                  history.push("/demograph/audience_builder/");
                }}
                type="primary"
                ghost
                icon={<FormOutlined />}
              >
                <ButtonText text={"Build New Audience"} />
              </Button>
            </Col>
          </Row>
        }
      />

      <PageContentLayout>
        <Card
          style={{ paddingTop: "0.5em", borderRadius: "10px" }}
          bodyStyle={{ minHeight: "90vh" }}
        >
          <FilterBar
            style={{ marginBottom: "1em" }}
            refetchAudienceDemographs={handleFetchAudienceDemographs}
            loadingAudienceDemographs={loadingAudienceDemographs}
            handleSaveAudiencesToApi={handleSaveAudiencesToApi}
            loadingPublishing={loadingPublishing}
          ></FilterBar>
          <Spin spinning={loadingAudienceDemographs}>
            <DemographicsAudienceTable
              handleFetchAudienceDemographs={handleFetchAudienceDemographs}
              audienceDemographs={audienceDemographsData}
              handleUpdateAudience={handleUpdateAudience}
            />
          </Spin>
        </Card>
      </PageContentLayout>
    </>
  );
};

export default withRouter(DemographicsMainView);
