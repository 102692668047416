import React from "react";
import moment from "moment";
import { Avatar, Space } from "antd";
import AvatarS3 from "../../../shared/images/AvatarS3";

const getRelativeTime = createdAt => {
  const date = moment(createdAt);

  // Calculate the difference between the current time and the given date
  const diff = moment.duration(moment().diff(date));

  // Format the difference as a relative time string
  return diff.humanize();
};

const historyConfig = {
  style: {
    PENDING_CREATE: {
      color: "orange",
    },
    PENDING_DELETE: {
      color: "orange",
    },
    PENDING_UPDATE: {
      color: "orange",
    },
    DELETED: {
      color: "#52C41A",
    },
    CREATED: {
      color: "#52C41A",
    },
    UPDATED: {
      color: "#52C41A",
    },
  },
  name: {
    DELETED: "APPROVED",
    CREATED: "APPROVED",
    UPDATED: "UPDATED",
    PENDING_DELETE: "PENDING",
    PENDING_CREATE: "PENDING",
    PENDING_UPDATE: "PENDING",
  },
  action: {
    DELETED: "DELETED",
    CREATED: "CREATED",
    UPDATED: "UPDATED",
    PENDING_DELETE: "DELETED",
    PENDING_CREATE: "CREATED",
    PENDING_UPDATE: "UPDATED",
  },
  property: {
    BASICS: "",
    ATTRIBUTION: "",
    CREATIVE: "Creative",
    TARGETING_ADDRESS: "Targeting Address",
    TARGETING_AREA: "Targeting Area",
    WALK_IN_LOCATIONS: "Walk In Locations",
  },
};

const updateTemplate = ({ history }) => {
  let oldValue = history.data.oldValue;
  let newValue = history.data.newValue;

  if (isNaN(oldValue) && isDate(oldValue)) {
    oldValue = moment(oldValue).format("ll");
  }

  if (isNaN(newValue) && isDate(newValue)) {
    newValue = moment(newValue).format("ll");
  }

  return (
    <span style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
      <b>{history.data.name || ""}</b>{" "}
      {camelCaseToReadable(history.data.property)}{" "}
      {JSON.stringify(oldValue) && <span>from</span>}{" "}
      <b>{JSON.stringify(oldValue)}</b> to <b>{JSON.stringify(newValue)}</b>
    </span>
  );
};

const isDate = value => {
  return moment(value, moment.ISO_8601).isValid();
};

const camelCaseToReadable = value => {
  return (
    value
      // Insert a space before each uppercase letter
      .replace(/([A-Z])/g, " $1")
      // Convert the first character to uppercase and the rest to lowercase
      .toLowerCase()
  );
};

const CampaignHistories = ({ histories }) => {
  return (
    <div>
      <Space direction="vertical" size="middle">
        {histories
          .sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1; // a should come before b in the sorted order
            } else if (a.createdAt < b.createdAt) {
              return 1; // b should come before a in the sorted order
            } else {
              return 0; // both dates are equal
            }
          })
          .map(history => (
            <Space align="start" key={history.id}>
              {/* === WHO MADE THE CHANGE === */}
              <div>
                {history.author.avatar ? (
                  <AvatarS3
                    imgKey={history.author.avatar.key}
                    avatarProps={{ size: "small" }}
                  />
                ) : (
                  <Avatar size="small">
                    {history.author.username.substring(0, 1)}
                  </Avatar>
                )}
              </div>
              <div>
                <b style={{}}>{history.author.name}</b>{" "}
                {/* === WHAT ACTION CREATE | DELETE | UPDATE === */}{" "}
                {historyConfig.action[history.action]}{" "}
                {/* === WHAT HAS BEEN UPDATED: CREATIVE, TARGETING ADDRESS, ETC === */}{" "}
                {historyConfig.property[history.property]}{" "}
                {history.data.newValue ? (
                  updateTemplate({ history })
                ) : (
                  <b>{history.data.name}</b>
                )}
                {/* <b style={historyConfig.style[history.action]}>
                  {" "}
                  ({historyConfig.name[history.action]})
                </b> */}
                .{/* === WHEN IT WAS UPDATE USING RELATIVE TIME === */}
                <small style={{ marginLeft: 8 }}>
                  {getRelativeTime(history.createdAt)} ago
                </small>
              </div>
            </Space>
          ))}
      </Space>
    </div>
  );
};

export default CampaignHistories;
