import gql from "graphql-tag";

const GET_TRANSACTION_PAGINATION = gql`
  query getTransactionPagination(
    $campaignOrderId: String!
    $reportName: String!
    $index: Int!
    $limit: Int!
    $startDateTimestamp: Int
    $endDateTimestamp: Int
    $attributionWindow: Int
    $attrMethod: String
  ) {
    getTransactionPagination(
      campaignOrderId: $campaignOrderId
      reportName: $reportName
      index: $index
      limit: $limit
      startDateTimestamp: $startDateTimestamp
      endDateTimestamp: $endDateTimestamp
      attributionWindow: $attributionWindow
      attrMethod: $attrMethod
    )
  }
`;

export default GET_TRANSACTION_PAGINATION;
