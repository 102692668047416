import React from "react";
import {
  Col,
  Row,
  Table,
  Empty,
  Button,
  Card,
  Avatar,
  Badge,
  Spin,
  Tag,
} from "antd";

// local imports
import UsersDetails from "./UsersDetails";
import HeaderBar from "./HeaderBar";

import { permissionsFromRoles } from "../../../../core/utils/dataManipulating/organization";

import AvatarS3 from "../../../shared/images/AvatarS3";
import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";

const style = {
  customerName: {
    fontWeight: 700,
    fontSize: "16px",
    wordBreak: "break-word",
    hyphens: "auto",
    marginRight: 4,
  },
  container: {
    border: "0.5px solid #e8e8e8",
    padding: "0.5px",
    marginBottom: "10px",
  },
};

const UsersTab = props => {
  const { currentuser, orgs, selectedOrg, updateUserEmbeddableConfig } = props;
  const checkIsAdmin = currentuser && currentuser.isAdmin;

  const columns = [
    {
      title: "Users",
      dataIndex: "name",
      render: (text, record, i) => (
        <Row type="flex" align="middle" gutter={20}>
          <Col>
            {record.avatar && record.avatar.key ? (
              <AvatarS3
                imgKey={record.avatar.key}
                avatarProps={{ shape: "square", size: "large" }}
              />
            ) : (
              <Avatar size="larger" shape="square">
                {record.username.substring(0, 1)}
              </Avatar>
            )}
          </Col>
          <Col>
            <span style={style.customerName}>{`${record.username}`}</span>
            <Badge
              dot
              style={
                Object.keys(permissionsFromRoles(record.roles)).includes(
                  "ENABLED"
                )
                  ? { backgroundColor: "#52c41a" }
                  : null
              }
            />
            {record &&
            props.selectedUser &&
            record.id === props.selectedUser.id ? (
              <Tag color="#87d068" style={{ marginLeft: 8 }}>
                Selected
              </Tag>
            ) : null}
          </Col>
        </Row>
      ),
      sorter: (a, b) => a.username < b.username,
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
    },
  ];

  return (
    <Row>
      <Col span={8} xs={24} xl={8} style={style.container}>
        <HeaderBar title="Users" />
        {checkIsAdmin && (
          <Card size="small">
            <Button
              loading={props.isLoading}
              onClick={props.onCreateNewUser}
              type="dashed"
              size="large"
              block
              style={{ width: "100%", height: "100%", padding: "10px 10px" }}
            >
              <Row type="flex" justify="center" gutter={10} align="middle">
                <Col>
                  <PlusOutlined />
                </Col>
                <Col>Create User</Col>
                <Col>
                  <UserAddOutlined />
                </Col>
              </Row>
            </Button>
          </Card>
        )}
        <Table
          showHeader={false}
          scroll={{ y: 560 }}
          columns={columns}
          bordered={false}
          dataSource={
            props && props.users
              ? props.users.sort((a, b) => {
                  const usernameA = a.username.toLowerCase();
                  const usernameB = b.username.toLowerCase();

                  if (usernameA < usernameB) {
                    return -1;
                  }
                  if (usernameA > usernameB) {
                    return 1;
                  }
                  return 0;
                })
              : []
          }
          pagination={false}
          rowClassName={(record, index) => {
            if (record && props.selectedUser) {
              if (record.id === props.selectedUser.id)
                return "tableSelectedRow";
            }
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                props.onRowClick(record, rowIndex, event);
              },
            };
          }}
          locale={{
            emptyText: <div />,
          }}
        />
      </Col>

      <Col span={16} xs={24} xl={16}>
        <Spin tip="Loading..." spinning={props.isLoading}>
          <div style={style.container}>
            {props.selectedUser ? (
              <UsersDetails
                orgs={orgs.filter(orgs => orgs.id !== selectedOrg.id)}
                updateUserEmbeddableConfig={updateUserEmbeddableConfig}
                {...props}
              />
            ) : (
              <Card>
                <Empty
                  description="Please select a user to view the info"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </Card>
            )}
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default UsersTab;
