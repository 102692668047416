import React, { createContext } from "react";
import { useLazyQuery } from "react-apollo";
import GET_USERS_BASIC_DETAILS from "../../GraphQl/Queries/GET_USERS_BASIC_DETAILS";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [
    fetchBasicUsersDetails,
    { data: basicUsersDetails, loading: basicUsersDetailsLoading, error },
  ] = useLazyQuery(GET_USERS_BASIC_DETAILS);

  return (
    <UserContext.Provider
      value={{
        fetchBasicUsersDetails,
        basicUsersDetails: (basicUsersDetails && basicUsersDetails.users) || [],
        basicUsersDetailsLoading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
