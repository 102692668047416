import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Tabs, Table, Modal, Form, Radio } from "antd";
import { PageHeaderLayout, PageContentLayout } from "../../../shared/layout";
import { CheckCircleOutlined } from "@ant-design/icons";
import { withRouter, useParams } from "react-router-dom";
import Text from "antd/lib/typography/Text";
import { TaskComments } from "./TaskComments";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { statusTypes } from "../configs/task.config";
import OverlaySpinner from "../../../shared/OverlaySpinner";

const { TabPane } = Tabs;

const TaskDetailsView = ({ history }) => {
  const [form] = Form.useForm();
  const { id: currentTaskId } = useParams();
  const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const [visibleTaskUpdate, setVisibleTaskUpdate] = useState(false);

  const {
    currentUser,
    updateTask,
    updateTaskLoading,
    currentTask,
    fetchTask,
    taskLoading,
  } = useTaskContext();

  useEffect(() => {
    if (currentTaskId) {
      fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!currentTaskId) return <p>Select a task to see the details.</p>;

  if (taskLoading) {
    return <OverlaySpinner />;
  }

  const level =
    currentUser &&
    currentUser.role &&
    currentUser.role.org &&
    currentUser.role.org.level;

  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`; // Return formatted date
  };

  const relatedCampaignData =
    currentTask &&
    currentTask.relatedCampaigns.map(campaign => ({
      key: campaign.id, // Assign a unique key for each row
      campaignName: campaign.name,
      flightDates: `${formatDate(campaign.startDate)} - ${formatDate(
        campaign.endDate
      )}`, // Format the flight dates
    }));

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await updateTask({
        variables: {
          data: {
            status: values.status,
          },
          where: { id: currentTaskId },
        },
      });
      await fetchTask({
        variables: {
          where: {
            id: currentTaskId,
          },
        },
      });
      setVisibleTaskUpdate(false);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      sorter: (a, b) => a.campaignName.localeCompare(b.campaignName),
      render: (text, record) => (
        <text
          onClick={() => history.push(`/campaigns/details/${record.key}`)}
          style={{ cursor: "pointer", color: "#1890ff" }} // Styling for clickable link
        >
          {text}
        </text>
      ),
    },
    {
      title: "Flight Dates",
      dataIndex: "flightDates",
      key: "flightDates",
      sorter: (a, b) => {
        const [startA] = a.flightDates.split(" - ");
        const [startB] = b.flightDates.split(" - ");

        return new Date(startA) - new Date(startB); // Sort by the start date of the flight
      },
    },
  ];

  return (
    <>
      <PageHeaderLayout
        title={currentTask && currentTask.name}
        titleIcon={
          <CheckCircleOutlined
            style={{
              marginRight: "10px",
              fontSize: 25,
            }}
          />
        }
        titleDescription={
          currentTask && currentTask.status === null
            ? "N/A"
            : currentTask && currentTask.status.replace(/_/g, " ").toUpperCase()
        }
        titleDescriptionStyle={{ marginLeft: "10px" }}
        rightContent={
          <Row>
            {/* Conditionally render Edit and Update buttons only for 'standard' level */}
            {level !== "CHANNEL_PARTNER" && (
              <>
                <Col style={{ paddingInline: "5px" }}>
                  <Button
                    onClick={() => history.push(`/tasks/form/${currentTaskId}`)}
                  >
                    Edit Task
                  </Button>
                </Col>
                <Col style={{ paddingInline: "5px" }}>
                  <Button
                    onClick={() => {
                      setVisibleTaskUpdate(true);
                    }}
                  >
                    Update Status
                  </Button>
                </Col>
              </>
            )}

            {/* Back button available for both levels */}
            <Col style={{ paddingInline: "5px" }}>
              <Button onClick={() => history.goBack()}>Back</Button>
            </Col>
          </Row>
        }
        style={{ height: "80px", border: "none" }}
      />
      <Row
        style={{
          backgroundColor: "white",
          paddingLeft: "3%",
          height: "45px",
          border: "none",
        }}
      >
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="Details" key="1" />
          <TabPane tab="History" key="2" />
        </Tabs>
      </Row>

      {/* Content Layout where tab content is rendered */}
      <PageContentLayout>
        {activeTab === "1" && (
          <Row style={{ justifyContent: "center" }}>
            <Col span={8}>
              {/* TASK COMMMET */}
              <TaskComments
                currentTask={currentTask}
                currentTaskId={currentTaskId}
              />
            </Col>
            <Col span={13} style={{ paddingLeft: "40px" }}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>TASK DETAILS</Text>
                }
              >
                <Row style={{ paddingBottom: "2.5vh" }}>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Requested By
                    </span>
                    <Text>
                      {currentTask &&
                      currentTask.createdBy &&
                      currentTask.createdBy.name
                        ? currentTask.createdBy.name
                        : "Unknown"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Created On
                    </span>
                    <Text>
                      {currentTask && currentTask.createdAt
                        ? new Date(currentTask.createdAt).toLocaleDateString()
                        : "N/A"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Recurring
                    </span>
                    <Text>
                      {currentTask && currentTask.recurring ? "Yes" : "No"}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ paddingBottom: "2.5vh" }}>
                  <Col style={{ display: "block", paddingRight: "2.5vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Request Type
                    </span>
                    <Text>
                      {(currentTask && currentTask.requestType) || "-"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Request Details
                    </span>
                    <Text>
                      {currentTask && currentTask.requestSubType
                        ? currentTask.requestSubType
                        : "-"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Status
                    </span>
                    <Text>
                      {currentTask && currentTask.status === null
                        ? "-"
                        : currentTask &&
                          currentTask.status.replace(/_/g, " ").toUpperCase()}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ display: "block", paddingRight: "2.5vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Assignee Org
                    </span>
                    <Text>
                      {currentTask && currentTask.assigneeOrg
                        ? currentTask.assigneeOrg &&
                          currentTask.assigneeOrg.name
                        : "-"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Assignee
                    </span>
                    <Text>
                      {currentTask && currentTask.assigneeUser
                        ? currentTask.assigneeUser &&
                          currentTask.assigneeUser.name
                        : "-"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Channel Partner
                    </span>
                    <Text>
                      {currentTask && currentTask.channelPartner
                        ? currentTask.channelPartner &&
                          currentTask.channelPartner.name
                        : "-"}
                    </Text>
                  </Col>
                  <Col style={{ display: "block", paddingRight: "3vw" }}>
                    <span
                      style={{
                        display: "block",
                        color: "gray",
                        paddingBottom: "5px",
                      }}
                    >
                      Advertiser
                    </span>
                    <Text>
                      {currentTask && currentTask.advertiser
                        ? currentTask.advertiser && currentTask.advertiser.name
                        : "-"}
                    </Text>
                  </Col>
                </Row>
              </Card>
              {currentTask &&
              currentTask.relatedCampaigns.length > 0 && ( // Render only if relatedCampaigns has items
                  <Col style={{ paddingTop: "30px" }}>
                    <Card
                      style={{ borderRadius: "7px" }}
                      title={
                        <Text style={{ fontWeight: "bolder" }}>
                          RELATED CAMPAIGNS
                        </Text>
                      }
                    >
                      <Table
                        columns={columns}
                        dataSource={relatedCampaignData} // Use the mapped data here
                        pagination={{ pageSize: 5 }}
                      />
                    </Card>
                  </Col>
                )}
            </Col>
          </Row>
        )}
        {activeTab === "2" && (
          <Row>
            <Col span={12}>
              <Card
                title={
                  <Text style={{ fontWeight: "bolder" }}>TASK HISTORY</Text>
                }
              >
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  <Col span={14}>
                    <Text style={{ fontWeight: "bold" }}>HISTORY </Text>
                    <Text>is still not </Text>
                    <Text style={{ fontWeight: "bold" }}>available </Text>
                  </Col>
                  <span style={{ color: "gray" }}>011/07/2024 - 12:00 GMT</span>
                </Row>
              </Card>
              {/* <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>
                      Ramon Lorenzo Gerzon{" "}
                    </Text>
                    <Text>updated Assignee: </Text>
                    <Text style={{ color: "gray" }} delete>
                      Ramon Lorenzo Gerzon{" "}
                    </Text>
                    <Text style={{ fontWeight: "bold" }}> Ben Malone</Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/05/2024 - 16:26 GMT</span>
                </Row>
              </Card>
              <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>Ben Malone </Text>
                    <Text>updated Status: </Text>
                    <Text style={{ color: "gray" }} delete>
                      Not Started{" "}
                    </Text>
                    <Text style={{ fontWeight: "bold" }}> In Progress</Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/07/2024 - 09:22 GMT</span>
                </Row>
              </Card>
              <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>Ben Malone </Text>
                    <Text>added a comment </Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/07/2024 - 09:23 GMT</span>
                </Row>
              </Card>
              <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>Alex Lapusca </Text>
                    <Text>added a comment </Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/07/2024 - 16:35 GMT</span>
                </Row>
              </Card>
              <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>Ben Malone </Text>
                    <Text>updated Due Date: </Text>
                    <Text style={{ color: "gray" }} delete>
                      05/20/2024{" "}
                    </Text>
                    <Text style={{ fontWeight: "bold" }}> 05/25/2024</Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/07/2024 - 09:22 GMT</span>
                </Row>
              </Card>
              <Card>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Col span={12}>
                    <Text style={{ fontWeight: "bold" }}>Ben Malone </Text>
                    <Text>added a comment with attachment </Text>
                  </Col>
                  <span style={{ color: "gray" }}>05/10/2024 - 10:37 GMT</span>
                </Row>
              </Card> */}
            </Col>
          </Row>
        )}
      </PageContentLayout>

      {visibleTaskUpdate && (
        <Modal
          closable
          onCancel={() => setVisibleTaskUpdate(false)}
          width={"250px"}
          visible={visibleTaskUpdate}
          title="TASK STATUS"
          footer={[
            <Button
              key="update"
              type="primary"
              onClick={handleUpdate}
              disabled={updateTaskLoading}
              loading={taskLoading}
            >
              {updateTaskLoading ? "Updating..." : "Update"}
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Row justify="center">
              <Form.Item
                name="status"
                label={
                  <Text style={{ paddingBottom: "10px" }}>
                    Set the task status below
                  </Text>
                }
              >
                <Radio.Group
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "200px",
                  }}
                >
                  {Object.keys(statusTypes).map(status => (
                    <Radio
                      key={statusTypes[status]}
                      value={statusTypes[status]}
                      style={{ height: "25px", padding: "15px" }}
                    >
                      {status}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default withRouter(TaskDetailsView);
