import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
// Feature imports
import Account from "../ux/Accounts/routes";
import Audiences from "../ux/Audiences/routes";
import Campaigns from "../ux/Campaigns/routes";
import Home from "../ux/Home/routes";
import Locations from "../../core/components/locations";
import MasterServicesAgreement from "../ux/MasterServicesAgreement/MasterServicesAgreementView";
import Orgs from "../ux/Orgs/routes";
import OrgConfig from "../ux/OrgConfig/routes";
import PaidSearch from "../ux/PaidSearch/routes";
import Segments from "../ux/Segments/routes";
import Payment from "../ux/Payment/routes";
import Articles from "../ux/Articles/routes";
import Editorial from "../ux/Editorial/routes";
import Signal from "../ux/SignalTags/routes";
import Demograph from "../ux/Demographics/routes";
import AI from "../ux/AI/routes";
import Advertiser from "../ux/Advertiser/routes";
import Invoice from "../ux/Invoice/routes";
import Tags from "../ux/Tags/routes";
import FirstPartyData from "../ux/FirstPartyData/routes";
import Targeting from "../ux/Targeting/routes";
import Creatives from "../ux/Creatives/routes";
import RetailStores from "../ux/RetailStores/routes";
import Settings from "../ux/Settings/routes";
import KnowledgeBase from "../ux/KnowledgeBase/routes";
import Tasks from "../ux/Tasks/routes";

import SeoRefactored from "../ux/SeoRefactored/routes";
//import { AudienceMaintenance } from "../ux/Maintenance/Maintenance";
import GET_ORG_THEME from "../../core/GraphQl/Queries/GET_ORG_THEME";
import UPDATE_USER_MSA_STATUS from "../../core/GraphQl/Mutations/UPDATE_USER_MSA_STATUS";

import { useQuery } from "@apollo/react-hooks";
import {
  updatePrimaryColor,
  updateSidebarColor,
} from "../shared/globalStyling/ColorChanger";
// Layout import
import MainLayout from "../shared/layout/MainLayout";
import compose from "lodash/flowRight";
import { graphql } from "react-apollo";
import GET_USER_MSA_STATUS from "../../core/GraphQl/Queries/GET_USER_MSA_STATUS";
import GET_PARENT_ORG from "../../core/GraphQl/Queries/GET_PARENT_ORG";
import { publicRoutes } from "./PublicRoutes";
import LIST_USERS from "../../core/GraphQl/Queries/LIST_USERS_ADMIN";
import GET_CHATWOOT_ID from "../../core/GraphQl/Queries/GET_CHATWOOT_ID";
import { determineUserAccess } from "../../core/permissions/userLevelAccess";

const PrivateRoutes = props => {
  document.title = `High Performance Marketing Platform`;
  const [msaStatus, setMsaStatus] = useState("VERSION_2");
  const [isChannelPartnerOrgLevel, setIsChannelPartnerOrgLevel] = useState(
    false
  );
  const [horizontalNavigation, setHorizontalNavigation] = useState(false);
  const [themeData, setthemeData] = useState(null);

  const mainRoutes = [
    ...publicRoutes,
    {
      path: "/account",
      main: Account,
    },
    {
      path: "/payment",
      main: Payment,
    },
    {
      path: "/invoice",
      main: Invoice,
    },
    {
      path: "/orgconfig",
      main: OrgConfig,
    },
    {
      path: "/home",
      main: Home,
    },
    {
      path: "*",
      main: Home,
    },
  ];

  const featureRoutes = [
    {
      path: "/locations",
      main: Locations,
    },
    {
      path: "/orgs",
      main: Orgs,
    },
    {
      path: "/campaigns",
      main: Campaigns,
    },
    {
      path: "/audiences",
      main: Audiences,
    },
    {
      path: "/segments",
      main: Segments,
    },
    {
      path: "/paid_search",
      main: PaidSearch,
    },
    {
      path: "/seo",
      main: SeoRefactored,
    },
    {
      path: "/articles",
      main: Articles,
    },
    {
      path: "/editorial",
      main: Editorial,
    },
    {
      path: "/signal",
      main: Signal,
    },
    {
      path: "/advertiser",
      main: Advertiser,
    },
    {
      path: "/demograph",
      main: Demograph,
    },
    {
      path: "/ai",
      main: AI,
    },
    {
      path: "/tags",
      main: Tags,
    },
    {
      path: "/audience",
      main: FirstPartyData,
    },
    {
      path: "/targeting",
      main: Targeting,
    },
    {
      path: "/creatives",
      main: Creatives,
    },
    {
      path: "/retails_stores",
      main: RetailStores,
    },
    {
      path: "/settings",
      main: Settings,
    },
    {
      path: "/knowledge_base",
      main: KnowledgeBase,
    },
    {
      path: "/tasks",
      main: Tasks,
    },
  ];

  const { permission } = props.currentUser;
  const userPermission = determineUserAccess(permission);

  const { isSelfService } = userPermission;

  const allowedRoutes = featureRoutes
    .filter(route => {
      if (route.path.substring(1).toUpperCase() === "ADVERTISER") {
        return route;
      }

      // Temporary
      if (route.path.substring(1).toUpperCase() === "DEMOGRAPH") {
        return route;
      }

      if (isSelfService) {
        if (route.path.substring(1).toUpperCase() === "TAGS") {
          return route;
        }
        if (route.path.substring(1).toUpperCase() === "AUDIENCE") {
          return route;
        }
        if (route.path.substring(1).toUpperCase() === "TARGETING") {
          return route;
        }
        if (route.path.substring(1).toUpperCase() === "CREATIVES") {
          return route;
        }
        if (route.path.substring(1).toUpperCase() === "RETAILS_STORES") {
          return route;
        }
      }
      if (
        props.currentUser.features.includes(
          route.path.substring(1).toUpperCase()
        )
      ) {
        return route;
      }

      if (
        props.currentUser.features.includes("EDITORIAL") &&
        route.path === "/signal"
      ) {
        return route;
      }

      if (route.path === "/settings") {
        return route;
      }

      if (
        props.currentUser.features.includes("TASK_MANAGEMENT") &&
        route.path === "/tasks"
      ) {
        return route;
      }

      if (
        props.currentUser.features.includes("ORGS") &&
        route.path === "/knowledge_base"
      ) {
        return route;
      }
      if (route.path.substring(1).toUpperCase() === "ARTICLES") {
        return route;
      }
      return null;
    })
    .concat(mainRoutes);

  useQuery(GET_ORG_THEME, {
    variables: { orgID: props && props.currentUser.role.org.id },
    onCompleted: themeData => {
      if (!themeData) return;
      if (!themeData.getOrgThemeConfig) return;

      setthemeData(themeData);

      const primaryColor =
        themeData.getOrgThemeConfig && themeData.getOrgThemeConfig.primaryColor;
      const sidebarColor =
        themeData.getOrgThemeConfig && themeData.getOrgThemeConfig.sidebarColor;
      updatePrimaryColor(primaryColor);
      updateSidebarColor(sidebarColor);
    },
  });

  // Get the user MSA status
  useQuery(GET_USER_MSA_STATUS, {
    variables: {
      id: props.currentUser.id,
    },
    onCompleted: data => {
      setMsaStatus(data.users[0].msaStatus);
    },
  });

  // Get the user parent organization level
  useQuery(GET_PARENT_ORG, {
    variables: {
      loggedInOrg: props.currentUser.role.org.id,
      orgName: props.currentUser.role.org.name,
    },
    onCompleted: data => {
      setIsChannelPartnerOrgLevel(
        data.org && data.org.level === "CHANNEL_PARTNER"
      );
    },
  });

  useQuery(LIST_USERS, {
    variables: { loggedInOrg: props.currentUser.role.org.id },

    onCompleted: data => {
      const user = data.users.filter(
        user => user.id === props.currentUser.id
      )[0];

      if (
        user &&
        user.config &&
        user.config.horizontalNavigation !== null &&
        user.config.horizontalNavigation !== undefined
      ) {
        setHorizontalNavigation(user.config.horizontalNavigation);
      }
    },
  });

  const { data: chatWootId, loading: chatWootLoading } = useQuery(
    GET_CHATWOOT_ID,
    {
      variables: { orgId: props.currentUser.role.org.id },
    }
  );

  const getChatWootId =
    chatWootId && chatWootId.org && chatWootId.org.chatWootId;

  if (msaStatus !== "VERSION_2" && isChannelPartnerOrgLevel) {
    return (
      <MasterServicesAgreement
        userId={props.currentUser.id}
        updateUserMsaStatus={props.updateUserMsaStatusMutation}
      />
    );
  }

  return (
    <MainLayout
      {...props}
      chatWootId={getChatWootId}
      chatWootLoading={chatWootLoading}
      themeData={themeData}
      horizontalNavigation={horizontalNavigation}
    >
      <Switch>
        {allowedRoutes.map((route, index) => {
          return <Route key={index} component={route.main} path={route.path} />;
        })}
      </Switch>
    </MainLayout>
  );
};

export default compose(
  graphql(UPDATE_USER_MSA_STATUS, { name: "updateUserMsaStatusMutation" })
)(PrivateRoutes);
