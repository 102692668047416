import React from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import "./styles.css";

const { Title, Text } = Typography;

const columns = [
  {
    title: "Location Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  {
    title: "Street Address",
    dataIndex: "formattedAddress",
    key: "formattedAddress",
    render: (_, { location }) => (location && location.name) || "",
  },
];

export const CampaignRequestorRetailStoreTable = ({
  form,
  setShowRetailStoresModal,
  editable = false,
  title = "RETAIL STORES",
}) => {
  const attributionTargets = form.getFieldValue("attributionTargets") || [];
  const advertiserType = form.getFieldValue("advertiserType");

  return (
    <div className="requester-table-wrapper" style={{ marginTop: 20 }}>
      <div className="requester-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>{title}</Title>
          </Col>
          <Col span={12}>
            {attributionTargets.length && editable ? (
              <div style={{ textAlign: "end" }}>
                <Space size="middle" align="center">
                  <Form.Item
                    name="measureWalkInTraffic"
                    valuePropName="checked"
                    style={{ marginBottom: 0 }}
                  >
                    <Checkbox>Measure Walk-In Traffic</Checkbox>
                  </Form.Item>
                  <Button onClick={() => setShowRetailStoresModal(true)}>
                    Edit Retail Stores
                  </Button>
                </Space>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
      {!attributionTargets.length ? (
        <div style={{ padding: 20 }}>
          <Space
            direction="vertical"
            size="small"
            align="center"
            style={{ width: "100%" }}
          >
            {editable ? (
              <>
                <Text>
                  Select retail store from your library in order to attribute
                  transacitons to store locations
                </Text>
                <Button
                  type="primary"
                  onClick={() => {
                    setShowRetailStoresModal(true);
                  }}
                >
                  Select Retail Stores
                </Button>
              </>
            ) : (
              <Text>No retail stores selected</Text>
            )}
          </Space>
        </div>
      ) : (
        <Table
          rowKey="name"
          columns={columns}
          dataSource={attributionTargets || []}
          pagination={false}
          className="requester-table"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                {`${attributionTargets.length} Retail Stores`}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      )}
      <Form.Item name="attributionTargets" style={{ display: "none" }}>
        <Input />
      </Form.Item>
      <Form.Item name="geoTargets" style={{ display: "none" }}>
        <Input />
      </Form.Item>

      {/* Create hidden input for attributionTargets */}
      <Form.Item
        name="attributionTargets"
        hidden
        rules={[
          {
            required: advertiserType === "RETAIL_LOCAL_BRICK_AND_MORTAR",
            message: "Please select at least one retail store",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
