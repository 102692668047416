import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Tooltip, Typography, Space } from "antd";
import "../globalStyling/responsive.css";

// Global Styles
import {
  PageTitleText,
  TooltipText,
} from "../../shared/globalStyling/styledText";

const styles = {
  container: {
    minHeight: "64px",
    background: "white",
    border: "0.8px solid #e8e8e8",
    padding: "0 3%",
  },
  title: {
    height: "100%",
    fontSize: "15px",
  },
};

const { Text } = Typography;

const PageHeaderLayout = ({
  title,
  titleIcon,
  titleExtra,
  centerContent,
  rightContent,
  topBorderOff,
  style,
  tag,
  subtitle,
  titleRightIcon,
  titleDescription,
  titleDescriptionStyle,
}) => {
  // prevents description to be "null"
  titleDescription =
    titleDescription === null ||
    titleDescription === undefined ||
    titleDescription === "null"
      ? ""
      : titleDescription;
  return (
    <Row
      className="ant-row-flex-column ant-row-align-items-center"
      type="flex"
      justify="space-between"
      align="middle"
      style={{
        ...styles.container,
        ...styles.title,
        ...style,
        borderTop: topBorderOff ? 0 : "0.8px solid #e8e8e8",
      }}
    >
      {title &&
        (typeof title === "string" ? (
          <>
            {title.length > 45 ? (
              <Tooltip title={<TooltipText text={title} />}>
                <Space className="ant-space-xs-vertical ant-space-md-horizontal">
                  {titleIcon && titleIcon}
                  <Space direction="vertical" size={0}>
                    <PageTitleText text={title} />
                    <Text type="secondary" style={titleDescriptionStyle}>
                      {titleDescription}
                    </Text>
                  </Space>
                  {tag}
                  {titleRightIcon ? titleRightIcon : null}
                  <Text type="secondary">{subtitle}</Text>
                  {titleExtra ? titleExtra : null}
                </Space>
              </Tooltip>
            ) : (
              <>
                <Space className="ant-space-xs-vertical ant-space-md-horizontal">
                  {titleIcon && titleIcon}
                  <Space direction="vertical" size={0}>
                    <PageTitleText text={title} />
                    <Text type="secondary" style={titleDescriptionStyle}>
                      {titleDescription}
                    </Text>
                  </Space>
                  {tag}
                  {titleRightIcon ? titleRightIcon : null}
                  <Text type="secondary">{subtitle}</Text>
                  {titleExtra ? titleExtra : null}
                </Space>
              </>
            )}
          </>
        ) : (
          <Col>
            <div>{title}</div>
          </Col>
        ))}
      {centerContent && (
        <Col>
          <div>{centerContent}</div>
        </Col>
      )}
      {rightContent && (
        <Col>
          <div>{rightContent}</div>
        </Col>
      )}
    </Row>
  );
};
export default PageHeaderLayout;

PageHeaderLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleIcon: PropTypes.node,
  style: PropTypes.object,
  centerContent: PropTypes.node,
  rightContent: PropTypes.node,
  titleDescription: PropTypes.string,
  titleDescriptionStyle: PropTypes.object,
};
