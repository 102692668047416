import React, { useEffect, useState } from "react";
import { Space, Typography, Row, Col, Table, Button, message } from "antd";
import moment from "moment";
import S3Image from "../../CampaignLauncher/components/S3Image";
import CampaignOrderQueueReviewMediaDialog from "./CampaignOrderQueueReviewMediaDialog";
import { useCampaignOrderQueueContext } from "../../../../core/components/campaigns/campaignordersqueue/useCampaignOrderQueueContext";
import CampaignOrderQueueReviewMediaNotesDialog from "./CampaignOrderQueueReviewMediaNotesDialog";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { DownloadOutlined } from "@ant-design/icons";
import { multiImageZipV2 } from "../../../../core/utils/dataloading/multiImageZip";

const { Title, Text } = Typography;

export const CampaignOrderQueueReviewAdGroup = ({
  adGroup,
  adGroupCreative,
  adGroupCreativeIndex,
}) => {
  const [adUnitSelectedRowKeys, setAdUnitSelectedRowKeys] = useState([]);
  const [mediaDialogVisible, setMediaDialogVisible] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [mediaNotesDialogVisible, setMediaNotesDialogVisible] = useState(false);
  const [activeMedias, setActiveMedias] = useState([]);
  const [isReviewMode, setIsReviewMode] = useState(false);
  const {
    updateMedia,
    updateManyMedias,
    loadingUpdateMedia,
  } = useCampaignOrderQueueContext();
  const {
    currentCampaignOrder,
    loadingCampaignOrder,
  } = useCampaignRequestorContext();

  const onAdUnitSelectionChange = selectedRowKeys => {
    setAdUnitSelectedRowKeys(selectedRowKeys);
  };

  const handleResetStatus = async id => {
    try {
      message.loading("Updating media status...");
      await updateMedia({
        variables: {
          data: {
            status: "PENDING",
          },
          where: {
            id,
          },
        },
      });

      message.destroy();
      message.success("Media status updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media status");
    }
  };

  const handleResetNotes = async id => {
    try {
      message.loading("Updating media notes...");
      await updateMedia({
        variables: {
          data: {
            mediaNotes: "",
            status: "PENDING",
          },
          where: {
            id,
          },
        },
      });

      message.destroy();
      message.success("Media notes updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media notes");
    }
  };

  const handleApproveSelections = async () => {
    try {
      message.loading("Updating media status...");
      await updateManyMedias({
        variables: {
          data: {
            status: "APPROVED",
          },
          where: {
            id_in: adUnitSelectedRowKeys,
          },
        },
      });

      setAdUnitSelectedRowKeys([]);

      message.destroy();
      message.success("Media status updated successfully");
    } catch (error) {
      message.destroy();
      message.error("Error updating media");
    }
  };

  const handleDownloadAllCreatives = adGroupCreative => {
    multiImageZipV2(adGroupCreative.name, adGroupCreative.medias);
  };

  useEffect(() => {
    // Check if current campaign order status is "PENDING", "REJECTED" or "LIVE_PENDING" to enable review mode
    if (
      currentCampaignOrder.status === "PENDING" ||
      currentCampaignOrder.status === "REJECTED" ||
      currentCampaignOrder.status === "LIVE_PENDING"
    ) {
      setIsReviewMode(true);
    }
  }, [currentCampaignOrder]);

  return (
    <div>
      <div className="requester-table-wrapper" key={adGroupCreativeIndex}>
        <div className="requester-table-header">
          <Row>
            <Col span={12}>
              <Title level={5}>
                DISPLAY AD GROUP {adGroupCreativeIndex + 1} -{" "}
                {adGroupCreative.name}
              </Title>
            </Col>
          </Row>
        </div>
        <div style={{ padding: 20 }}>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Row gutter={20}>
              <Col span={10}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Click-Through Link</Text>
                  <Text>{adGroup.clickthrough}</Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Ad Units</Text>
                  <Text>{adGroupCreative.medias.length}</Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">Start Date</Text>
                  <Text>
                    {moment(adGroupCreative.startDate).format("MM/DD/YYYY")}
                  </Text>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <Text type="secondary">End Date</Text>
                  <Text>
                    {moment(adGroupCreative.endDate).format("MM/DD/YYYY")}
                  </Text>
                </Space>
              </Col>
            </Row>

            <div className="requester-table-wrapper" style={{ marginTop: 20 }}>
              <div className="requester-table-header">
                <Row>
                  <Col span={12}>
                    <Title level={5}>AD UNITS</Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        justifyContent: "flex-end",
                      }}
                    >
                      {isReviewMode && (
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="default"
                            onClick={handleApproveSelections}
                            disabled={!adUnitSelectedRowKeys.length}
                          >
                            Approve Selection(s)
                          </Button>
                          <Button
                            type="default"
                            onClick={() => setMediaNotesDialogVisible(true)}
                            disabled={!adUnitSelectedRowKeys.length}
                          >
                            Send Notes
                          </Button>
                        </div>
                      )}

                      <Button
                        type="default"
                        disabled={!adGroupCreative.medias.length}
                        onClick={() =>
                          handleDownloadAllCreatives(adGroupCreative)
                        }
                      >
                        <DownloadOutlined />
                        Download All Creatives
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
              {adGroup &&
              adGroup.adGroupCreatives &&
              !adGroup.adGroupCreatives.length ? (
                <div style={{ padding: 20 }}>
                  <Space
                    direction="vertical"
                    size="small"
                    align="center"
                    style={{ width: "100%" }}
                  ></Space>
                </div>
              ) : (
                <>
                  <Table
                    rowKey="id"
                    rowSelection={
                      isReviewMode
                        ? {
                            selectedRowKeys: adUnitSelectedRowKeys,
                            onChange: onAdUnitSelectionChange,
                          }
                        : null
                    }
                    columns={[
                      {
                        title: "",
                        dataIndex: "key",
                        key: "key",
                        render: (key, record, index) => {
                          return (
                            <div
                              onClick={() => {
                                setMediaDialogVisible(true);
                                setSelectedMediaIndex(index);
                                setActiveMedias(adGroupCreative.medias);
                              }}
                            >
                              <S3Image imgKey={key} preview={false} />
                            </div>
                          );
                        },
                      },
                      {
                        title: "Ad Unit Name",
                        dataIndex: "name",
                        key: "name",
                      },
                      {
                        title: "Aspect",
                        dataIndex: "aspect",
                        key: "aspect",
                        render: (_, record) => {
                          return `${record.width} x ${record.height}`;
                        },
                      },
                      {
                        title: "Type",
                        dataIndex: "fileType",
                        key: "fileType",
                      },
                      {
                        title: "Notes",
                        dataIndex: "mediaNotes",
                        key: "mediaNotes",
                        render: (notes, record) => {
                          if (notes) {
                            return (
                              <Space direction="vertical" size="small">
                                <Text>{notes}</Text>
                                <Button
                                  type="text"
                                  disabled={
                                    loadingUpdateMedia || loadingCampaignOrder
                                  }
                                  onClick={() => handleResetNotes(record.id)}
                                >
                                  <Text
                                    strong
                                    style={{
                                      color: "#1890FF",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Reset
                                  </Text>
                                </Button>
                              </Space>
                            );
                          }
                        },
                      },
                      {
                        title: "Status",
                        dataIndex: "status",
                        key: "status",
                        render: (status, record) => {
                          switch (status) {
                            case "APPROVED":
                              return (
                                <Space direction="vertical" size="small">
                                  <Text type="success" strong>
                                    Approved
                                  </Text>

                                  {isReviewMode && (
                                    <Button
                                      type="text"
                                      disabled={
                                        loadingUpdateMedia ||
                                        loadingCampaignOrder
                                      }
                                      onClick={() =>
                                        handleResetStatus(record.id)
                                      }
                                    >
                                      <Text
                                        strong
                                        style={{
                                          color: "#1890FF",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Reset
                                      </Text>
                                    </Button>
                                  )}
                                </Space>
                              );

                            default:
                              return "";
                          }
                        },
                      },
                    ]}
                    dataSource={adGroupCreative.medias}
                    pagination={false}
                    className="requester-table"
                  />
                </>
              )}
            </div>
          </Space>
        </div>
      </div>

      {/* MEDIA DIALOG */}
      {mediaDialogVisible && (
        <CampaignOrderQueueReviewMediaDialog
          activeMedias={activeMedias}
          visible={mediaDialogVisible}
          setVisible={setMediaDialogVisible}
          isReviewMode={isReviewMode}
          selectedMediaIndex={selectedMediaIndex}
        />
      )}

      {/* MEDIA NOTES DIALOG */}
      {mediaNotesDialogVisible && (
        <CampaignOrderQueueReviewMediaNotesDialog
          mediaIds={adUnitSelectedRowKeys}
          visible={mediaNotesDialogVisible}
          setVisible={setMediaNotesDialogVisible}
          medias={adGroupCreative.cmedias}
        />
      )}
    </div>
  );
};
