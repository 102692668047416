import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import JSZip from "jszip";
import { prepareTransactionsWithEventsExportSimplified } from "../../../../../platform/ux/Campaigns/campaigndetails/utils";
import { defaultAttributionWindow } from "../../../../utils/constants/constants";
import moment from "moment";

const CampaignChartsController = ({
  children,
  dataConfig,
  url,
  expiresAt,
  refetch,
  byTransactionsRangeDate,
  setByTransactionsRangeDate,
  dataTransactionsRangeDate,
  setDataTransactionsRangeDate,
  getBasketItems,
  basketItemsLoading,
  basketItemsError,
  basketItemsData,
  transactionTarget,
  campaignOrder,
  byCampaignRangeDate,
  setByCampaignRangeDate,
  dataCampaignRangeDate,
  setDataCampaignRangeDate,
  setDataAttributionWindow,
  dataAttributionWindow,
  attrMethod,
  chartData,
  setChartData,
  selectedCampaign,
  dateTrue,
  setDateTrue,
  priceVisible,
  reportName,
  setUseDynamicExport,
  setExportButtonLoading,
  setExportButtonDisable,
  setExportButtonMenuItems,
  getCSVReport,
  useDynamicExport,
  csvReportLoading,
  csvReportDataSignedURL,
  getTransactionsTable,
  transactionsTableLoading,
  transactionsTable,
  pageSize,
  currentUser,
  ...props
}) => {
  let defaultDisplayAttributionWindow =
    (dataConfig && dataConfig.defaultDisplayAttributionWindow) ||
    defaultAttributionWindow.key;

  const [rawData, setRawData] = useState(null);
  const [loadingRawData, setLoadingRawData] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(null);
  const [s3Url, setS3Url] = useState(null);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentStartEndDate, setCurrentStartEndDate] = useState(null);
  const [attributionWindow, setAttributionWindow] = useState(
    defaultDisplayAttributionWindow
  );
  const [attributionMethod, setAttributionMethod] = useState("ALL");

  //Time check happens first
  useEffect(() => {
    const currentTime = new Date().toUTCString();
    if (currentTime <= expiresAt) {
      //url is valid - set url
      setS3Url(url);
      setIsValidUrl(true);
    } else {
      //url is valid - refetch
      setIsValidUrl(false);
      refetch();
      setS3Url(null);
    }
  }, [expiresAt, s3Url, setS3Url, refetch, url]);

  //If check happens - we have a url (from reload or first load)
  useEffect(() => {
    if (s3Url && loadingRawData && campaignOrder.version === 1.3) {
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            await response.json().then(json => {
              if (!json.hasSimplifiedReport && currentUser.isDemo) {
                json.transactionsWithEvents = json.transactionsWithEvents.map(
                  t => {
                    t.impressions = t.impressions.map(i => {
                      i.CREATIVEURL = "";
                      return i;
                    });
                    return t;
                  }
                );
              }
              setRawData(json);
              setLoadingRawData(false);
            });
          } catch (err) {
            setLoadingRawData(false);
          }
        }
      });
    } else if (
      campaignOrder.version < 1.3 &&
      chartData &&
      chartData.transactionsOverallData
    ) {
      setRawData(chartData);
      setLoadingRawData(false);
    }
  }, [s3Url, loadingRawData, campaignOrder, chartData, currentUser.isDemo]);

  useEffect(() => {
    if (
      rawData &&
      rawData.transactionsWithEvents &&
      !chartData.transactionsWithEvents
    ) {
      setChartData({
        ...chartData,
        transactionsWithEvents: rawData.transactionsWithEvents,
      });
    }
  }, [rawData, chartData, setChartData]);

  if (rawData && !rawData.hasSimplifiedReport && useDynamicExport) {
    setUseDynamicExport(false);
  }

  useEffect(() => {
    if (useDynamicExport === true) {
      setExportButtonDisable(false);
      setExportButtonMenuItems([
        {
          key: "transactionEvents",
          text: "Transaction - With Events",
          onClick: () => {
            setExportButtonLoading(true);
            const key = "TransactionsWithEvents";
            const zip = new JSZip();
            const folder = zip.folder(
              `${selectedCampaign.name.replace(/\//g, " ")}-${key}}`
            );
            const formattedData = prepareTransactionsWithEventsExportSimplified(
              dataAttributionWindow
            );
            folder.file(
              `${selectedCampaign.name} - Transaction - With Events.csv`,
              Papa.unparse(formattedData)
            );
            zip.generateAsync({ type: "blob" }).then(
              blob => {
                saveAs(
                  blob,
                  `${selectedCampaign.name.replace(
                    /\//g,
                    " "
                  )}-Transaction - With Events`
                );
                return true;
              },
              function(e) {
                console.log("error", e);
                return false;
              }
            );
            setExportButtonLoading(false);
          },
        },
        {
          key: "transactionEventsSource",
          text: "Transaction - With Events from Source",
          confirmTitle: "Please allow Popup",
          confirmDescription:
            "Popup should be allowed on your browser to download this report",
          hasConfirm: true,
          onClick: () => {
            setExportButtonLoading(true);
            getCSVReport();
          },
        },
      ]);
    }
  }, [
    dataAttributionWindow,
    getCSVReport,
    selectedCampaign.name,
    setExportButtonDisable,
    setExportButtonLoading,
    setExportButtonMenuItems,
    useDynamicExport,
  ]);

  useEffect(() => {
    if (
      csvReportDataSignedURL &&
      csvReportDataSignedURL.getCampaignReportSignedURL
    ) {
      //Download CSV via URL
      const { url } = csvReportDataSignedURL.getCampaignReportSignedURL;
      window.open(url, "_blank").focus();
      setExportButtonLoading(false);
    }
  }, [csvReportDataSignedURL, selectedCampaign.name, setExportButtonLoading]);

  useEffect(() => {
    if (rawData && rawData.hasSimplifiedReport)
      getTransactionsTable({
        variables: {
          campaignOrderId: campaignOrder.id,
          reportName,
          index: currentIdx + 1,
          limit: pageSize,
          startDateTimestamp: currentStartEndDate
            ? moment(currentStartEndDate[0], "YYYY/MM/DD").unix()
            : null,
          endDateTimestamp: currentStartEndDate
            ? moment(currentStartEndDate[1], "YYYY/MM/DD").unix()
            : null,
          attributionWindow,
          attrMethod: attributionMethod,
        },
      });
  }, [
    campaignOrder,
    currentIdx,
    getTransactionsTable,
    attributionMethod,
    pageSize,
    reportName,
    currentStartEndDate,
    attributionWindow,
    rawData,
  ]);

  if (isValidUrl === true || (campaignOrder.version < 1.3 && chartData)) {
    return React.cloneElement(children, {
      chartData: rawData,
      chartsLoading: loadingRawData,
      isValidUrl,
      byTransactionsRangeDate,
      setByTransactionsRangeDate,
      dataTransactionsRangeDate,
      setDataTransactionsRangeDate,
      getBasketItems,
      basketItemsLoading,
      basketItemsError,
      basketItemsData,
      transactionTarget,
      campaignOrder,
      byCampaignRangeDate,
      setByCampaignRangeDate,
      dataCampaignRangeDate,
      setDataCampaignRangeDate,
      selectedCampaign,
      dateTrue,
      setDateTrue,
      priceVisible,
      setDataAttributionWindow,
      dataAttributionWindow,
      reportName,
      transactionsTable,
      transactionsTableLoading,
      attributionWindow,
      setCurrentIdx,
      setCurrentStartEndDate,
      setAttributionWindow,
      isDemo: currentUser.isDemo,
      currentUser,
      attributionMethod,
      setAttributionMethod,
      ...props,
    });
  } else {
    return React.cloneElement(children, {
      chartData: null,
      isValidUrl,
      campaignOrder,
    });
  }
};

export default CampaignChartsController;
