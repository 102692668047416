import React from "react";
import { Col, Row, Typography } from "antd";
import DetailBarCard from "../../../../shared/DetailBarCard";

const DetailBar = ({
  name = "",
  dataToDisplay = { visible: false, values: [] },
}) => {
  if (!dataToDisplay.visible) return null;

  const cardCount = dataToDisplay.values.length;

  // Calculate the span based on the number of cards
  const getSpan = () => {
    const baseSpan = Math.floor(24 / cardCount); // base span for each card
    const extraSpace = 24 % cardCount; // remaining space after division

    // Distribute the extra space evenly among the cards
    const size = baseSpan + (extraSpace > 0 ? 1 : 0);
    return size <= 2 ? 3 : size;
  };

  return (
    <div className="mb-6">
      <Typography.Title level={4} className="mb-4">
        {name}
      </Typography.Title>
      <Row gutter={[16, 16]}>
        {dataToDisplay.values.map((item, index) => (
          <Col key={`${name}-${index}`} xs={24} sm={12} md={8} lg={getSpan()}>
            <DetailBarCard {...item} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DetailBar;
