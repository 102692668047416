import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CampaignsMainViewDataLoader from "../../../../core/components/campaigns/campaignsmain";
import CampaignsMainView from "../CampaignsMainView";
import CampaignDetailsDataLoader from "../../../.././core/components/campaigns/campaigndetails";
import CampaignDetailsChartsDataLoader from "../../../../core/components/campaigns/campaigndetails/CampaignDetailsChartsDataLoader";

import CampaignsDetailsView from "../campaigndetails/CampaignsDetailsView";
import AmplifyView from "../campaigndetails/amplify/AmplifyView";
import CampaignLauncherLoader from "../../../../core/components/campaignLauncher";
import CampaignLauncherView from "../../CampaignLauncher/CampaignLauncherView";
import CampaignOrderAuditView from "../../CampaignOrderAudit/CampaignOrderAuditView";
import CampaignOrderAuditLoader from "../../../../core/components/campaigns/campaignorderaudit/CampaignOrderAuditLoader";
import GeotargetManagerLoader from "../../../../core/components/campaigns/geotargetmanager/GeotargetManagerLoader";
import CampaignRequestor from "../../CampaignRequestor/CampaignRequestor";
import { CampaignRequestorProvider } from "../../../../core/components/campaignRequestor/CampaignRequestorProvider";
import { LocationProvider } from "../../../../core/components/locations/LocationProvider";
import { TagProvider } from "../../../../core/components/tags/TagProvider";
import { TargetingProvider } from "../../../../core/components/targeting/TargetingProvider";
import { AudienceProvider } from "../../../../core/components/audiences/AudienceProvider";
import { CreativeProvider } from "../../../../core/components/creatives/main/CreativeProvider";
import { CampaignOrderQueue } from "../../CampaignOrderQueue/components/CampaignOrderQueue";
import { CampaignOrderQueueProvider } from "../../../../core/components/campaigns/campaignordersqueue/CampaingOrderQueueProvider";
import { CampaignOrderQueueReview } from "../../CampaignOrderQueue/components/CampaignOrderQueueReview";
import { DealIdProvider } from "../../../../core/components/dealIds/DealIdProvider";
import CampaignOrderQueueView from "../../CampaignOrderQueue/CampaignOrderQueueView";
import CampaignOrdersQueueLoader from "../../../../core/components/campaigns/campaignordersqueue/CampaignOrdersQueueLoader";
import { OrgProvider } from "../../../../core/components/orgs/OrgProvider";

const CampaignsRoutes = props => {
  const { path } = props.match;

  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${path}/main/:pageNumber?`}
          render={() => {
            return (
              <OrgProvider>
                <CampaignsMainViewDataLoader>
                  <CampaignsMainView />
                </CampaignsMainViewDataLoader>
              </OrgProvider>
            );
          }}
        />
        <Route
          exact
          path={`${path}/details/:id?`}
          render={() => (
            <CampaignDetailsDataLoader>
              <CampaignDetailsChartsDataLoader>
                <CampaignsDetailsView />
              </CampaignDetailsChartsDataLoader>
            </CampaignDetailsDataLoader>
          )}
        />
        <Route
          exact
          path={`${path}/manager/:id?`}
          render={() => (
            <CampaignLauncherLoader>
              <GeotargetManagerLoader>
                <CampaignLauncherView />
              </GeotargetManagerLoader>
            </CampaignLauncherLoader>
          )}
        />
        <Route
          exact
          path={`${path}/requester/:id?`}
          render={() => (
            <CampaignRequestorProvider>
              <LocationProvider>
                <TagProvider>
                  <TargetingProvider>
                    <AudienceProvider>
                      <CreativeProvider>
                        <CampaignRequestor />
                      </CreativeProvider>
                    </AudienceProvider>
                  </TargetingProvider>
                </TagProvider>
              </LocationProvider>
            </CampaignRequestorProvider>
          )}
        />
        <Route
          exact
          path={`${path}/boost/:id?`}
          render={() => (
            <CampaignLauncherLoader>
              <AmplifyView />
            </CampaignLauncherLoader>
          )}
        />

        <Route
          exact
          path={`${path}/orderqueue/:id?`}
          render={() => (
            <CampaignOrdersQueueLoader>
              <CampaignOrderQueueView />
            </CampaignOrdersQueueLoader>
          )}
        />

        <Route
          exact
          path={`${path}/v2/orderqueue/:tab?`}
          render={() => (
            <CampaignOrderQueueProvider>
              <CampaignOrderQueue />
            </CampaignOrderQueueProvider>
          )}
        />

        <Route
          exact
          path={`${path}/v2/orderqueue/details/:id?`}
          render={() => (
            <CampaignRequestorProvider>
              <CampaignOrderQueueProvider>
                <DealIdProvider>
                  <CampaignOrderQueueReview />
                </DealIdProvider>
              </CampaignOrderQueueProvider>
            </CampaignRequestorProvider>
          )}
        />

        <Route
          exact
          path={`${path}/audit/:id?`}
          render={() => (
            <CampaignOrderAuditLoader>
              <CampaignOrderAuditView />
            </CampaignOrderAuditLoader>
          )}
        />
        {/* Fallback */}
        <Route
          path={`${path}`}
          render={() => <Redirect to={`${path}/main/1`} />}
        />
      </Switch>
    </div>
  );
};

export default CampaignsRoutes;
