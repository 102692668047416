import React from "react";
import { Button, Col, Row, Space, Table, Typography } from "antd";
import "./styles.css";

const { Title } = Typography;

const columns = [
  {
    title: "Location Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  {
    title: "Street Address",
    dataIndex: "formattedAddress",
    key: "formattedAddress",
    render: (_, { location }) => (location && location.name) || "",
  },
];

export const CampaignRequestorWalkInTable = ({
  form,
  setShowRetailStoresModal,
  editable = false,
}) => {
  const attributionTargets = form.getFieldValue("attributionTargets") || [];
  const geoTargets = form.getFieldValue("geoTargets") || [];
  const measureWalkInTraffic = form.getFieldValue("measureWalkInTraffic");
  return (
    <div className="requester-table-wrapper" style={{ marginTop: 20 }}>
      <div className="requester-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>WALK-IN MEASUREMENT</Title>
          </Col>
          <Col span={12}>
            {(attributionTargets.length && editable) ||
            (geoTargets.length && editable) ? (
              <div style={{ textAlign: "end" }}>
                <Space size="middle" align="center">
                  <Button onClick={() => setShowRetailStoresModal(true)}>
                    Edit Locations
                  </Button>
                </Space>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
      {attributionTargets.length && measureWalkInTraffic ? (
        <Table
          key={target => target.id}
          style={{ borderTop: "1px solid #f0f0f0" }}
          showHeader={false}
          columns={columns}
          dataSource={attributionTargets}
          pagination={false}
          className="requester-table"
        />
      ) : null}
      {geoTargets.length ? (
        <Table
          key={target => target.name}
          style={{ borderTop: "1px solid #f0f0f0" }}
          showHeader={false}
          columns={columns}
          dataSource={geoTargets}
          pagination={false}
          className="requester-table"
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2}>
                {attributionTargets.length + geoTargets.length} Locations
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      ) : (
        <>
          {editable && (
            <div style={{ padding: 20 }}>
              <Space
                direction="vertical"
                size="small"
                align="center"
                style={{ width: "100%" }}
              >
                <Typography.Text>
                  Select locations from your library to enable Walk-In
                  reporting.
                </Typography.Text>
                <Button
                  type="primary"
                  onClick={() => setShowRetailStoresModal(true)}
                >
                  Select Locations
                </Button>
              </Space>
            </div>
          )}
        </>
      )}
    </div>
  );
};
