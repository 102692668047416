import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import {
  Input,
  Card,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import {
  formatNumber,
  formatWholeNumber,
} from "../../../../core/utils/campaigns";
import moment from "moment";
import { CodeOutlined, CopyOutlined } from "@ant-design/icons";
import CreateImpressionsTag from "./components/CreateImpressionsTag";
import CreateMeasurementTags from "./components/CreateMeasurementTags";
import CreatePublisherDomainTag from "./components/CreatePublsiherDomainTag";
const { Text } = Typography;
const { Search } = Input;

const SignalTagsMainView = ({
  publishersTags,
  measurementsTags,
  impressionsTags,
  onImressionsTagCreate,
  history,
  refetch,
  advertisers,
}) => {
  const [filteredPublisherTags, setFilteredPublisherTags] = useState(
    // Handles the existing list of the data
    publishersTags
  );

  const [filteredImpressionTags, setFilteredImpressionTags] = useState(
    impressionsTags
  );

  const [filteredMeasurementTags, setFilteredMeasurementTags] = useState(
    measurementsTags
  );

  const [activeTab, setActiveTab] = useState("domain"); // Not going back to default tab when searching in other tabs

  const publisherColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
    },
    {
      title: "Page Views",
      dataIndex: "pageViews",
      key: "pageViews",
      render: text => {
        return formatWholeNumber(text);
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];

  const measurementColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Advertiser",
      dataIndex: "advertiser",
      key: "advertiser",
    },
    {
      title: "Funnels",
      dataIndex: "funnels",
      key: "funnels",
    },
    {
      title: "Measured Events",
      dataIndex: "events",
      key: "events",
      render: value => {
        const events = value.map((e, index) => {
          const { event, value, date } = e;
          return (
            <Tag key={index} color="success">
              <Space direction="vertical" size={0}>
                <Text type="success" strong>
                  {formatNumber(value)}
                </Text>
                <Text type="success" strong>
                  {event}
                </Text>
                <Text type="success" disabled>
                  {moment(date).format("YYYY/MM/DD HH:mm:ss")}
                </Text>
              </Space>
            </Tag>
          );
        });

        return <Row>{events}</Row>;
      },
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];

  const impressionColumn = [
    {
      title: "Tag Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Platform",
      dataIndex: "dsp",
      key: "dsp",
    },
    {
      title: "Tag ID",
      dataIndex: "tagId",
      key: "tagId",
      render: text => {
        return (
          <Space direction="horizontal">
            {text}
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(text);
                message.info("Tag ID copied to clipboard");
              }}
            />
          </Space>
        );
      },
    },
  ];
  console.log(filteredImpressionTags);
  return (
    <React.Fragment>
      <PageHeaderLayout
        title={"Signal Tags"}
        titleIcon={
          <CodeOutlined
            style={{
              marginRight: "10px",
              color: "lightgrey",
              fontSize: 20,
            }}
          />
        }
      />
      <PageContentLayout>
        <Tabs activeKey={activeTab} onChange={key => setActiveTab(key)}>
          <Tabs.TabPane tab="Publisher Domain Tags" key="domain">
            <Card
              title={"Publisher Domain Tags"}
              extra={
                <Space direction="horizontal">
                  <Search
                    placeholder="Search"
                    allowClear
                    onSearch={search => {
                      setFilteredPublisherTags(
                        publishersTags.filter(item =>
                          Object.values(item).some(value =>
                            value
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                        )
                      );
                    }}
                    style={{
                      width: 200,
                    }}
                  />
                  <CreatePublisherDomainTag />
                </Space>
              }
            >
              <Table
                columns={publisherColumn}
                dataSource={filteredPublisherTags}
              />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Impression Tags" key="impressions">
            <Card
              title={"Impression Tags"}
              extra={
                <Space direction="horizontal">
                  <Search
                    placeholder="Search"
                    allowClear
                    onSearch={search => {
                      setFilteredImpressionTags(
                        impressionsTags.filter(item =>
                          Object.values(item).some(value =>
                            value
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                        )
                      );
                    }}
                    style={{
                      width: 200,
                    }}
                  />
                  <CreateImpressionsTag
                    onSubmit={onImressionsTagCreate}
                    refetch={refetch}
                    advertisers={advertisers}
                  />
                </Space>
              }
            >
              <Table
                columns={impressionColumn}
                dataSource={filteredImpressionTags}
              />
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Measurement Tags" key="measurement">
            <Card
              title="Measurement Tags"
              extra={
                <Space direction="horizontal">
                  <Search
                    placeholder="Search"
                    allowClear
                    onSearch={search => {
                      setFilteredMeasurementTags(
                        measurementsTags.filter(item =>
                          Object.values(item).some(value =>
                            value
                              .toString()
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          )
                        )
                      );
                    }}
                    style={{
                      width: 200,
                    }}
                  />
                  <CreateMeasurementTags />
                </Space>
              }
            >
              <Table
                columns={measurementColumn}
                dataSource={filteredMeasurementTags}
                onRow={data => ({
                  onClick: () => {
                    const { id } = data;
                    history.push(`/tags/details/${id}`);
                  },
                })}
              />
            </Card>
          </Tabs.TabPane>
        </Tabs>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(SignalTagsMainView);
