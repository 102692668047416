import { useContext } from "react";
import TaskContext from "./TaskProvider";

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};
