import React, { useEffect, useState } from "react";
// React and hooks
import { useParams, useHistory } from "react-router-dom";
import { Button, Col, Row, Space, Tabs } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";

// Shared components
import OverlaySpinner from "../../../shared/OverlaySpinner";
import { useCampaignRequestorContext } from "../../../../core/components/campaignRequestor/useCampaignRequestorContext";

// Local components
import { CampaignOrderQueueReviewDetails } from "./CampaignOrderQueueReviewDetails";
import { CampaignOrderQueueReviewCampaign } from "./CampaignOrderQueueReviewCampaign";
import { CampaignOrderQueueReviewSummary } from "./CampaignOrderQueueReviewSummary";
import { CampaignOrderQueueHistory } from "./CampaignOrderQueueHistory";

// Styles
import "../../CampaignRequestor/styles.css";
import "./styles.css";

export const CampaignOrderQueueReview = () => {
  const { id } = useParams(); // Extract the ID from the URL
  const history = useHistory();
  const [campaignOrderReview, setCampaignOrderReview] = useState(null);

  const {
    fetchCampaignOrder,
    currentCampaignOrder,
    loadingChildrenOrgs,
  } = useCampaignRequestorContext();

  useEffect(() => {
    if (id) {
      fetchCampaignOrder({
        variables: {
          where: {
            id,
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentCampaignOrder) {
      setCampaignOrderReview(currentCampaignOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaignOrder]);

  if (loadingChildrenOrgs) {
    return <OverlaySpinner />;
  }

  const handleEditCampaign = () => {
    history.push(`/campaigns/requester/${id}`);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      {campaignOrderReview && (
        <>
          <Row
            style={{ backgroundColor: "white", padding: "24px 24px 8px 24px" }}
          >
            <Col span={16}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Space direction="horizontal" size="large">
                  <FileSearchOutlined style={{ fontSize: "24px" }} />
                  <h3>{campaignOrderReview.name}</h3>
                </Space>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Space direction="horizontal" size="small">
                <Button onClick={handleEditCampaign}>Edit Campaign</Button>
                <Button onClick={handleBack}>Back</Button>
              </Space>
            </Col>
          </Row>
          <Tabs
            style={{ padding: "8px 24px" }}
            defaultActiveKey="1"
            className="campaign-order-queue-tabs"
          >
            <Tabs.TabPane tab="Details" key="1">
              <div style={{ paddingTop: 38 }}>
                <Row gutter={20}>
                  <Col span={18}>
                    <Space
                      direction="vertical"
                      size="large"
                      style={{ width: "100%" }}
                    >
                      <CampaignOrderQueueReviewDetails
                        currentCampaignOrder={campaignOrderReview}
                      />

                      {campaignOrderReview.campaigns.map((campaign, index) => (
                        <CampaignOrderQueueReviewCampaign
                          key={index}
                          index={index}
                          campaign={campaign}
                          currentCampaignOrder={campaignOrderReview}
                        />
                      ))}
                    </Space>
                  </Col>
                  <Col span={6}>
                    <CampaignOrderQueueReviewSummary
                      currentCampaignOrder={campaignOrderReview}
                    />
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="History" key="2">
              <div style={{ paddingTop: 38 }}>
                <CampaignOrderQueueHistory
                  currentCampaignOrder={campaignOrderReview}
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
    </>
  );
};
