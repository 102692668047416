import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Table,
  Input,
  Button,
  Select,
  Space,
  Row,
  message,
  Modal,
  Tag,
} from "antd";
import TaskUpdateModal from "./TaskUpdateModal";
import { useTaskContext } from "../../../../core/components/tasks/useTaskContext";
import { taskTypes, clientTypes } from "../configs/task.config";
import { withRouter } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import useSearchParams from "../../../../core/hooks/useSearchParams";

const { Option } = Select;

const UnassignedTasksView = ({ history }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    fetchTasksPaginated,
    task,
    unassignedTaskPagination,
    setUnassignedTaskPagination,
    tasksLoading,
    updateTask,
    updateTaskLoading,
    currentUser,
  } = useTaskContext();
  const unassignedData = task.filter(item => item.assigneeUser === null);
  const [searchParams, setSearchParams] = useSearchParams({
    requestType: "",
    clientType: "",
    name: "",
  });

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedUpdate, setSelectedUpdate] = useState([]);
  const [showRelatedCampaignModal, setShowRelatedCampaignModal] = useState(
    false
  );

  useEffect(() => {
    handleFetchTasksPaginated({
      skip:
        (unassignedTaskPagination.current - 1) *
          unassignedTaskPagination.pageSize || 0,
      first: unassignedTaskPagination.pageSize,
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unassignedTaskPagination.pageSize, searchParams]);

  const handleFetchTasksPaginated = ({
    skip,
    first,
    name = "",
    requestType = "",
    clientType = "",
  }) => {
    fetchTasksPaginated({
      variables: {
        where: {
          AND: [
            {
              name_contains: name,
              assigneeUser: null,
            },
            {
              ...(requestType ? { requestType } : {}),
            },
            ...(clientType
              ? [
                  {
                    createdBy: { roles_some: { org: { level: clientType } } },
                  },
                ]
              : []),
          ],
          OR: [
            {
              createdBy: {
                roles_some: {
                  org: {
                    OR: [
                      {
                        parentOrg_some: {
                          id: currentUser.role.org.id,
                        },
                      },
                      {
                        id: currentUser.role.org.id,
                      },
                    ],
                  },
                },
              },
            },
            { channelPartner: { id: currentUser.role.org.id } },
          ],
        },
        orderBy: "updatedAt_DESC",
        skip,
        first,
      },
    });
  };

  const handleTaskTypeFilter = value => {
    const updatedFilters = {
      ...searchParams,
      requestType: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleClientTypeFilter = value => {
    const updatedFilters = {
      ...searchParams,
      clientType: value === "all" ? "" : value,
    };
    setSearchParams(updatedFilters);
  };

  const handleTableChange = ({ current, pageSize }) => {
    setUnassignedTaskPagination(prev => ({ ...prev, current, pageSize }));

    const newQuery = { ...searchParams };
    const queryString = qs.stringify(newQuery, { addQueryPrefix: true });
    history.push(`/tasks/unassigned/${current}${queryString}`);

    handleFetchTasksPaginated({
      skip: (current - 1) * pageSize || 0,
      first: pageSize,
      ...newQuery,
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUpdate = async values => {
    try {
      message.loading("Updating...", 0);
      const { dueDate, status, assigneeOrg, assignee } = values;

      const updatedData = {
        dueDate: dueDate ? dueDate.toISOString() : null,
        status,
        assigneeOrg: assigneeOrg ? { connect: { id: assigneeOrg } } : null,
        assigneeUser: assignee ? { connect: { id: assignee } } : null,
      };

      await updateTask({
        variables: { data: updatedData, where: { id: selectedUpdate.id } },
      });
      setIsModalVisible(false);
      message.destroy();
      message.success("Updated successfully!");
      history.push(`/tasks/main/1`);
    } catch (error) {
      message.destroy();
      message.error("Failed to update.");
      console.error("Failed to update task:", error);
    }
  };

  const handleSearchChange = name => {
    const updatedFilters = { ...searchParams, name };
    setSearchParams(updatedFilters);
  };

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            history.push(`/tasks/details/${record.id}`);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate),
      render: dueDate =>
        dueDate ? new Date(dueDate).toLocaleDateString("en-CA") : "N/A",
    },
    {
      title: "Request Type",
      dataIndex: "requestType",
      key: "requestType",
      render: (requestType, record) => (
        <>
          {requestType || "N/A"}
          <br />
          <span style={{ color: "gray" }}>{record.requestSubType || ""}</span>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: status => {
        if (!status) return "";
        const formattedStatus = status
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Title case each word
          .join(" ");
        return formattedStatus;
      },
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      key: "clientType",
      render: (_, record) => {
        const orgLevel =
          record.createdBy &&
          record.createdBy.roles &&
          record.createdBy.roles[0] &&
          record.createdBy.roles[0].org &&
          record.createdBy.roles[0].org.level;
        const formattedOrgLevel = orgLevel
          .split("_")
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return formattedOrgLevel;
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (_, record) => {
        const orgName =
          record.createdBy &&
          record.createdBy.roles &&
          record.createdBy.roles[0] &&
          record.createdBy.roles[0].org &&
          record.createdBy.roles[0].org.name;
        return (
          <>
            <Tag
              color="geekblue"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "150px",
              }}
            >
              {orgName || "N/A"}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Campaigns",
      dataIndex: "campaigns",
      key: "campaigns",
      render: (_, record) =>
        record.relatedCampaigns && record.relatedCampaigns.length > 0 ? (
          <Button
            type="link"
            onClick={() => {
              setSelectedCampaigns(record.relatedCampaigns);
              setShowRelatedCampaignModal(true);
            }}
          >
            View
          </Button>
        ) : (
          <div style={{ marginLeft: 25 }}>-</div>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => {
              setSelectedUpdate(record);
              setIsModalVisible(true);
            }}
          >
            Update
          </Button>
        </Space>
      ),
    },
  ];

  const campaignColumns = [
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <text
          onClick={() => history.push(`/campaigns/details/${record.id}`)}
          style={{ cursor: "pointer", color: "#1890ff" }}
        >
          {text}
        </text>
      ),
    },
    {
      title: "Flight Dates",
      dataIndex: "flightDates",
      key: "flightDates",
      sorter: (a, b) => new Date(a.startDate) - new Date(b.startDate),
      render: (_, record) => {
        const startDate = moment(record.startDate).format("MM/DD/YYYY");
        const endDate = moment(record.endDate).format("MM/DD/YYYY");
        return `${startDate} - ${endDate}`;
      },
    },
  ];

  return (
    <>
      <Card
        title="TASKS"
        extra={
          <Row gutter={16} align="middle">
            <Col>
              <Input.Search
                placeholder="Search tasks"
                onSearch={value => handleSearchChange(value)}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  history.push(`/tasks/form/`);
                }}
              >
                Create Task
              </Button>
            </Col>
          </Row>
        }
      >
        <Row gutter={16} style={{ marginBottom: 16 }} justify="end">
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Request Type:</Col>
              <Col flex="auto">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onChange={handleTaskTypeFilter}
                >
                  <Option value="all">All</Option>
                  {taskTypes.map(type => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row align="middle" gutter={8}>
              <Col flex="none">Client Type</Col>
              <Col flex="auto">
                <Select
                  defaultValue="all"
                  style={{ width: "100%" }}
                  onChange={handleClientTypeFilter}
                >
                  <Option value="all">All</Option>
                  {Object.keys(clientTypes).map(type => (
                    <Option key={type} value={clientTypes[type]}>
                      {type}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={unassignedData}
          loading={tasksLoading}
          pagination={unassignedTaskPagination}
          onChange={handleTableChange}
        />
        <TaskUpdateModal
          visible={isModalVisible}
          onCancel={handleCancel}
          onUpdate={handleUpdate}
          selected={selectedUpdate}
          loading={updateTaskLoading}
        />
      </Card>
      {showRelatedCampaignModal && (
        <Modal
          closable
          visible={showRelatedCampaignModal}
          width={"40%"}
          onCancel={() => setShowRelatedCampaignModal(false)}
          footer={null}
          title="RELATED CAMPAIGNS"
        >
          <Table
            rowKey="id"
            columns={campaignColumns}
            dataSource={selectedCampaigns}
            pagination={{ pageSize: 5 }}
          />
        </Modal>
      )}
    </>
  );
};

export default withRouter(UnassignedTasksView);
