import { Card, Tooltip, Row } from "antd";
import React from "react";
import {
  KPITitleText,
  KpiPercentageText,
  KpiSubText,
} from "./globalStyling/styledText";
import { QuestionCircleOutlined } from "@ant-design/icons";

const styles = {
  title: {
    marginBottom: "10px",
  },
  card: {
    height: "100%",
    minHeight: "10em",
  },
  body: {
    overflow: "hidden",
  },
  valueContainer: {
    wordBreak: "break-word",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  titleText: {
    maxWidth: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const DetailBarCard = ({
  title,
  value,
  tooltip,
  prevValue,
  prevPercentage,
  prevRanges,
}) => {
  return (
    <Card style={styles.card} bodyStyle={styles.body}>
      <div style={styles.title}>
        <Row justify="space-between" align="top" wrap={false}>
          <div style={styles.titleText}>
            <KPITitleText text={title} />
          </div>
          {tooltip && (
            <Tooltip
              title={tooltip}
              arrowPointAtCenter={true}
              placement="rightBottom"
            >
              <QuestionCircleOutlined style={{ flexShrink: 0 }} />
            </Tooltip>
          )}
        </Row>
      </div>
      <div style={styles.valueContainer}>
        <KpiSubText text={value} />
      </div>
      <div style={styles.valueContainer}>
        <KpiPercentageText
          text={prevValue}
          prev={prevPercentage}
          ranges={prevRanges}
        />
      </div>
    </Card>
  );
};

export default DetailBarCard;
