import gql from "graphql-tag";
const GET_CAMPAING_ORDER_BASIC_DETAILS_CONNECTION = gql`
  query campaignOrdersConnection(
    $where: CampaignOrderWhereInput
    $orderBy: CampaignOrderOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    campaignOrdersConnection: campaignOrdersConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          startDate
          endDate
        }
      }
      aggregate {
        count
      }
    }
    full: campaignOrdersConnection(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export default GET_CAMPAING_ORDER_BASIC_DETAILS_CONNECTION;
