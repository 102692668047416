import React, { useState, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Modal,
  DatePicker,
  Checkbox,
  Radio,
  Tag,
  Table,
} from "antd";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import {
  CheckCircleOutlined,
  PaperClipOutlined,
  PictureOutlined,
  CodeOutlined,
} from "@ant-design/icons";

import { PageTitleText } from "../../../shared/globalStyling/styledText";
import { CopyBlock } from "react-code-blocks";

import { withRouter } from "react-router-dom";

const { Text } = Typography;
const { Option } = Select;

const getNumberWithSuffix = number => {
  if (number === 1 || number === 21 || number === 31) return `${number}st`;
  if (number === 2 || number === 22) return `${number}nd`;
  if (number === 3 || number === 23) return `${number}rd`;
  return `${number}th`;
};

const TaskEdit = ({ history }) => {
  const [filteredSubTypes, setFilteredSubTypes] = useState([]);
  const [isSubTypeDisabled, setIsSubTypeDisabled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringType, setRecurrenceType] = useState("");

  const [weeklyDays, setWeeklyDays] = useState([]);
  const [monthlyType, setMonthlyType] = useState("dayOfMonth");

  const [dayOfMonth, setDayOfMonth] = useState("1st");
  const [dayOfWeek, setDayOfWeek] = useState("Monday");
  const [dateOfMonth, setDateOfMonth] = useState(getNumberWithSuffix(15));

  const [yearlyMonth, setYearlyMonth] = useState("January");
  const [yearlyDate, setYearlyDate] = useState(getNumberWithSuffix(15));

  const [selectedDate, setSelectedDate] = useState(null);
  const [modalDate, setModalDate] = useState(null);

  const [taskDetails, setTaskDetails] = useState("");
  const fileInputRef = useRef(null);
  const [attachedFile, setAttachedFile] = useState([]);
  const imageInputRef = useRef(null);
  const [attachedImage, setAttachedImage] = useState([]);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [attachedCodeSnippet, setAttachedCodeSnippet] = useState([]);
  const [isCodeSnippet, setIsCodeSnippet] = useState(false);
  const [outAttachedCodeSnippet, setOutAttachedCodeSnippet] = useState([]);

  const [modalRelatedCampaign, setModalRelatedCampaign] = useState(false);

  const taskTypes = [
    "Display Campaign",
    "SEO",
    "SEM",
    "Attention Signals",
    "Demograph",
    "Onboarding",
    "Troubleshooting",
    "Sales Support",
    "General Question",
  ];

  const subTypes = {
    "Display Campaign": [
      "Update Campaign Name",
      "Update Creative(s)",
      "Update Clickthru URL(s)",
      "Update Audience Segment(s)",
      "Update Geotarget(s)",
      "Update Walk-in Location(s)",
      "Update E-Commerce Attribution",
      "Update Campaign Status",
      "Campaign Budget / Flight Dates",
      "Change Line Item Allocation / Change Flight Dates",
      "Campaign Launch",
      "Report Request",
      "Other",
    ],
    Onboarding: [
      "Domain Approval",
      "Org Creation",
      "Tag Creation / Installation",
      "Other",
    ],
    Troubleshooting: ["Tag Investigation", "Pacing", "Performance", "Other"],
    "Sales Support": ["Request Renewal", "Budget & Strategy", "Other"],
    // Add other subtypes as needed
  };

  const columns = [
    {
      title: "Campaign Name",
      dataIndex: "campaignName",
      key: "campaignName",
      sorter: () => "",
    },
    {
      title: "Flight Dates",
      dataIndex: "flightDates",
      key: "flightDates",
      sorter: () => "",
    },
  ];

  const dataSource = [
    { key: "1", campaignName: "name1", flightDates: "11/12/2024 - 12/12/2024" },
    { key: "2", campaignName: "name2", flightDates: "11/13/2024 - 12/13/2024" },
    { key: "3", campaignName: "name3", flightDates: "11/14/2024 - 12/14/2024" },
    { key: "4", campaignName: "name4", flightDates: "11/25/2024 - 12/25/2024" },
    { key: "5", campaignName: "name5", flightDates: "11/26/2024 - 12/26/2024" },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState(dataSource);
  const [tableData, setTableData] = useState(dataSource);
  const rowSelection = {
    selectedRowKeys,
    selectedRows,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(newSelectedRows);
    },
  };

  const assigneeOrg = ["MediaJel", "Org 02", "Org 03"];
  const assigneeUser = ["Ben Malone", "Name 02", "Name 03"];
  const channelPartner = ["SM Services", "CP 02", "CP 03"];
  const advertiser = ["Gelato", "Advertiser 02", "Advertiser 03"];

  const handleSubTypeChange = value => {
    const subTypesForSelected = subTypes[value] || [];
    setFilteredSubTypes(subTypesForSelected);
    setIsSubTypeDisabled(subTypesForSelected.length === 0);
  };

  const handleDateOk = () => {
    if (isRecurring) {
      console.log("Selected Recurrence Type:", recurringType);

      if (recurringType === "daily") {
        console.log("Selected Daily: true");
      }

      if (recurringType === "weekly") {
        console.log("Selected Weekly Days:", weeklyDays);
      }

      if (recurringType === "monthly") {
        if (monthlyType === "dayOfMonth") {
          console.log("Monthly Type: Day Of Month");
          console.log("Selected Day of Month:", dayOfMonth);
          console.log("Selected Day of Week:", dayOfWeek);
        } else if (monthlyType === "dateOfMonth") {
          console.log("Monthly Type: Date Of Month");
          console.log("Selected Date of Month:", dateOfMonth);
        }
      }

      if (recurringType === "yearly") {
        console.log("Selected Yearly Date:", yearlyDate);
        console.log("Selected Year Month:", yearlyMonth);
      }
    } else {
      console.log("Recurring option is not selected.");
    }
    setSelectedDate(modalDate);
    setModalVisible(false);
  };

  const handleRecurrenceTypeChange = value => {
    setRecurrenceType(value);
  };

  const handleWeeklyDayChange = days => {
    setWeeklyDays(
      days.target.checked
        ? [...weeklyDays, days.target.value]
        : weeklyDays.filter(day => day !== days.target.value)
    );
  };

  return (
    <React.Fragment>
      <PageHeaderLayout
        title="Edit Task"
        titleIcon={
          <CheckCircleOutlined
            style={{
              marginRight: "10px",
              fontSize: 20,
            }}
          />
        }
        rightContent={
          <Row>
            <Col style={{ paddingInline: "5px" }}>
              <Button onClick={() => history.push(`/tasks/details`)}>
                Back
              </Button>
            </Col>
            <Col style={{ paddingInline: "5px" }}>
              <Button disabled type="primary">
                Update
              </Button>
            </Col>
          </Row>
        }
      />
      <PageContentLayout>
        <Form layout="vertical">
          {/* TASK DETAILS */}
          <Row gutter={[30, 30]}>
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>TASK DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name={"taskName"}
                      label={"Task Name"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        The name you will identify this task by
                      </p>
                      <Input placeholder="Enter task name" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name={"dueDate"}
                      label={"Due Date"}
                      colon={false}
                      layout={"vertical"}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        Task completion
                      </p>

                      {/* CUSTOM STYLE DATEPICKER */}
                      <DatePicker
                        style={{ width: "100%" }}
                        value={selectedDate}
                        onOpenChange={open => {
                          if (open) {
                            setModalVisible(true); // Open the modal when DatePicker is clicked
                          }
                        }}
                        open={false}
                      />
                      <Modal
                        closable={false}
                        visible={modalVisible}
                        width={isRecurring ? "600px" : "330px"}
                        onOk={handleDateOk}
                        onCancel={() => setModalVisible(false)}
                        bodyStyle={{ display: "flex", height: "380px" }}
                        footer={
                          <Row
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={isRecurring}
                              onChange={checkBox => {
                                setIsRecurring(checkBox.target.checked);
                                if (checkBox.target.checked) {
                                  setRecurrenceType("daily");
                                }
                              }}
                            >
                              Recurring
                            </Checkbox>
                            <Button
                              type="link"
                              onClick={handleDateOk}
                              style={{ marginLeft: "8px" }}
                            >
                              Update
                            </Button>
                          </Row>
                        }
                      >
                        <Col span={13}>
                          <style>
                            {`
                            /* Target the DatePicker popup dropdown with high specificity */
                            .ant-picker-dropdown {
                              box-shadow: none !important;
                            }

                            /* In case there's additional shadow on inner elements, target those too */
                            .ant-picker-dropdown .ant-picker-panel-container {
                              box-shadow: none !important;
                            }

                            /* Fallback to target specific parts like 'content' and 'select dropdowns' */
                            .ant-picker-dropdown .ant-picker-content,
                            .ant-picker-dropdown .ant-select-dropdown {
                              box-shadow: none !important;
                            }
                          `}
                          </style>
                          <DatePicker
                            style={{
                              flex: isRecurring ? 1 : "100%",
                              marginRight: isRecurring ? "8px" : "0",
                            }}
                            open={modalVisible}
                            getPopupContainer={trigger => trigger.parentNode}
                            onChange={date => setModalDate(date)}
                            value={modalDate}
                          />
                        </Col>

                        {isRecurring && (
                          <React.Fragment>
                            {/* SEPARATOR LINE */}
                            <Col
                              style={{
                                height: "auto",
                                width: "1px",
                                backgroundColor: "#d9d9d9",
                                margin: "0 16px",
                              }}
                            />
                            <Col
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              span={9}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Repeats
                              </span>
                              <Row
                                style={{ marginTop: "20px", paddingBottom: "" }}
                              >
                                <Form.Item
                                  name="recurringType"
                                  noStyle
                                  initialValue="daily"
                                >
                                  <Select
                                    style={{ width: 150 }}
                                    onChange={handleRecurrenceTypeChange}
                                  >
                                    <Option value="daily">Daily</Option>
                                    <Option value="weekly">Weekly</Option>
                                    <Option value="monthly">Monthly</Option>
                                    <Option value="yearly">Yearly</Option>
                                  </Select>
                                </Form.Item>
                              </Row>
                              {recurringType === "weekly" && (
                                <Row>
                                  <Col span={24} style={{ paddingTop: "10px" }}>
                                    <span style={{ marginLeft: "28px" }}>
                                      Repeat weekly on
                                    </span>
                                    <Row
                                      gutter={[8, 8]}
                                      style={{
                                        marginTop: "8px",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      {[
                                        "Sunday",
                                        "Monday",
                                        "Tuesday",
                                        "Wednesday",
                                        "Thursday",
                                        "Friday",
                                        "Saturday",
                                      ].map(day => (
                                        <Col key={day} span={24}>
                                          <Checkbox
                                            key={day}
                                            value={day}
                                            checked={weeklyDays.includes(day)}
                                            onChange={handleWeeklyDayChange}
                                          >
                                            {day}
                                          </Checkbox>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                              {recurringType === "monthly" && (
                                <Row>
                                  <Col span={24} style={{ paddingTop: "10px" }}>
                                    <span>Repeat monthly on</span>
                                    {/* Radio for Day of Month */}
                                    <Row style={{ marginTop: "10px" }}>
                                      <Radio.Group
                                        value={monthlyType}
                                        onChange={e =>
                                          setMonthlyType(e.target.value)
                                        }
                                      >
                                        <Radio value="dayOfMonth">
                                          Day of Month
                                        </Radio>
                                      </Radio.Group>
                                    </Row>

                                    <Row
                                      gutter={16}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Col span={12}>
                                        <Select
                                          defaultValue={"1st"}
                                          value={dayOfMonth}
                                          onChange={value =>
                                            setDayOfMonth(value)
                                          }
                                          style={{ width: "100%" }}
                                          disabled={
                                            monthlyType !== "dayOfMonth"
                                          }
                                        >
                                          <Option value="1st">1st</Option>
                                          <Option value="2nd">2nd</Option>
                                          <Option value="3rd">3rd</Option>
                                          <Option value="4th">4th</Option>
                                          <Option value="5th">5th</Option>
                                        </Select>
                                      </Col>
                                      <Col span={12}>
                                        <Select
                                          defaultValue={"Monday"}
                                          value={dayOfWeek}
                                          onChange={value =>
                                            setDayOfWeek(value)
                                          }
                                          style={{ width: "200" }}
                                          disabled={
                                            monthlyType !== "dayOfMonth"
                                          }
                                        >
                                          <Option value="Sunday">Sun</Option>
                                          <Option value="Monday">Mon</Option>
                                          <Option value="Tuesday">Tue</Option>
                                          <Option value="Wednesday">Wed</Option>
                                          <Option value="Thursday">
                                            Thurs
                                          </Option>
                                          <Option value="Friday">Fri</Option>
                                          <Option value="Saturday">Sat</Option>
                                        </Select>
                                      </Col>
                                    </Row>

                                    <Row style={{ marginTop: "20px" }}>
                                      <Radio.Group
                                        value={monthlyType}
                                        onChange={e =>
                                          setMonthlyType(e.target.value)
                                        }
                                      >
                                        <Radio value="dateOfMonth">
                                          Date of Month
                                        </Radio>
                                      </Radio.Group>
                                    </Row>

                                    <Row style={{ marginTop: "10px" }}>
                                      <Select
                                        value={dateOfMonth}
                                        onChange={value =>
                                          setDateOfMonth(value)
                                        }
                                        style={{ width: "50%" }}
                                        disabled={monthlyType !== "dateOfMonth"}
                                      >
                                        {[...Array(31)].map((_, i) => (
                                          <Option
                                            key={i}
                                            value={getNumberWithSuffix(i + 1)}
                                          >
                                            {getNumberWithSuffix(i + 1)}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                              {recurringType === "yearly" && (
                                <Row>
                                  <Col span={24} style={{ paddingTop: "10px" }}>
                                    <span>Repeat yearly on</span>
                                    <Row
                                      gutter={16}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Col span={12}>
                                        <Select
                                          defaultValue={"Jan"}
                                          value={yearlyMonth}
                                          onChange={value =>
                                            setYearlyMonth(value)
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <Option value="January">Jan</Option>
                                          <Option value="February">Feb</Option>
                                          <Option value="March">Mar</Option>
                                          <Option value="April">Apr</Option>
                                          <Option value="May">May</Option>
                                          <Option value="June">Jun</Option>
                                          <Option value="July">Jul</Option>
                                          <Option value="August">Aug</Option>
                                          <Option value="September">Sep</Option>
                                          <Option value="October">Oct</Option>
                                          <Option value="November">Nov</Option>
                                          <Option value="December">Dec</Option>
                                        </Select>
                                      </Col>
                                      <Col span={12}>
                                        <Select
                                          value={yearlyDate}
                                          onChange={value =>
                                            setYearlyDate(value)
                                          }
                                          style={{ width: "100" }}
                                        >
                                          {[...Array(31)].map((_, i) => (
                                            <Option
                                              key={i}
                                              value={getNumberWithSuffix(i + 1)}
                                            >
                                              {getNumberWithSuffix(i + 1)}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                          </React.Fragment>
                        )}
                      </Modal>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      name={"status"}
                      label={"Status"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        Task Status
                      </p>
                      <Select placeholder="Not Started">
                        <Option value="notStarted">Not Started</Option>
                        <Option value="inProgress">In Progress</Option>
                        <Option value="completed">Completed</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name={"requestType"}
                      label={"Request Type"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        Select the type that best matches this task
                      </p>
                      <Select
                        placeholder="Select request type"
                        onChange={handleSubTypeChange}
                      >
                        {taskTypes.map((type, index) => (
                          <Option key={index} value={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={"details"}
                      label={"Request Details"}
                      colon={false}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        Optional: Select a sub-type that best matches this task
                      </p>
                      <Select
                        placeholder="Select request details"
                        disabled={isSubTypeDisabled}
                      >
                        {filteredSubTypes.map((subtype, index) => (
                          <Option key={index} value={subtype}>
                            {subtype}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* REQUEST DETAILS */}
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>REQUEST DETAILS</Text>
                }
              >
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name={"assigneeOrg"}
                      label={"Assignee Org"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        The org that will receive this task
                      </p>
                      <Select placeholder="Select Assignee Org">
                        {assigneeOrg.map((org, index) => (
                          <Option key={index} value={org}>
                            {org}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={"assigneeUser"}
                      label={"Assignee"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        The person that will receive this task
                      </p>
                      <Select placeholder="Select Assignee">
                        {assigneeUser.map((user, index) => (
                          <Option key={index} value={user}>
                            {user}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={10}>
                    <Form.Item
                      name={"channelPartner"}
                      label={"Channel Partner"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        The partner this task is regarding
                      </p>
                      <Select placeholder="Select Partner">
                        {channelPartner.map((partner, index) => (
                          <Option key={index} value={partner}>
                            {partner}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={"advertiser"}
                      label={"Advertiser"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        The advertiser this task is regarding
                      </p>
                      <Select placeholder="Select Advertiser">
                        {advertiser.map((advertiser, index) => (
                          <Option key={index} value={advertiser}>
                            {advertiser}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[18, 18]}>
                  <Col span={24}>
                    <Form.Item
                      name={"taskDetails"}
                      label={"Task Details"}
                      colon={false}
                      style={{ paddingRight: "10px" }}
                    >
                      <p style={{ color: "gray", fontSize: "12px" }}>
                        Details about this task
                      </p>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                          position: "relative",
                          padding: "5px",
                        }}
                      >
                        <Row style={{ flexGrow: 1 }}>
                          <Input.TextArea
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            value={taskDetails}
                            placeholder="Enter task details"
                            onChange={e => setTaskDetails(e.target.value)}
                            style={{
                              border: "none",
                              outline: "none",
                              resize: "none",
                              width: "100%",
                              padding: "5px",
                            }}
                          />
                        </Row>

                        {/* Hidden files input */}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          multiple
                          onChange={file => {
                            const newFiles = Array.from(file.target.files);
                            if (newFiles.length > 0) {
                              setAttachedFile(prevFiles => [
                                ...prevFiles,
                                ...newFiles,
                              ]);
                            }
                          }}
                        />
                        <input
                          type="file"
                          ref={imageInputRef}
                          style={{ display: "none" }}
                          multiple
                          accept="image/*"
                          onChange={image => {
                            const newImages = Array.from(
                              image.target.files
                            ).filter(image => image.type.startsWith("image/"));
                            if (newImages.length > 0) {
                              setAttachedImage(prevImages => [
                                ...prevImages,
                                ...newImages,
                              ]);
                            }
                          }}
                        />

                        {/* Render tags inside the text box */}
                        {attachedFile && attachedFile.length > 0 && (
                          <Row
                            style={{
                              display: "block",
                              marginTop: "10px",
                            }}
                          >
                            {attachedFile.map((file, index) => (
                              <Tag
                                closable
                                key={index}
                                onClose={() => {
                                  // Remove the file from the attachedFile array
                                  const updatedFiles = attachedFile.filter(
                                    (_, i) => i !== index
                                  );
                                  setAttachedFile(updatedFiles);
                                }}
                                icon={<PaperClipOutlined />}
                                style={{
                                  marginBottom: "4px",
                                  display: "block",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  width: "fit-content",
                                }}
                              >
                                {file.name}
                              </Tag>
                            ))}
                          </Row>
                        )}
                        {attachedImage && attachedImage.length > 0 && (
                          <Row
                            style={{
                              display: "block",
                              marginTop: "10px",
                            }}
                          >
                            {attachedImage.map((file, index) => (
                              <Tag
                                closable
                                key={index}
                                onClose={() => {
                                  // Remove the file from the attachedFile array
                                  const updatedImages = attachedImage.filter(
                                    (_, i) => i !== index
                                  );
                                  setAttachedImage(updatedImages);
                                }}
                                icon={<PictureOutlined />}
                                style={{
                                  marginBottom: "4px",
                                  display: "block",
                                  whiteSpace: "pre-wrap",
                                  wordWrap: "break-word",
                                  width: "fit-content",
                                }}
                              >
                                {file.name}
                              </Tag>
                            ))}
                          </Row>
                        )}

                        {isCodeSnippet && outAttachedCodeSnippet && (
                          <Col
                            style={{
                              maxWidth: "100%",
                              maxHeight: "300px", // Limit the height of the code block
                              overflowY: "auto", // Allow vertical scroll if content exceeds height
                              overflowX: "auto",
                            }}
                          >
                            <CopyBlock
                              text={outAttachedCodeSnippet}
                              language="javascript"
                              wrapLongLines
                              showLineNumbers={false}
                              theme="dracula"
                              codeBlock
                            />
                          </Col>
                        )}
                      </Row>

                      <Row style={{ paddingTop: "10px" }}>
                        <PaperClipOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            fileInputRef.current.click();
                          }}
                        />
                        <PictureOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            imageInputRef.current.click();
                          }}
                        />
                        <CodeOutlined
                          style={{
                            paddingInline: "5px",
                            color: "gray",
                            fontSize: "20px",
                          }}
                          onClick={() => setShowCodeModal(true)}
                        />
                      </Row>
                    </Form.Item>
                    <Modal
                      closable={false}
                      visible={showCodeModal}
                      bodyStyle={{ height: "300px", padding: "0" }}
                      footer={
                        <>
                          <Button
                            onClick={() => {
                              setShowCodeModal(false);
                            }}
                            style={{ marginLeft: "8px" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              setOutAttachedCodeSnippet(attachedCodeSnippet);
                              setIsCodeSnippet(true);
                              setShowCodeModal(false);
                            }}
                            style={{ marginLeft: "8px" }}
                          >
                            Embed
                          </Button>
                        </>
                      }
                    >
                      <Card
                        title={
                          <Text style={{ fontWeight: "bolder" }}>
                            SEND CODE SNIPPET
                          </Text>
                        }
                      >
                        <Row style={{ paddingBottom: "20px" }}>
                          <Text>
                            Embed a code snippet by pasting the code into the
                            field below:
                          </Text>
                        </Row>
                        <Row
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            position: "relative",
                          }}
                        >
                          <Row style={{ flexGrow: 1 }}>
                            <Input.TextArea
                              autoSize={{ minRows: 6, maxRows: 6 }}
                              value={attachedCodeSnippet}
                              onChange={e => {
                                setAttachedCodeSnippet(e.target.value);
                              }}
                              style={{
                                backgroundColor: "#f5f5f5",
                                border: "none",
                                outline: "none",
                                resize: "none",
                                width: "100%",
                                margin: "5px",
                              }}
                            />
                          </Row>
                        </Row>
                      </Card>
                    </Modal>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* RELATED CAMPAIGNS */}
            <Col span={16}>
              <Card
                style={{ borderRadius: "7px" }}
                title={
                  <Text style={{ fontWeight: "bolder" }}>
                    RELATED CAMPAIGNS
                  </Text>
                }
                extra={
                  <Button onClick={() => setModalRelatedCampaign(true)}>
                    Edit Related Campaigns
                  </Button>
                }
              >
                <Col span={24}>
                  <Form.Item
                    name={"relatedCampaigns"}
                    labelAlign="center"
                    colon={false}
                    style={{ paddingRight: "10px" }}
                    labelCol={{ span: 24, style: { textAlign: "center" } }}
                  >
                    <Modal
                      footer={null}
                      closable={false}
                      visible={modalRelatedCampaign}
                      width={"60%"}
                      onCancel={() => setModalRelatedCampaign(false)}
                      bodyStyle={{ height: "auto" }}
                    >
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <PageTitleText text={"ATTACH CAMPAIGNS"} />
                        <Button
                          type="primary"
                          onClick={() => {
                            const selectedData = dataSource.filter(item =>
                              selectedRowKeys.includes(item.key)
                            );
                            setTableData(selectedData);
                            setModalRelatedCampaign(false);
                          }}
                        >
                          Attach Campaigns
                        </Button>
                      </Row>
                      <Table
                        rowSelection={{
                          ...rowSelection,
                          type: "checkbox",
                          columnTitle: "",
                        }}
                        columns={columns}
                        dataSource={dataSource}
                      />
                    </Modal>
                    <Table columns={columns} dataSource={tableData} />
                  </Form.Item>
                </Col>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(TaskEdit);
