import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table, Tag } from "antd";

import { useCampaignRequestorContext } from "../../../core/components/campaignRequestor/useCampaignRequestorContext";
import { useTargetingContext } from "../../../core/components/targeting/useTargetingContext";
import moment from "moment";
import { cloneDeep } from "lodash";

const CampaignRequestorTargetingGroupsDialog = ({
  visible,
  setVisible,
  onAddSelections,
  selectedTargetingGroups,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { selectedAdvertiser } = useCampaignRequestorContext();
  const {
    fetchTargetingGroups,
    targetingGroups,
    loadingTargetingGroups,
    targetingGroupsPagination,
    setTargetingGroupsPagination,
  } = useTargetingContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }

    const newSelectedTargetingGroups = cloneDeep(targetingGroups)
      .filter(targeting => selectedRowKeys.includes(targeting.id))
      .map(targeting => {
        delete targeting.id;
        return targeting;
      });
    onAddSelections(newSelectedTargetingGroups);
    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleFetchTargetingGroups = (page = 1, pageSize = 10) => {
    fetchTargetingGroups({
      variables: {
        where: {
          AND: [
            {
              type: "TARGETING",
            },
            {
              orgs_some: { id: selectedAdvertiser.id },
            },
          ],
        },
        first: pageSize,
        skip: (page - 1) * pageSize,
      },
    });
  };

  useEffect(() => {
    handleFetchTargetingGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTargetingGroups && selectedTargetingGroups.length > 0) {
      const keys = [];
      selectedTargetingGroups.forEach(group => {
        // Find the targeting group in the list by name
        const targeting = targetingGroups.find(t => t.name === group.name);
        if (targeting) {
          keys.push(targeting.id);
        }
      });
      setSelectedRowKeys(keys);
    }
  }, [targetingGroups, selectedTargetingGroups]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>TARGETING GROUPS</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1200}
      >
        {loadingTargetingGroups ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={[
              {
                title: "Targeting Group Name",
                dataIndex: "name",
                key: "name",
                ellipsis: true, // Enables ellipsis for text overflow
                width: 200, // Set a fixed width for the column
              },
              {
                title: "Locations",
                dataIndex: "locations",
                key: "locations",
                width: "40%",
                render: (_, { geoTargets }) => (
                  <div>
                    {geoTargets &&
                      geoTargets.map(target => {
                        return target.location ? (
                          <Tag color="blue" key={target.id}>
                            {target.location.name}
                          </Tag>
                        ) : (
                          <></>
                        );
                      })}
                  </div>
                ),
              },
              {
                title: "Created On",
                dataIndex: "createdAt",
                key: "createdAt",
                render: text => moment(text).format("MM/DD/YYYY hh:mma"),
              },
            ]}
            dataSource={targetingGroups}
            scroll={{ x: 1200 }}
            pagination={targetingGroupsPagination}
            onChange={(paginationObject, filtersObject, sorterObject) => {
              setTargetingGroupsPagination({
                ...targetingGroupsPagination,
                current: paginationObject.current,
                pageSize: paginationObject.pageSize,
              });
              handleFetchTargetingGroups(
                paginationObject.current,
                paginationObject.pageSize
              );
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignRequestorTargetingGroupsDialog;
