import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

// MUTATIONS

// QUERIES
import GET_CAMPAIGN_ORDER_BASIC_DETAILS_CONNECTION from "../../GraphQl/Queries/GET_CAMPAING_ORDER_BASIC_DETAILS_CONNECTION";
import { TableConfig } from "../../../platform/shared/configs/TableConfig";

const CampaignOrderContext = createContext({});

export const CampaignOrderProvider = props => {
  const { children } = props;
  const [campaignOrders, setCampaignOrders] = useState([]);
  const [campaignOrdersPagination, setCampaignOrdersPagination] = useState(
    TableConfig.pagination
  );

  const [
    fetchBasicCampaignOrdersDetailsConnection,
    {
      data: basicCampaignOrdersDetailsConnectionData,
      loading: basicCampaignOrdersDetailsLoading,
    },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_BASIC_DETAILS_CONNECTION);

  useEffect(() => {
    if (
      basicCampaignOrdersDetailsConnectionData &&
      basicCampaignOrdersDetailsConnectionData.campaignOrdersConnection
    ) {
      setCampaignOrders(
        basicCampaignOrdersDetailsConnectionData.campaignOrdersConnection.edges.map(
          edge => edge.node
        )
      );
      setCampaignOrdersPagination({
        ...campaignOrdersPagination,
        total: basicCampaignOrdersDetailsConnectionData.full.aggregate.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicCampaignOrdersDetailsConnectionData]);

  return (
    <CampaignOrderContext.Provider
      value={{
        fetchBasicCampaignOrdersDetailsConnection,
        campaignOrders,
        basicCampaignOrdersDetailsLoading,
        campaignOrdersPagination,
        setCampaignOrdersPagination,
      }}
      {...props}
    >
      {children}
    </CampaignOrderContext.Provider>
  );
};

export default CampaignOrderContext;
