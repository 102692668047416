import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import CampaignRequestorAdGroupsDialog from "./CampaignRequestorAdGroupsDialog";
import moment from "moment";
import S3Image from "../CampaignLauncher/components/S3Image";

const { Text, Title } = Typography;

export const CampaignRequestorAdGroup = ({
  form,
  index,
  fieldKey,
  restField,
  name,
  editable = false,
  setPreviousValues,
}) => {
  const campaigns = form.getFieldValue("campaigns");
  const adGroup = campaigns[index].adGroup || {};
  const adGroupCreatives = (adGroup && adGroup.adGroupCreatives) || [];
  const allMedias = adGroupCreatives.reduce((acc, item) => {
    return acc.concat(item.medias);
  }, []);

  const [showAdGroupsModal, setShowAdGroupsModal] = useState(false);

  return (
    <>
      <Card title="AD GROUP">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Row gutter={20}>
            <Col span={24}>
              <Space
                direction="vertical"
                size="small"
                style={{ width: "100%" }}
              >
                <Text>Click-Through Link</Text>
                <Text type="secondary">
                  Where users arrive after clicking a banner in this Ad Group
                </Text>
                <Form.Item
                  {...restField}
                  name={[name, "adGroup", "clickthrough"]}
                  fieldKey={[fieldKey, "adGroup", "clickthrough"]}
                  rules={[
                    {
                      required: true,
                      message: `Please enter a URL for ${campaigns[index].name}`,
                    },
                  ]}
                >
                  <Input placeholder="Enter URL" disabled={!editable} />
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Space>

        <div className="requester-table-wrapper" style={{ marginTop: 20 }}>
          <div className="requester-table-header">
            <Row>
              <Col span={12}>
                <Title level={5}>DISPLAY AD GROUPS</Title>
              </Col>
              {editable && adGroupCreatives.length > 0 ? (
                <Col span={12}>
                  <div style={{ textAlign: "end" }}>
                    <Button onClick={() => setShowAdGroupsModal(true)}>
                      Edit Ad Groups
                    </Button>
                  </div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
          {!adGroupCreatives.length ? (
            <div style={{ padding: 20 }}>
              <Space
                direction="vertical"
                size="small"
                align="center"
                style={{ width: "100%" }}
              >
                {editable ? (
                  <>
                    <Text>
                      Select ad groups from your library to include their ad
                      units
                    </Text>
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowAdGroupsModal(true);
                      }}
                    >
                      Select Ad Groups
                    </Button>
                  </>
                ) : (
                  <Text>No Ad Groups Selected</Text>
                )}
              </Space>
            </div>
          ) : (
            <>
              <Form.List
                name={[name, "adGroup", "adGroupCreatives"]}
                fieldKey={[fieldKey, "adGroup", "adGroupCreatives"]}
              >
                {adGroupCreativesFields => {
                  return (
                    <Table
                      key="name"
                      columns={[
                        {
                          title: "Display Ad Gropup Name",
                          dataIndex: "name",
                          key: "name",
                          ellipsis: true, // Enables ellipsis for text overflow
                          width: 200, // Set a fixed width for the column
                        },
                        {
                          title: "Duration",
                          dataIndex: "duration",
                          key: "duration",
                          render: (_, __, index) => {
                            return (
                              <Row gutter={4}>
                                <Col span={12}>
                                  <Space
                                    direction="vertical"
                                    size="small"
                                    style={{ width: "100%" }}
                                  >
                                    <Text>Start Date</Text>
                                    <Form.Item
                                      {...adGroupCreativesFields[index]}
                                      name={[index, "startDate"]}
                                      fieldKey={[index, "startDate"]}
                                    >
                                      <DatePicker disabled={!editable} />
                                    </Form.Item>
                                  </Space>
                                </Col>
                                <Col span={12}>
                                  <Space
                                    direction="vertical"
                                    size="small"
                                    style={{ width: "100%" }}
                                  >
                                    <Text>End Date</Text>
                                    <Form.Item
                                      {...adGroupCreativesFields[index]}
                                      name={[index, "endDate"]}
                                      fieldKey={[index, "endDate"]}
                                    >
                                      <DatePicker disabled={!editable} />
                                    </Form.Item>
                                  </Space>
                                </Col>
                              </Row>
                            );
                          },
                        },
                        {
                          title: "Gallery",
                          dataIndex: "medias",
                          key: "medias",
                          render: medias => {
                            return (
                              <Space size="small">
                                {medias.map((media, index) => (
                                  <S3Image key={index} imgKey={media.key} />
                                ))}
                              </Space>
                            );
                          },
                        },
                      ]}
                      dataSource={adGroupCreatives}
                      pagination={false}
                      className="requester-table"
                      summary={() => (
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            {adGroupCreatives.length} Display Ad Groups
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>
                            {allMedias.length} Display Ad Units
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      )}
                    />
                  );
                }}
              </Form.List>
            </>
          )}
        </div>

        {/* Create hidden input for adGroupCreatives*/}
        <Form.Item
          {...restField}
          name={[name, "adGroup", "adGroupCreatives"]}
          fieldKey={[fieldKey, "adGroup", "adGroupCreatives"]}
          hidden
          rules={[
            {
              required: true,
              message: `Please select ad groups for ${campaigns[index].name}`,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Card>
      {showAdGroupsModal && (
        <CampaignRequestorAdGroupsDialog
          visible={showAdGroupsModal}
          setVisible={setShowAdGroupsModal}
          orgId={form.getFieldValue("advertiserId")}
          selectedAdGroupCreatives={adGroupCreatives}
          onAddSelections={creatives => {
            const updatedCampaigns = campaigns.map((campaign, i) => {
              if (i === index) {
                return {
                  ...campaign,
                  adGroup: {
                    ...campaign.adGroup,
                    adGroupCreatives: creatives.map(creative => {
                      // Check if creative already exists in the list
                      const existingAudience = adGroupCreatives.find(
                        a => a.name === creative.name
                      );
                      const adGroup = campaign.adGroup;

                      if (existingAudience) {
                        return existingAudience;
                      }

                      delete creative.id;
                      return {
                        ...creative,
                        startDate:
                          adGroup && adGroup.startDate
                            ? moment(campaign.adGroup.startDate)
                            : moment(form.getFieldValue("startDate")),
                        endDate:
                          adGroup && adGroup
                            ? moment(campaign.adGroup.endDate)
                            : moment(form.getFieldValue("endDate")),
                      };
                    }),
                  },
                };
              }
              return campaign;
            });
            form.setFieldsValue({
              campaigns: updatedCampaigns,
            });
            setPreviousValues({
              ...form.getFieldsValue(),
              campaigns: updatedCampaigns,
            });
          }}
        />
      )}
    </>
  );
};
