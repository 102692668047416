import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Controller from "./Controller";
import {
  graphql,
  Mutation,
  Query,
  useMutation,
  useQuery,
  withApollo,
  useLazyQuery,
} from "react-apollo";
import compose from "lodash/flowRight";
import { isEmpty } from "lodash";
import OverlaySpinner from "../../../platform/shared/OverlaySpinner";

import CURRENT_USER from "../../GraphQl/Queries/CURRENT_USER";
import CAMPAIGN_ORDER from "../../GraphQl/Queries/CAMPAIGN_ORDER";
import CREATE_CAMPAIGN_ORDER from "../../GraphQl/Mutations/CREATE_CAMPAIGN_ORDER";
import UPDATE_CAMPAIGN_ORDER from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER";
import UPDATE_CAMPAIGN from "../../GraphQl/Mutations/UPDATE_CAMPAIGN";
import CREATE_MEDIA_ADMIN from "../../GraphQl/Mutations/CREATE_MEDIA_ADMIN";
import DELETE_CREATIVE from "../../GraphQl/Mutations/DELETE_CREATIVE";
import DELETE_MANY_CREATIVES from "../../GraphQl/Mutations/DELETE_MANY_CREATIVES";
import DELETE_ADUNIT from "../../GraphQl/Mutations/DELETE_ADUNIT";
import CREATE_GEOTARGET from "../../GraphQl/Mutations/CREATE_GEOTARGET";
import DELETE_GEOTARGET from "../../GraphQl/Mutations/DELETE_GEOTARGET";
import UPDATE_GEOTARGET from "../../GraphQl/Mutations/UPDATE_GEOTARGET";
import ATTACH_FLIGHTS_GEOTARGET from "../../GraphQl/Mutations/ATTACH_FLIGHTS_GEOTARGET";
import ATTRIBUTION_AUDIENCES_LAUNCHER from "../../GraphQl/Queries/ATTRIBUTION_AUDIENCES_LAUNCHER";
import ATTRIBUTION_AUDIENCES from "../../GraphQl/Queries/ATTRIBUTION_AUDIENCES";
import CREATE_NOTIFICATION from "../../GraphQl/Mutations/CREATE_NOTIFICATION";
import CART_PROVIDER from "../../GraphQl/Introspection/CART_PROVIDER";
import { UPDATE_CAMPAIGN_ORDER_WITH_CPM } from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_WITH_CPM";
import EDIT_CAMPAIGN_STATUS from "../../GraphQl/Mutations/EDIT_CAMPAIGN_STATUS";
import GET_PARENT_ORG from "../../GraphQl/Queries/GET_PARENT_ORG";
import CREATE_CAMPAIGN_ORDER_NOTIFICATION from "../../GraphQl/Mutations/CREATE_CAMPAIGN_ORDER_NOTIFICATION";
import CAMPAIGN_ORDER_LIST from "../../GraphQl/Queries/CAMPAIGN_ORDER_LIST";
import GET_CAMPAIGN from "../../GraphQl/Queries/GET_CAMPAIGN";
import CREATE_LINE_ITEM_CAMPAIGN from "../../GraphQl/Mutations/CREATE_LINE_ITEM_CAMPAIGN";
import LIQUID_M_REGIONS from "../../GraphQl/Queries/LIQUID_M_REGIONS";
import LINE_ITEM_UPDATE_CAMPAIGN_ORDER from "../../GraphQl/Mutations/LINE_ITEM_UPDATE_CAMPAIGN_ORDER";
import LIQUID_M_CAMPAIGN_PAUSE from "../../GraphQl/Queries/LIQUID_M_CAMPAIGN_PAUSE";
import LIQUID_M_CAMPAIGN_START from "../../GraphQl/Queries/LIQUID_M_CAMPAIGN_START";
import GET_ORG_CPM from "../../GraphQl/Queries/GET_ORG_CPM";
import GET_SUB_ORGS_TAGS from "../../GraphQl/Queries/GET_SUB_ORGS_TAGS";
import UPDATE_CAMPAIGN_ORDER_TO_PARENT_ORGS from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_TO_PARENT_ORGS";
import CREATE_CAMPAIGN_HISTORY from "../../GraphQl/Mutations/CREATE_CAMPAIGN_HISTORY";

// REGION GROUP QUERIES
import GET_REGION_GROUPS_WITH_PRESET_LIST from "../../GraphQl/Queries/GET_REGION_GROUPS_WITH_PRESET_LIST";

// ADGROUP QUERIES
import GET_ADGROUP_CREATIVES_LAUNCHER from "../../GraphQl/Queries/GET_ADGROUP_CREATIVES_LAUNCHER";
import GET_ADGROUP_CREATIVE from "../../GraphQl/Queries/GET_ADGROUP_CREATIVE";

// TARGETING QUERIES
import GET_LOCATIONS from "../../GraphQl/Queries/GET_LOCATIONS";

// TARGET MUTATIONS
import CREATE_LOCATION_WITH_GEOTARGET from "../../GraphQl/Mutations/CREATE_LOCATION_WITH_GEOTARGET";
import CREATE_GEOTARGET_LOCATION from "../../GraphQl/Mutations/CREATE_GEOTARGET_LOCATION";
import DELETE_LOCATION_ADMIN from "../../GraphQl/Mutations/DELETE_LOCATION_ADMIN";
import EDIT_LOCATION_ADMIN_WITH_GEOTARGETS from "../../GraphQl/Mutations/EDIT_LOCATION_ADMIN_WITH_GEOTARGETS";
import UPDATE_LOCATION_SEGMENT from "../../GraphQl/Mutations/UPDATE_LOCATION_SEGMENT";
import DELETE_AUDIENCE_LOCATION from "../../GraphQl/Mutations/DELETE_AUDIENCE_LOCATION";
import ADD_CREATIVE_NOTES from "../../GraphQl/Mutations/ADD_CREATIVE_NOTES";
import ADD_LOCATION_NOTES from "../../GraphQl/Mutations/ADD_LOCATION_NOTES";
import UPDATE_CREATIVE_STATUS from "../../GraphQl/Mutations/UPDATE_CREATIVE_STATUS";
import CREATE_MANY_CAMPAIGN_CREATIVES from "../../GraphQl/Mutations/CREATE_MANY_CAMPAIGN_CREATIVES";
import CREATE_MANY_AD_UNITS from "../../GraphQl/Mutations/CREATE_MANY_AD_UNITS";
import GET_CART_PROVIDERS from "../../GraphQl/Queries/GET_CART_PROVIDER";
import UPDATE_CAMPAIGN_ORDER_REVIEW from "../../GraphQl/Mutations/UPDATE_CAMPAIGN_ORDER_REVIEW";
import CAMPAIGN_ORDER_SLACK_NOTIFICATION from "../../GraphQl/Mutations/CAMPAIGN_ORDER_SLACK_NOTIFICATION";
import GET_CAMPAIGN_ORDER_TAG_ACTIVITIES from "../../GraphQl/Queries/GET_CAMPAIGN_ORDER_TAG_ACTIVITIES";
import UPDATE_MANY_CREATIVES from "../../GraphQl/Mutations/UPDATE_MANY_CREATIVES";
import START_LIQUIDM_CAMPAIGN from "../../GraphQl/Mutations/START_LIQUIDM_CAMPAIGN";

const Loader = props => {
  const campaignOrderID = props.match.params.id;
  const loggedInRole = props.currentUser.role;
  const loggedInId = props.currentUser.id;
  const loggedInOrg = props.currentUser.role.org;
  const isTrafficker =
    loggedInOrg && loggedInOrg.config && loggedInOrg.config.isTrafficker;

  const isUserTrafficker = props.currentUser.permission.isTrafficker;

  const [selectedCreative, setSelectedCreative] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [adgroupOnCompleted, setAdgroupOnCompleted] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isCloningClicked, setIsCloningClicked] = useState(false);
  const [skip, setSkip] = useState(0);
  const [first, setFirst] = useState(10);
  const [pagination, setPagination] = useState({
    current: skip / first < 1 ? 1 : skip / first,
    pageSize: first,
  });
  const [searchLineName, setSearchLineName] = useState("");

  const searchParams = new URLSearchParams(props.location.search);
  const step = searchParams.get("step");
  const formattedStep = step ? Number(step) : 1;
  if (formattedStep > 6) {
    searchParams.delete("step");
    props.history.push(`${props.location.pathname}?${searchParams.toString()}`);
  }

  const { data: cartProvidersData } = useQuery(GET_CART_PROVIDERS);

  const [updateCampaignOrderWithCPM] = useMutation(
    UPDATE_CAMPAIGN_ORDER_WITH_CPM,
    {
      update(cache, { data: { updateCampaignOrder } }) {
        cache.writeQuery({
          query: CAMPAIGN_ORDER,
          variables: { ID: campaignOrderID },
          data: { campaignOrders: [updateCampaignOrder] },
          fetchPolicy: "no-cache",
        });
      },
    }
  );

  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    variables: {
      userID: props.userID,
      message: props.message,
    },
  });

  const [updateCampaignOrderLineItem] = useMutation(
    LINE_ITEM_UPDATE_CAMPAIGN_ORDER,
    {
      variables: {
        campaignOrderId: props.campaignOrderId,
        name: props.name,
        demandSidePlatform: props.demandSidePlatform,
        clientOrg: props.clientOrg,
        budgetTotal: props.budgetTotal,
        budgetByDay: props.budgetByDay,
        startDate: props.startDate,
        endDate: props.endDate,
        originalStartDate: props.originalStartDate,
        originalEndDate: props.originalEndDate,
        orgId: props.orgId,
        phoneNumber: props.phoneNumber,
        forwardingNumber: props.forwardingNumber,
        cpm: props.cpm,
        rawCost: props.rawCost,
      },
    }
  );

  const { data: cartProvider } = useQuery(CART_PROVIDER);

  const { data: getcpm } = useQuery(GET_ORG_CPM, {
    variables: {
      loggedInOrg: loggedInOrg.id,
    },
  });

  const {
    data: campaignOrderList,
    loading: campaignOrderListLoading,
  } = useQuery(CAMPAIGN_ORDER_LIST, {
    variables: {
      orgId: loggedInOrg.id,
      searchName: searchName,
    },
  });

  const { data: getSubOrgsTagsData } = useQuery(GET_SUB_ORGS_TAGS, {
    variables: {
      orgId: loggedInOrg.id,
    },
  });

  const [
    selectedCampaignList,
    { data: selectedCampaignListData, loading: selectedCampaignListLoading },
  ] = useLazyQuery(CAMPAIGN_ORDER, {
    variables: {
      id: props.id,
    },
  });

  const [
    searchRegionLiquidM,
    { data: searchRegionLiquidMData, loading: searchRegionLiquidMLoading },
  ] = useLazyQuery(LIQUID_M_REGIONS, {
    variables: {
      filter: props.filter,
      page: props.page,
    },
  });

  const [
    getParentOrg,
    { data: getParentOrgData, loading: getParentOrgLoading },
  ] = useLazyQuery(GET_PARENT_ORG, {
    variables: {
      loggedInOrg: props.loggedInOrg,
      orgName: props.orgName,
    },
  });

  const { data: regionGroups } = useQuery(GET_REGION_GROUPS_WITH_PRESET_LIST, {
    variables: { orgId: loggedInOrg.id },
  });

  const {
    data: selectedCampaignLineItemsData,
    loading: selectedCampaignLineItemsLoading,
    refetch,
  } = useQuery(GET_CAMPAIGN, {
    notifyOnNetworkStatusChange: true,
    variables: {
      skip,
      first,
      orgID: loggedInOrg.id,
      searchLineName: searchLineName,
    },
  });

  const [pauseLiquidMCampaign] = useLazyQuery(LIQUID_M_CAMPAIGN_PAUSE, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "no-cache",
  });

  const [startLiquidMCampaign] = useLazyQuery(LIQUID_M_CAMPAIGN_START, {
    variables: {
      id: props.id,
    },
    fetchPolicy: "no-cache",
  });

  const [createLineItemCampaign] = useMutation(CREATE_LINE_ITEM_CAMPAIGN, {
    variables: {
      name: props.name,
      adProviderId: props.adProviderId,
      clientOrg: props.clientOrg,
      orgId: props.orgId,
      campaignOrderId: props.campaignOrderId,
      startDate: props.startDate,
      endDate: props.endDate,
      cpm: props.cpm,
      budgetTotal: props.budgetTotal,
    },
  });

  const [updateCampaignOrderStatus] = useMutation(EDIT_CAMPAIGN_STATUS, {
    variables: {
      id: campaignOrderID,
      status: props.status,
    },
  });

  const [updateCampaignOrderOrgsAddParentOrgs] = useMutation(
    UPDATE_CAMPAIGN_ORDER_TO_PARENT_ORGS,
    {
      variables: {
        id: campaignOrderID,
        orgId: props.orgId,
      },
    }
  );

  const [createCampaignOrderNotification] = useMutation(
    CREATE_CAMPAIGN_ORDER_NOTIFICATION,
    {
      variables: {
        orgId: props.orgId,
        message: props.message,
      },
    }
  );

  const [addCreativeNotes] = useMutation(ADD_CREATIVE_NOTES, {
    variables: {
      id: props.id,
      mediaNotes: props.mediaNotes,
      mediaHasNotes: props.mediaHasNotes,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CAMPAIGN_ORDER,
        variables: {
          id: campaignOrderID,
        },
      },
    ],
  });

  const [addLocationNotes] = useMutation(ADD_LOCATION_NOTES, {
    variables: {
      id: props.id,
      notes: props.notes,
      hasNotes: props.hasNotes,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: CAMPAIGN_ORDER,
        variables: {
          id: campaignOrderID,
        },
      },
    ],
  });

  const [createManyCampaignCreatives] = useMutation(
    CREATE_MANY_CAMPAIGN_CREATIVES,
    {
      variables: {
        id: props.id,
        data: props.data,
      },
    }
  );

  const [createManyAdUnits] = useMutation(CREATE_MANY_AD_UNITS, {
    variables: {
      id: props.id,
      data: props.data,
    },
  });

  const [
    updateCampaignOrderReview,
    { loading: updateCampaignOrderReviewLoading },
  ] = useMutation(UPDATE_CAMPAIGN_ORDER_REVIEW, {
    variables: {
      campaignOrderID: props.campaignOrderID,
      review: props.review,
      stateLaw: props.stateLaw,
      scaleBudget: props.scaleBudget,
      reviewerId: props.reviewerId,
    },
  });

  const [createCampaignHistory] = useMutation(CREATE_CAMPAIGN_HISTORY, {
    variables: {
      action: props.action,
      property: props.property,
      data: props.data,
      authorId: props.authorId,
      campaignOrderId: props.campaignOrderId,
    },
  });

  const [campaignOrderSlackNotification] = useMutation(
    CAMPAIGN_ORDER_SLACK_NOTIFICATION,
    {
      variables: {
        id: campaignOrderID,
        userId: loggedInId,
      },
    }
  );

  // This doesn't need to refetch the campaign order, it should only update the state of the campaign order
  const [updateCampaignOrder, { data: updateCampaignOrderData }] = useMutation(
    UPDATE_CAMPAIGN_ORDER,
    {
      refetchQueries: [
        {
          query: CAMPAIGN_ORDER,
          variables: {
            id: campaignOrderID,
          },
        },
      ],
    }
  );

  // PAUSED or START LiquidM Campaign(s)
  const [
    pauseOrStartLiquidMCampaign,
    { loading: loadingPauseOrStartLiquidMCampaign },
  ] = useMutation(START_LIQUIDM_CAMPAIGN, {
    variables: {
      id: campaignOrderID,
    },
  });

  // ADGROUP QUERIES
  const { data: orgAdgroups, loading: orgAdgroupsLoading } = useQuery(
    GET_ADGROUP_CREATIVES_LAUNCHER,
    {
      variables: { orgId: loggedInOrg.id },
    }
  );

  const validateOrgAdgroups = orgAdgroups && orgAdgroups.adGroupCreativeses;

  const total = useMemo(() => {
    return selectedCampaignLineItemsData && selectedCampaignLineItemsData.total
      ? selectedCampaignLineItemsData.total.aggregate.count
      : 0;
  }, [selectedCampaignLineItemsData]);

  const [
    campaignOrderTagsActivities,
    {
      data: campaignOrderTagsActivitiesData,
      loading: campaignOrderTagsActivitiesLoading,
    },
  ] = useLazyQuery(GET_CAMPAIGN_ORDER_TAG_ACTIVITIES, {
    fetchPolicy: "no-cache",
  });

  const [
    adgroupOrgData,
    { data: orgAdgroup, loading: orgAdgroupLoading },
  ] = useLazyQuery(GET_ADGROUP_CREATIVE, {
    variables: { adgroupId: selectedCreative },
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setAdgroupOnCompleted(true);
      setLoading(false);
    },
  });

  const [updateManyCreatives] = useMutation(UPDATE_MANY_CREATIVES, {
    variables: {
      where: props.where,
      data: props.data,
    },
  });

  // This logic should be on the controller level not in loader (Separation of concern)
  useEffect(() => {
    if (selectedCreative !== "") {
      setLoading(true);
      setAdgroupOnCompleted(false);
      adgroupOrgData();
    }
  }, [adgroupOrgData, selectedCreative]);

  useEffect(() => {
    setFirst(pagination.pageSize);
    setSkip(pagination.current * pagination.pageSize - pagination.pageSize);
  }, [pagination, setSkip, setFirst]);

  useEffect(() => {
    refetch({
      first: 10,
      skip: 0,
    });
  }, [refetch]);

  return (
    <Mutation mutation={CREATE_CAMPAIGN_ORDER}>
      {(createCampaignOrder, { data, loading: createCampaignOrderLoading }) => {
        return (
          <Query
            query={CAMPAIGN_ORDER}
            variables={{ id: campaignOrderID }}
            skip={isEmpty(campaignOrderID)}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data }) => {
              if (loading || createCampaignOrderLoading)
                return <OverlaySpinner />;
              if (error)
                return `Error (Query: CAMPAIGN_ORDER)! ${error.message}`;
              let campaignOrder = null;

              if (
                updateCampaignOrderData &&
                updateCampaignOrderData.updateCampaignOrder
              ) {
                campaignOrder = updateCampaignOrderData.updateCampaignOrder;
              } else {
                campaignOrder = isEmpty(data)
                  ? null
                  : isEmpty(data.campaignOrders)
                  ? null
                  : data.campaignOrders[0];
              }
              const isLive = campaignOrder && campaignOrder.status === "LIVE";
              // TODO: if status isn't PENDING, route to correct location

              //Sets flag to determine mode of campaign manager - request launch of new campaign order vs. edit exisiting campaign order
              const isEdit = campaignOrder ? true : false;

              return (
                <Controller
                  {...props}
                  createCampaignOrderNotification={
                    createCampaignOrderNotification
                  }
                  campaignOrder={campaignOrder}
                  updateCampaignOrder={updateCampaignOrder}
                  createCampaignOrder={createCampaignOrder}
                  updateCampaignOrderWithCPM={updateCampaignOrderWithCPM}
                  orgID={loggedInRole.org.id}
                  orgName={loggedInRole.org.name}
                  isLive={isLive}
                  isEdit={isEdit}
                  step={formattedStep}
                  loggedInId={loggedInId}
                  createNotification={createNotification}
                  cartProvider={cartProvider}
                  orgAdgroups={validateOrgAdgroups}
                  orgAdgroupsLoading={orgAdgroupsLoading}
                  selectedCreative={selectedCreative}
                  setSelectedCreative={setSelectedCreative}
                  orgAdgroup={orgAdgroup}
                  orgAdgroupLoading={orgAdgroupLoading}
                  adgroupOnCompleted={adgroupOnCompleted}
                  setAdgroupOnCompleted={setAdgroupOnCompleted}
                  isTrafficker={isTrafficker}
                  isUserTrafficker={isUserTrafficker}
                  updateCampaignOrderStatus={updateCampaignOrderStatus}
                  campaignOrderList={campaignOrderList}
                  campaignOrderListLoading={campaignOrderListLoading}
                  searchName={searchName}
                  setSearchName={setSearchName}
                  selectedCampaignList={selectedCampaignList}
                  selectedCampaignListData={selectedCampaignListData}
                  selectedCampaignListLoading={selectedCampaignListLoading}
                  isCloningClicked={isCloningClicked}
                  setIsCloningClicked={setIsCloningClicked}
                  selectedCampaignLineItemsData={selectedCampaignLineItemsData}
                  selectedCampaignLineItemsLoading={
                    selectedCampaignLineItemsLoading
                  }
                  isLoading={isLoading}
                  refetch={refetch}
                  setSkip={setSkip}
                  setFirst={setFirst}
                  first={first}
                  skip={skip}
                  pagination={pagination}
                  setPagination={setPagination}
                  currentPagination={pagination}
                  total={total}
                  createLineItemCampaign={createLineItemCampaign}
                  urlCampaignOrderID={campaignOrderID}
                  searchRegionLiquidM={searchRegionLiquidM}
                  searchRegionLiquidMData={searchRegionLiquidMData}
                  searchRegionLiquidMLoading={searchRegionLiquidMLoading}
                  setSearchLineName={setSearchLineName}
                  searchLineName={searchLineName}
                  updateCampaignOrderLineItem={updateCampaignOrderLineItem}
                  pauseLiquidMCampaign={pauseLiquidMCampaign}
                  startLiquidMCampaign={startLiquidMCampaign}
                  getcpm={getcpm}
                  getSubOrgsTagsData={getSubOrgsTagsData}
                  updateCampaignOrderOrgsAddParentOrgs={
                    updateCampaignOrderOrgsAddParentOrgs
                  }
                  getParentOrg={getParentOrg}
                  getParentOrgData={getParentOrgData}
                  getParentOrgLoading={getParentOrgLoading}
                  regionGroups={regionGroups}
                  addCreativeNotes={addCreativeNotes}
                  addLocationNotes={addLocationNotes}
                  createManyCampaignCreatives={createManyCampaignCreatives}
                  createManyAdUnits={createManyAdUnits}
                  cartProvidersData={
                    cartProvidersData && cartProvidersData.getCarts
                  }
                  updateCampaignOrderReviewLoading={
                    updateCampaignOrderReviewLoading
                  }
                  updateCampaignOrderReview={updateCampaignOrderReview}
                  createCampaignHistory={createCampaignHistory}
                  campaignOrderSlackNotification={
                    campaignOrderSlackNotification
                  }
                  campaignOrderTagsActivities={campaignOrderTagsActivities}
                  campaignOrderTagsActivitiesData={
                    campaignOrderTagsActivitiesData
                  }
                  campaignOrderTagsActivitiesLoading={
                    campaignOrderTagsActivitiesLoading
                  }
                  updateManyCreatives={updateManyCreatives}
                  pauseOrStartLiquidMCampaign={pauseOrStartLiquidMCampaign}
                  loadingPauseOrStartLiquidMCampaign={
                    loadingPauseOrStartLiquidMCampaign
                  }
                >
                  {props.children}
                </Controller>
              );
            }}
          </Query>
        );
      }}
    </Mutation>
  );
};

export default compose(
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser }, ownProps }) => {
      return ownProps.currentUser || { currentUser };
    },
  }),

  graphql(ATTRIBUTION_AUDIENCES, {
    options: props => {
      const { currentUser } = props;
      const currentUsersOrg = currentUser.role.org.id;
      return { variables: { TYPE: "ATTRIBUTION", ORGID: currentUsersOrg } };
    },
    props: props => {
      return {
        audiences: props.data.audiencesConnection
          ? props.data.audiencesConnection
          : null,
      };
    },
  }),

  graphql(ATTRIBUTION_AUDIENCES_LAUNCHER, {
    options: props => {
      const { currentUser } = props;
      const currentUsersOrg = currentUser.role.org.id;
      return { variables: { TYPE: "TARGETING", orgId: currentUsersOrg } };
    },
    props: props => {
      return {
        audiencesTargeting:
          props.data && props.data.getAudiences && props.data.getAudiences.data
            ? props.data.getAudiences.data.audiencesConnection
            : null,
      };
    },
  }),
  graphql(CREATE_MEDIA_ADMIN, { name: "createMediaMutation" }),
  graphql(DELETE_CREATIVE, { name: "deleteCreative" }),
  graphql(DELETE_MANY_CREATIVES, { name: "deleteManyCreatives" }),
  graphql(UPDATE_CREATIVE_STATUS, { name: "updateCreativeStatus" }),
  graphql(DELETE_ADUNIT, { name: "deleteAdUnit" }),
  graphql(CREATE_GEOTARGET, { name: "createGeoTarget" }),
  graphql(ATTACH_FLIGHTS_GEOTARGET, { name: "attachFligthsGeoTarget" }),
  graphql(DELETE_GEOTARGET, { name: "deleteGeoTarget" }),
  graphql(UPDATE_GEOTARGET, { name: "updateGeoTarget" }),
  graphql(UPDATE_CAMPAIGN, { name: "updateInsertionOrder" }),
  graphql(GET_LOCATIONS, {
    options: props => {
      const { currentUser } = props;
      const currentUsersOrg = currentUser.role.org.id;
      return { variables: { id: currentUsersOrg } };
    },
    props: props => {
      return {
        targetingLocations: props.data.locations ? props.data.locations : null,
      };
    },
  }),
  graphql(CREATE_LOCATION_WITH_GEOTARGET, {
    name: "createLocationWithGeotarget",
  }),
  graphql(CREATE_GEOTARGET_LOCATION, {
    name: "createGeoTargetLocation",
  }),
  graphql(DELETE_LOCATION_ADMIN, { name: "deleteLocation" }),
  graphql(EDIT_LOCATION_ADMIN_WITH_GEOTARGETS, {
    name: "updateLocationWithGeotargets",
    options: () => ({
      refetchQueries: [
        {
          query: GET_LOCATIONS,
        },
      ],
    }),
  }),
  graphql(UPDATE_LOCATION_SEGMENT, {
    name: "updateSegment",
  }),
  graphql(DELETE_AUDIENCE_LOCATION, {
    name: "deleteAudienceLocation",
  })
)(withApollo(withRouter(Loader)));
