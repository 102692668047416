import React from "react";
import { Col, Form, Input, Row, Select, Typography } from "antd";
import "./styles.css";
import { countries } from "../../../core/utils/constants/constants";

const { Title } = Typography;

export const CampaignRequestorCountriesTable = ({
  form,
  restField,
  name,
  fieldKey,
  editable = false,
  index,
}) => {
  const targetingType = form.getFieldValue("campaigns")[index].targetingType;

  return (
    <div className="requester-table-wrapper" style={{ marginTop: 20 }}>
      <div className="requester-table-header">
        <Row>
          <Col span={12}>
            <Title level={5}>TARGETING: COUNTRY</Title>
          </Col>
        </Row>
      </div>
      <div style={{ padding: 20 }}>
        <Form.Item
          {...restField}
          name={[name, "targetingCountries"]}
          fieldKey={[fieldKey, "targetingCountries"]}
        >
          <Select
            style={{ width: "100%" }}
            options={countries.map(country => ({
              label: country.name,
              value: country.code,
            }))}
            disabled={!editable}
          />
        </Form.Item>
      </div>

      {/* Create hidden input for targetingCountries */}
      <Form.Item
        {...restField}
        name={[name, "targetingCountries"]}
        fieldKey={[fieldKey, "targetingCountries"]}
        hidden
        rules={[
          {
            required: targetingType === "COUNTRY",
            message: "Please select a country",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
