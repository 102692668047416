import { Modal, Button, Card, Row, Typography, Input } from "antd";
import React from "react";

const { Text } = Typography;
const TaskCodeSnippetModal = ({ visible, setVisible, onEmbedSnippet }) => {
  const [snippet, setSnippet] = React.useState("");
  return (
    <Modal
      closable={false}
      visible={visible}
      bodyStyle={{ height: "300px", padding: "0" }}
      footer={
        <>
          <Button
            onClick={() => {
              setVisible(false);
            }}
            style={{ marginLeft: "8px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onEmbedSnippet(snippet);
              setVisible(false);
            }}
            style={{ marginLeft: "8px" }}
          >
            Embed
          </Button>
        </>
      }
    >
      <Card
        title={<Text style={{ fontWeight: "bolder" }}>SEND CODE SNIPPET</Text>}
      >
        <Row style={{ paddingBottom: "20px" }}>
          <Text>
            Embed a code snippet by pasting the code into the field below:
          </Text>
        </Row>
        <Row
          style={{
            backgroundColor: "#f5f5f5",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <Row style={{ flexGrow: 1 }}>
            <Input.TextArea
              maxLength={5000}
              autoSize={{ minRows: 6, maxRows: 6 }}
              value={snippet}
              onChange={e => {
                setSnippet(e.target.value);
              }}
              style={{
                backgroundColor: "#f5f5f5",
                border: "none",
                outline: "none",
                resize: "none",
                width: "100%",
                margin: "5px",
              }}
            />
          </Row>
        </Row>
      </Card>
    </Modal>
  );
};

export default TaskCodeSnippetModal;
