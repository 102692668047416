import React, { useEffect, useState } from "react";
import { Button, Modal, Space, Spin, Table } from "antd";

import moment from "moment";
import { useCreativeContext } from "../../../core/components/creatives/main/useCreativeContext";
import S3Image from "../CampaignLauncher/components/S3Image";
import { cloneDeep } from "lodash";

const columns = [
  {
    title: "Display Ad Gropup Name",
    dataIndex: "name",
    key: "name",
    ellipsis: true, // Enables ellipsis for text overflow
    width: 200, // Set a fixed width for the column
  },
  {
    title: "Ad Units",
    dataIndex: "medias",
    key: "medias",
    render: medias => medias.length,
  },
  {
    title: "Last Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: date => moment(date).format("MM/DD/YYYY hh:mma"),
  },
  {
    title: "Gallery",
    dataIndex: "medias",
    key: "medias",
    render: medias => {
      return (
        <Space size="small">
          {medias.map((media, index) => (
            <S3Image key={index} imgKey={media.key} />
          ))}
        </Space>
      );
    },
  },
];

const CampaignRequestorAdGroupsDialog = ({
  visible,
  setVisible,
  orgId,
  onAddSelections,
  selectedAdGroupCreatives,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    fetchAdGroupCreatives,
    adGroupCreatives,
    loadingAdGroupCreatives,
  } = useCreativeContext();

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }
    const selectedAdGroup = cloneDeep(adGroupCreatives).filter(adGroup =>
      selectedRowKeys.includes(adGroup.id)
    );
    onAddSelections(selectedAdGroup);
    setVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    fetchAdGroupCreatives({
      variables: {
        where: {
          AND: [
            {
              org_some: {
                id: orgId,
              },
            },

            {
              org_every: {
                id: orgId,
              },
            },
            {
              medias_every: {
                type: "STILL",
              },
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedAdGroupCreatives && selectedAdGroupCreatives.length > 0) {
      const keys = [];
      selectedAdGroupCreatives.forEach(group => {
        // Find the targeting group in the list by name
        const adGroup = adGroupCreatives.find(t => t.name === group.name);
        if (adGroup) {
          keys.push(adGroup.id);
        }
      });
      setSelectedRowKeys(keys);
    }
  }, [adGroupCreatives, selectedAdGroupCreatives]);

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: 20,
            }}
          >
            <span>DISPLAY AD GROUPS</span>
            <div>
              <Button type="default" onClick={() => handleAddSelections()}>
                Add Selection(s)
              </Button>
            </div>
          </div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={1200}
      >
        {loadingAdGroupCreatives ? (
          <Space direction="vertical" style={{ width: "100%" }} align="center">
            <Spin />
          </Space>
        ) : (
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={adGroupCreatives}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaignRequestorAdGroupsDialog;
