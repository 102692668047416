import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import CampaignRequestorRegionGroupsDialog from "./CampaignRequestorRegionGroupsDialog";
import CampaignRequestorTargetingGroupsDialog from "./CampaignRequestorTargetingGroupsDialog";
import { CampaignRequestorAudience } from "./CampaignRequestorAudience";
import { CampaignRequestorAdGroup } from "./CampaignRequestorAdGroup";
import { calculateImpressions } from "./CampaignRequestorUtil";
import { CampaignRequestorTargetingRegionTable } from "./CampaignRequestorTargetingRegionTable";
import { CampaignRequestorTargetingLocalTable } from "./CampaignRequestorTargetingLocalTable";
import moment from "moment";
import { CampaignRequestorCountriesTable } from "./CampaignRequestorCountriesTable";
const { Title, Text } = Typography;
const { Panel } = Collapse;

const CampaignRequestorLineItem = ({
  form,
  name,
  fieldKey,
  restField,
  index,
  setPreviousValues,
  add,
}) => {
  const budget = form.getFieldValue("budget");
  // const cpm = form.getFieldValue("cpm");
  const campaigns = form.getFieldValue("campaigns");
  const lineItemName = form.getFieldValue(["campaigns", index, "name"]);
  const targetingRegions = form.getFieldValue([
    "campaigns",
    index,
    "targetingRegions",
  ]);
  const targetingLocations = form.getFieldValue([
    "campaigns",
    index,
    "targetingLocations",
  ]);

  const [showRegionsModal, setShowRegionsModal] = useState(false);
  const [showTargetingGroupsModal, setShowTargetingGroupsModal] = useState(
    false
  );
  const [targetingType, setTargetingType] = useState(
    form.getFieldValue(["campaigns", index, "targetingType"])
  );

  const handleCloneLineItem = ({ event, index }) => {
    event.preventDefault();
    const cloneItemSource = JSON.parse(JSON.stringify(campaigns[index]));

    let newLineItem = {
      ...cloneItemSource,
      budgetTotal: Number(cloneItemSource.budgetTotal) / 2,
      budgetPercentage: Number(cloneItemSource.budgetPercentage) / 2,
      impressions: calculateImpressions({
        budget: Number(cloneItemSource.budgetTotal) / 2,
        cpm: cloneItemSource.cpm,
      }),
    };
    newLineItem.name = `${newLineItem.name} (Copy)`;
    delete newLineItem.id;

    // Check if there adGroup and adGroupCreatives
    if (newLineItem.adGroup && newLineItem.adGroup.id) {
      delete newLineItem.adGroup.id;
      if (
        newLineItem.adGroup.adGroupCreatives &&
        newLineItem.adGroup.adGroupCreatives.length
      ) {
        newLineItem.adGroup.adGroupCreatives = newLineItem.adGroup.adGroupCreatives.map(
          adGroupCreative => {
            delete adGroupCreative.id;
            if (adGroupCreative.medias && adGroupCreative.medias.length) {
              adGroupCreative.medias = adGroupCreative.medias.map(media => {
                delete media.id;
                return media;
              });
            }
            return adGroupCreative;
          }
        );
      }
    }

    // Check if there are firstPartyAudiences
    if (
      newLineItem.firstPartyDataAudiences &&
      newLineItem.firstPartyDataAudiences.length
    ) {
      newLineItem.firstPartyDataAudiences = newLineItem.firstPartyDataAudiences.map(
        audience => {
          delete audience.id;
          return audience;
        }
      );
    }

    // Check if there are targetingRegions
    if (newLineItem.targetingRegions && newLineItem.targetingRegions.length) {
      newLineItem.targetingRegions = newLineItem.targetingRegions.map(
        region => {
          delete region.id;
          return region;
        }
      );
    }

    // Check if there are targetingLocations
    if (
      newLineItem.targetingLocations &&
      newLineItem.targetingLocations.length
    ) {
      newLineItem.targetingLocations = newLineItem.targetingLocations.map(
        location => {
          delete location.id;
          return location;
        }
      );
    }

    const updatedCampaigns = campaigns.map((campaign, i) => {
      if (i === index) {
        return {
          ...campaign,
          budgetTotal: Number(campaign.budgetTotal) / 2,
          budgetPercentage: Number(campaign.budgetPercentage) / 2,
          impressions: calculateImpressions({
            budget: Number(campaign.budgetTotal) / 2,
            cpm: campaign.cpm,
          }),
        };
      }
      return campaign;
    });

    newLineItem = {
      ...newLineItem,
      startDate: newLineItem.startDate ? moment(newLineItem.startDate) : null,
      endDate: newLineItem.endDate ? moment(newLineItem.endDate) : null,
      adGroup:
        newLineItem.adGroup && newLineItem.adGroup.adGroupCreatives
          ? {
              ...newLineItem.adGroup,
              adGroupCreatives: newLineItem.adGroup.adGroupCreatives.map(
                adGroupCreative => {
                  return {
                    ...adGroupCreative,
                    startDate: adGroupCreative.startDate
                      ? moment(adGroupCreative.startDate)
                      : null,
                    endDate: adGroupCreative.endDate
                      ? moment(adGroupCreative.endDate)
                      : null,
                  };
                }
              ),
            }
          : null,
    };

    form.setFieldsValue({
      campaigns: updatedCampaigns,
    });

    add(newLineItem);
    setPreviousValues({
      ...form.getFieldsValue(),
      campaigns: [...updatedCampaigns, newLineItem],
    });
  };

  const handleDeleteLineItem = index => {
    let newCampaigns = [...campaigns];
    // Get the current budget from the line item to be deleted
    const budgetToDelete = newCampaigns[index].budgetTotal;
    newCampaigns.splice(index, 1);

    // Get the last index of the newCampaigns array
    const lastIndex = newCampaigns.length - 1;

    newCampaigns[lastIndex] = {
      ...newCampaigns[lastIndex],
      budgetTotal: newCampaigns[lastIndex].budgetTotal + budgetToDelete,
      budgetPercentage:
        newCampaigns[lastIndex].budgetPercentage +
        (budgetToDelete / budget) * 100,
      impressions: calculateImpressions({
        budget: newCampaigns[lastIndex].budgetTotal + budgetToDelete,
        cpm: newCampaigns[lastIndex].cpm,
      }),
    };

    form.setFieldsValue({
      campaigns: newCampaigns,
    });
    setPreviousValues({
      ...form.getFieldsValue(),
      campaigns: newCampaigns,
    });
  };

  const startDisabledDate = date => {
    const startDate = form.getFieldValue("startDate");
    const endDateCampaign = form.getFieldValue(["campaigns", index, "endDate"]);
    // Disable dates after the endDate and 2 days before the endDate
    return (
      (date &&
        (date > moment(endDateCampaign) ||
          date >=
            moment(endDateCampaign)
              .clone()
              .subtract(2, "days"))) ||
      date < moment(startDate)
    );
  };

  const endDisabledDate = date => {
    const endDate = moment(form.getFieldValue("endDate")).endOf("day");
    const startDateCampaign = moment(
      form.getFieldValue(["campaigns", index, "startDate"])
    ).startOf("day");

    // Disable dates before the startDate
    return (
      date <
        moment(startDateCampaign)
          .clone()
          .add(2, "days") || date > moment(endDate)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setTargetingType(
        form.getFieldValue(["campaigns", index, "targetingType"])
      );
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return (
    <div id={`requester-line-item-${index}`}>
      <Collapse bordered={false} defaultActiveKey={[0]} key={index}>
        <Panel
          open={true}
          header={<Title level={5}>LINE ITEM {index + 1}</Title>}
          extra={
            <Space size="small">
              <Button
                disabled={!index}
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteLineItem(index)}
              >
                Delete Line Item {index + 1}
              </Button>
              <Button
                onClick={event => handleCloneLineItem({ event, index })}
                icon={<CopyOutlined />}
              >
                Clone Line Item {index + 1}
              </Button>
            </Space>
          }
        >
          <Card>
            <Space direction="vertical" style={{ width: "100%" }} size="large">
              <Row gutter={20}>
                <Col span={12}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text>Line Item Name</Text>
                    <Text type="secondary">
                      The name you will identify this line item by
                    </Text>
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      fieldKey={[fieldKey, "name"]}
                      rules={[
                        { required: true, message: "Missing Line Item Name" },
                      ]}
                    >
                      <Input placeholder="Line Item Name" />
                    </Form.Item>
                  </Space>
                </Col>
                {/* <Col span={12}>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <Text>Wholesale CPM</Text>
                    <Text type="secondary">
                      Rate you pay MediaJel (only visible to your org)
                    </Text>
                    <Input
                      addonBefore="$USD"
                      defaultValue="5"
                      style={{ width: "50%" }}
                    />
                  </Space>
                </Col> */}
              </Row>

              <Card title="DURATION">
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Row gutter={20}>
                    <Col span={10}>
                      <Row>
                        <Col span={12}>
                          <Space
                            direction="vertical"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Text>Start Date</Text>
                            <Text type="secondary">Leave blank for ASAP </Text>
                            <Form.Item
                              {...restField}
                              name={[name, "startDate"]}
                              fieldKey={[fieldKey, "startDate"]}
                            >
                              <DatePicker disabledDate={startDisabledDate} />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space
                            direction="vertical"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Text>End Date</Text>
                            <Text type="secondary">Date campaign will end</Text>
                            <Form.Item
                              {...restField}
                              name={[name, "endDate"]}
                              fieldKey={[fieldKey, "endDate"]}
                              rules={[
                                { required: true, message: "Missing End Date" },
                              ]}
                            >
                              <DatePicker disabledDate={endDisabledDate} />
                            </Form.Item>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col span={10}>
                      <Row>
                        <Col span={24}>
                          <Space
                            direction="vertical"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Text>Line Item Budget</Text>
                            <Text type="secondary">
                              Maximum amount this line item will spend over its
                              duration
                            </Text>
                            <div>
                              <Input.Group compact>
                                <Form.Item
                                  {...restField}
                                  name={[name, "budgetTotal"]}
                                  fieldKey={[fieldKey, "budgetTotal"]}
                                  style={{ width: "30%" }}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Line Item Budget",
                                    },
                                    {
                                      validator: (_, value) => {
                                        if (value > 0) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          "Line Item Budget must be greater than zero"
                                        );
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    addonBefore={<>$</>}
                                    disabled={campaigns.length === 1}
                                    min={0}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  name={[name, "budgetPercentage"]}
                                  fieldKey={[fieldKey, "budgetPercentage"]}
                                  style={{ width: "30%" }}
                                >
                                  <Input
                                    addonAfter={<>%</>}
                                    disabled={campaigns.length === 1}
                                    min={0}
                                  />
                                </Form.Item>
                              </Input.Group>
                            </div>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row gutter={20}>
                    <Col span={10}>
                      <Row>
                        <Col span={14}>
                          <Space
                            direction="vertical"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Text>CPM</Text>
                            <Text type="secondary">
                              Rate per 1,000 impressions
                            </Text>
                            <div>
                              <Input.Group compact>
                                <Form.Item
                                  {...restField}
                                  name={[name, "cpm"]}
                                  fieldKey={[fieldKey, "cpm"]}
                                  style={{ width: "50%" }}
                                  rules={[
                                    { required: true, message: "Missing CPM" },
                                    () => ({
                                      validator(_, value) {
                                        if (value >= 1 || value === "") {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error("Please input a valid CPM")
                                        );
                                      },
                                    }),
                                  ]}
                                >
                                  <Input addonBefore={<>USD</>} />
                                </Form.Item>
                              </Input.Group>
                            </div>
                          </Space>
                        </Col>
                        <Col span={10}>
                          <Space
                            direction="vertical"
                            size="small"
                            style={{ width: "100%" }}
                          >
                            <Text>Monthly Impressions</Text>
                            <Text type="secondary">Per line item</Text>
                            <div>
                              <Input.Group compact>
                                {/* <Form.Item
                                  {...restField}
                                  name={[name, "impressions"]}
                                  fieldKey={[fieldKey, "impressions"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Monthly Impressions",
                                    },
                                  ]}
                                > */}
                                <Input
                                  disabled
                                  value={(
                                    form.getFieldValue([
                                      "campaigns",
                                      index,
                                      "impressions",
                                    ]) || 0
                                  ).toLocaleString()}
                                />
                                {/* </Form.Item> */}
                              </Input.Group>
                            </div>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Space>
              </Card>

              <Card title="TARGETING TYPE">
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: "100%" }}
                >
                  <Text>Targeting type for this line item: {lineItemName}</Text>
                  <Form.Item
                    {...restField}
                    name={[name, "targetingType"]}
                    fieldKey={[fieldKey, "targetingType"]}
                    rules={[
                      {
                        required: true,
                        message: `Please select a targeting type for ${lineItemName}`,
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={e => setTargetingType(e.target.value)}
                    >
                      <Space direction="vertical">
                        <Radio value="REGION">Target specific regions</Radio>
                        <Radio value="LOCAL">Local Targeting</Radio>
                        <Radio value="COUNTRY">Target an entire country</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Space>

                {targetingType === "REGION" && (
                  <CampaignRequestorTargetingRegionTable
                    name={name}
                    restField={restField}
                    fieldKey={fieldKey}
                    form={form}
                    index={index}
                    editable={true}
                    setShowRegionsModal={setShowRegionsModal}
                  />
                )}

                {targetingType === "LOCAL" && (
                  <CampaignRequestorTargetingLocalTable
                    name={name}
                    restField={restField}
                    fieldKey={fieldKey}
                    form={form}
                    index={index}
                    setShowTargetingGroupsModal={setShowTargetingGroupsModal}
                    editable={true}
                  />
                )}

                {targetingType === "COUNTRY" && (
                  <CampaignRequestorCountriesTable
                    form={form}
                    restField={restField}
                    name={name}
                    fieldKey={fieldKey}
                    editable={true}
                    index={index}
                  />
                )}
              </Card>

              <CampaignRequestorAudience
                form={form}
                index={index}
                restField={restField}
                fieldKey={fieldKey}
                name={name}
                setPreviousValues={setPreviousValues}
              />

              <CampaignRequestorAdGroup
                form={form}
                index={index}
                restField={restField}
                fieldKey={fieldKey}
                name={name}
                editable={true}
                setPreviousValues={setPreviousValues}
              />
            </Space>
          </Card>
        </Panel>
      </Collapse>

      {showRegionsModal && (
        <CampaignRequestorRegionGroupsDialog
          visible={showRegionsModal}
          setVisible={setShowRegionsModal}
          selectedRegionGroups={targetingRegions}
          onAddSelections={regions => {
            const updatedCampaigns = campaigns.map((campaign, i) => {
              if (i === index) {
                return {
                  ...campaign,
                  targetingRegions: regions.map(region => {
                    // Check if region is newly added
                    const existingRegion = targetingRegions.find(
                      r => r.name === region.name
                    );

                    if (existingRegion) {
                      return existingRegion;
                    }

                    delete region.id;
                    return region;
                  }),
                };
              }
              return campaign;
            });
            form.setFieldsValue({
              campaigns: updatedCampaigns,
            });
            setPreviousValues({
              ...form.getFieldsValue(),
              campaigns: updatedCampaigns,
            });
          }}
        />
      )}

      {showTargetingGroupsModal && (
        <CampaignRequestorTargetingGroupsDialog
          visible={showTargetingGroupsModal}
          setVisible={setShowTargetingGroupsModal}
          selectedTargetingGroups={targetingLocations}
          onAddSelections={targetingLocations => {
            const updatedCampaigns = campaigns.map((campaign, i) => {
              if (i === index) {
                return {
                  ...campaign,
                  targetingLocations: targetingLocations.map(location => {
                    // Check if location is newly added
                    const existingLocation = targetingLocations.find(
                      l => l.name === location.name
                    );

                    if (existingLocation) {
                      return existingLocation;
                    }

                    delete location.id;
                    return location;
                  }),
                };
              }
              return campaign;
            });
            form.setFieldsValue({
              campaigns: updatedCampaigns,
            });
            setPreviousValues({
              ...form.getFieldsValue(),
              campaigns: updatedCampaigns,
            });
          }}
        />
      )}
    </div>
  );
};

export default CampaignRequestorLineItem;
