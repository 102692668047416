import gql from "graphql-tag";

const UPDATE_COMMENT = gql`
  mutation updateComment(
    $data: CommentUpdateInput!
    $where: CommentWhereUniqueInput!
  ) {
    updateComment(data: $data, where: $where) {
      id
      comment
      attachedFiles {
        id
        name
        url
      }
      attachedImages {
        id
        name
        url
      }
      codeSnippet
      createdBy {
        id
        name
      }
      createdAt
    }
  }
`;

export default UPDATE_COMMENT;
