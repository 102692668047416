import React, { useEffect, useState } from "react";
import { useCampaignOrderContext } from "../../../../core/components/campaingOrder/useCampaignOrderContext";
import { Modal, Row, Button, Table } from "antd";
import { PageTitleText } from "../../../shared/globalStyling/styledText";
import moment from "moment";
import { debounce } from "lodash";

const columns = [
  {
    title: "Campaign Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Flight Dates",
    dataIndex: "flightDates",
    key: "flightDates",
    render: (_, record) => {
      return `${moment(record.startDate).format("MM/DD/YYYY")} - ${moment(
        record.endDate
      ).format("MM/DD/YYYY")}`;
    },
  },
];

const TaskRelatedCampaignsModal = ({
  visible,
  setVisible,
  channelPartnerOrgId,
  onAddSelections,
  selectedCampaigns,
}) => {
  const {
    fetchBasicCampaignOrdersDetailsConnection,
    campaignOrders,
    basicCampaignOrdersDetailsLoading,
    campaignOrdersPagination,
    setCampaignOrdersPagination,
  } = useCampaignOrderContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleAddSelections = () => {
    if (selectedRowKeys.length === 0) {
      onAddSelections([]);
      setVisible(false);
      return;
    }

    const newSelectedItems = campaignOrders.filter(item =>
      selectedRowKeys.includes(item.id)
    );
    onAddSelections(newSelectedItems);
    setVisible(false);
  };

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleFetchCampaignOrders = debounce((page = 1, pageSize = 10) => {
    fetchBasicCampaignOrdersDetailsConnection({
      variables: {
        where: {
          orgs_some: {
            OR: [
              {
                parentOrg_some: {
                  id: channelPartnerOrgId,
                },
              },
              {
                id: channelPartnerOrgId,
              },
            ],
          },
        },
        orderBy: "createdAt_DESC",
        first: pageSize,
        skip: (page - 1) * pageSize,
      },
    });
  }, 500);

  useEffect(() => {
    handleFetchCampaignOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCampaigns && selectedCampaigns.length > 0) {
      const keys = [];
      selectedCampaigns.forEach(group => {
        const targeting = campaignOrders.find(t => t.name === group.name);
        if (targeting) {
          keys.push(targeting.id);
        }
      });
      setSelectedRowKeys(keys);
    }
  }, [campaignOrders, selectedCampaigns]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Modal
        visible={visible}
        width={"50%"}
        closable={false}
        onCancel={() => setVisible(false)}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Row style={{ display: "flex", justifyContent: "space-between" }}>
          <PageTitleText text={"ATTACH CAMPAIGNS"} />
          <Button type="primary" onClick={() => handleAddSelections()}>
            Attach Campaign(s)
          </Button>
        </Row>
        <Table
          rowKey="id"
          rowSelection={rowSelection}
          columns={columns}
          loading={basicCampaignOrdersDetailsLoading}
          dataSource={campaignOrders}
          pagination={campaignOrdersPagination}
          onChange={(paginationObject, filtersObject, sorterObject) => {
            setCampaignOrdersPagination({
              ...campaignOrdersPagination,
              current: paginationObject.current,
              pageSize: paginationObject.pageSize,
            });
            handleFetchCampaignOrders(
              paginationObject.current,
              paginationObject.pageSize
            );
          }}
        />
      </Modal>
    </div>
  );
};

export default TaskRelatedCampaignsModal;
