import React from "react";
import { Auth } from "aws-amplify";
import { graphql } from "react-apollo";
import compose from "lodash/flowRight";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { PublicRoutes, PrivateRoutes } from "../platform/routes";
import { GlobalContextWrapper } from "./context/GlobalContext";
import {
  getTopRole,
  getRolesFeatures,
} from "./utils/dataManipulating/organization";
import OverlaySpinner from ".././platform/shared/OverlaySpinner";
import UPDATE_USER from "./GraphQl/Mutations/UPDATE_USER";
import AUTHENTICATED_USER from "./GraphQl/Mutations/AUTHENTICATED_USER";
import CURRENT_USER from "../core/GraphQl/Queries/CURRENT_USER";
import CryptoJS from "crypto-js";
import Idle from "idle-js";
import { notification } from "antd";
import { H } from "highlight.run";

const { search, href } = window.location;
const currentUrl = window.location.href;

class AccountLoader extends React.PureComponent {
  state = {
    loading: true,
  };

  componentDidMount = async () => {
    try {
      if (search && search.includes("?token=")) {
        Auth.signOut();
        this.loginViaToken();
      } else {
        // This is to check if the user is idle for 15 minutes if so then logout the user
        this.checkIdleTime();
        Auth.currentAuthenticatedUser()
          .then(() => {
            this.setLoggedInUser();
          })
          .catch(err => {
            console.log(err);
            Auth.signOut().then(() => {
              this.setState({ loading: false });

              const url = new URL(currentUrl);
              const redirectUrlParam = url.searchParams.get("redirectUrl");

              this.setState({ loading: false });

              if (redirectUrlParam) {
                if (redirectUrlParam === `${window.location.origin}/signin`) {
                  this.props.history.push("/signin");
                }
              } else {
                if (redirectUrlParam !== `${window.location.origin}/signin`) {
                  this.props.history.push(
                    `/signin?redirectUrl=${currentUrl.replace(
                      window.location.origin,
                      ""
                    )}`
                  );
                }
              }
            });
          });
      }
    } catch (error) {
      console.log(error);
      Auth.signOut();
      this.setState({ loading: false });
    }

    // Check if session is idle
    const sessionIdle = localStorage.getItem("sessionIdle");
    if (sessionIdle) {
      notification.close("sessionWarning");
      notification.error({
        message: "Session Expired",
        description: "You have been logged out due to inactivity.",
        key: "sessionExpired",
        duration: 0, // the notification will not close automatically
      });
      localStorage.removeItem("sessionIdle");
    }
  };

  componentWillUnmount() {
    this.idle.stop();
  }

  setLoggedInUser = async () => {
    try {
      const response = await this.props.authenticatedUser();
      if (response) {
        const {
          authenticatedUser: {
            id,
            username,
            config,
            email,
            cognitoUserId,
            avatar,
            roles,
            notifications,
            msaStatus,
            ssoToken,
            campaignTableDisplayColumns,
          },
        } = response.data;

        // Set highlight.io user context
        H.identify(username, {
          id,
          email,
        });

        const features = getRolesFeatures(roles);
        if (features.includes("ENABLED")) {
          const enabledFeatures = features.filter(feature => {
            return feature !== "ENABLED";
          });

          const role = getTopRole(roles);

          const isAdmin = config ? config.isAdmin : false;

          const isDemo = config ? config.isDemo : false;

          const isPreviewOrg = false;

          let previewUser = null;

          if (localStorage.getItem("userData")) {
            previewUser = JSON.parse(localStorage.getItem("userData"));
          }

          const userData = previewUser || {
            isAuthenticated: true,
            id,
            username,
            email,
            cognitoUserId,
            avatar,
            features: enabledFeatures,
            notifications,
            defaultRole: role,
            role,
            isAdmin,
            isDemo,
            isPreviewOrg,
            permission: config,
            msaStatus,
            ssoToken,
            campaignTableDisplayColumns:
              campaignTableDisplayColumns && campaignTableDisplayColumns.length
                ? campaignTableDisplayColumns
                : [],
            config,
          };

          let rbacPermissions = [];
          if (
            userData.config &&
            userData.config.embeddableConfig &&
            userData.config.embeddableConfig.type
          ) {
            switch (userData.config.embeddableConfig.type) {
              case "DEFAULT":
                rbacPermissions.push("defaultSSO");
                break;

              case "DISPLAY":
                rbacPermissions.push("displaySSO");
                break;

              case "ADVERTISER":
                rbacPermissions.push("advertiserSSO");
                break;

              case "PUBLISHER":
                rbacPermissions.push("publisherSSO");
                break;

              default:
                Object.keys(
                  userData.config.embeddableConfig.widgetConfig
                ).forEach(key => {
                  if (
                    userData.config.embeddableConfig.widgetConfig[key] &&
                    key !== "__typename"
                  ) {
                    rbacPermissions.push(`widgetSSO${key}`);
                  }
                });
                rbacPermissions.push("widgetSSO");
                break;
            }
          } else {
            rbacPermissions.push("default");
          }

          const level =
            userData &&
            userData.role &&
            userData.role.org &&
            userData.role.org.level;
          let userRoles = [];

          if (userData.config) {
            userRoles = Object.keys(userData.config)
              .filter(key => key.startsWith("is"))
              .filter(key => userData.config[key]);
          }
          userRoles.push(level);

          this.props.setUser({
            id: userData.id,
            roles: userRoles,
            permissions: rbacPermissions,
          });

          await this.props.updateUser({
            variables: userData,
          });

          this.setState({
            loading: false,
          });
        } else {
          console.log("Not enabled");
          Auth.signOut();
        }
      }
    } catch (err) {
      console.log(err);
      await Auth.signOut();
      this.setState({ loading: false });
    }
  };

  getParams = url => {
    const result = {};
    const params = url.split("?");
    params.shift(); // discard the part before the first `?`

    params.forEach(param => {
      let [key, value] = param.split("=");
      result[key] = value;
    });

    return result;
  };

  loginViaToken = async () => {
    let tokenUsername = null;
    let tokenPassword = null;
    let token = null;
    let redirectUrl = null;
    try {
      let params = this.getParams(href);
      token = params["token"];
      redirectUrl = params["redirectUrl"];

      const bytesToken = CryptoJS.AES.decrypt(
        token,
        process.env.REACT_APP_TOKEN_SECRET
      );
      const creds = bytesToken.toString(CryptoJS.enc.Utf8).split("|");
      tokenUsername = creds[0].split("=")[1];
      tokenPassword = creds[1].split("=")[1];

      if (tokenUsername && tokenPassword) {
        const result = await Auth.signIn(tokenUsername, tokenPassword);
        if (result) {
          this.setLoggedInUser();
          if (redirectUrl) {
            this.props.history.push(redirectUrl);
          }
        } else {
          throw new Error("Invalid token");
        }
      }
    } catch (error) {
      Auth.signOut();
      this.setState({ loading: false });
      this.props.history.push("/page-not-found");
    }
  };

  checkIdleTime = () => {
    this.idle = new Idle({
      onIdle: () => {
        // TODO: Investitage why currentUser is undefined on mount and on update lifecycle methods
        if (this.props.currentUser) {
          notification.open({
            message: "Are you still signed in?",
            description: "You will be logged out in 10 minutes.",
            key: "sessionWarning",
            duration: 0, // the notification will not close automatically
          });

          // Start another timer to actually sign the user out after 10 minutes
          this.logoutTimer = setTimeout(() => {
            Auth.signOut();
            localStorage.setItem("sessionIdle", true);
            window.location.reload();
          }, 600000); // 10 minutes in milliseconds
        }
      },
      onActive: () => {
        // If the user becomes active, close the warning notification and clear the logout timer
        notification.close("sessionWarning");
        clearTimeout(this.logoutTimer);
      },
      idle: 14400000, // 4 hours in milliseconds
      keepTracking: true,
      startAtIdle: false,
    }).start();
  };

  render() {
    if (this.state.loading) {
      return <OverlaySpinner />;
    }

    const {
      updateUserResult,
      authenticatedUserResult,
      currentUser,
      authenticatedUser,
    } = this.props;

    const newProps = {
      updateUserResult,
      authenticatedUserResult,
      currentUser,
      authenticatedUser,
    };

    return (
      <Router>
        <div>
          {currentUser && currentUser.isAuthenticated ? (
            <GlobalContextWrapper {...newProps}>
              <PrivateRoutes {...newProps} />
            </GlobalContextWrapper>
          ) : (
            <PublicRoutes {...newProps} />
          )}
        </div>
      </Router>
    );
  }
}

export default compose(
  withRouter,
  graphql(UPDATE_USER, { name: "updateUser" }),
  graphql(AUTHENTICATED_USER, {
    name: "authenticatedUser",
  }),
  graphql(CURRENT_USER, {
    props: ({ data: { currentUser } }) => {
      return { currentUser };
    },
  })
)(AccountLoader);
